// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import vi from 'date-fns/locale/vi';

/**
 * @returns {boolean}
 */
const currentLocaleVi = () => {
    const currentLocale = localStorage.getItem('locale');
    if (currentLocale === 'vi') {
        return true;
    }

    return false;
};
/**
 * @param   {Date|string} now
 * @param   {Date|string} then
 * @returns {string}
 */
const commonTimeFormat = (now, then) => {
    const isThisYear = differenceInCalendarYears(now, then) < 1;

    return isThisYear
        ? format(then, 'MMM do, h:mm a', {
            locale: currentLocaleVi() ? vi : '',
        })
        : format(then, 'MMM do, yyyy h:mm a', {
            locale: currentLocaleVi() ? vi : '',
        });
};

const relativeTimeFormat = (now, then) => {
    const daysPassed = differenceInDays(now, then);

    if (daysPassed < 1) {
        return formatDistance(then, now, {
            addSuffix: true,
            locale: currentLocaleVi() ? vi : '',
        });
    }

    if (daysPassed < 7) {
        return format(then, 'EEEE, h:mm a', {
            locale: currentLocaleVi() ? vi : '',
        });
    }

    return null;
};

/**
 * @param {Date|string} date
 * @param {string} fromFormat
 */
export function formatTime(date) {
    const now = new Date();
    const then = parseISO(date);

    return commonTimeFormat(now, then);
}

/**
 * @param {Date|string} date
 * @param {string} fromFormat
 */
export function humanizeTime(date) {
    const now = new Date();
    const then = parseISO(date);

    return relativeTimeFormat(now, then) || commonTimeFormat(now, then);
}

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import authenticated from './authenticated';
import guestOnly from './guestOnly';
import isAdmin from './isAdmin';
import isEventEditorOrAdmin from './isEventEditorOrAdmin';

// Enabled middleware:
export default {
    authenticated,
    guestOnly,
    isAdmin,
    isEventEditorOrAdmin,
};

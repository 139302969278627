<template>
    <div>
        <h1 class="card-title">
            {{ title }}
        </h1>

        <p class="card-subtitle mb-3">
            {{ message }}
        </p>

        <div class="d-flex justify-content-end">
            <el-button
                type="info"
                size="mini"
                :disabled="processing"
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                type="success"
                size="mini"
                :disabled="processing"
                @click="confirm"
            >
                Confirm
            </el-button>
        </div>
    </div>
</template>

<script>
    import processRequest from '~/mixins/processRequest';

    export default {

        mixins: [processRequest],
        data() {
            return {
                processing: false,
            };
        },

        computed: {
            message() {
                if (this.$route.query.is_sunner === 'true') {
                    return `Are you sure want to change ${this.$route.query.username}'s status to not sunner ?`;
                }
                return `Are you sure want to change ${this.$route.query.username}'s status to sunner ?`;
            },

            title() {
                if (this.$route.query.is_sunner === 'true') {
                    return 'Unset Sun* Account';
                }
                return 'Set Sun* Account';
            },
        },

        methods: {
            confirm() {
                this.processing = true;
                this.processRequest(this.$store.dispatch('adminUser/switchInternalMember', {
                    username: this.$route.query.username,
                    value: !(this.$route.query.is_sunner === 'true'),
                }))
                    .then((res) => {
                        this.$message.success('User updated!');
                        window.opener.postMessage({
                            user: res, success: true, message: 'User updated!', action: 'setSunner',
                        }, '*');
                    }).catch(() => {
                        this.$message.error('Sorry. Something went wrong!');
                        window.opener.postMessage({ success: false, message: 'Sorry. Something went wrong!' }, '*');
                    }).finally(() => {
                        this.processing = false;
                        window.close();
                    });
            },

            cancel() {
                window.close();
            },
        },

    };
</script>

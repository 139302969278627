// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import _pickBy from 'lodash/pickBy';
import _mapValues from 'lodash/mapValues';

function collectServerError(errors) {
    return _mapValues(errors, '0');
}

export default {
    data: () => ({
        processing: false,
        serverErrors: {},
        serverErrorMessage: null,
    }),

    methods: {
        submit(form, send) {
            this.serverErrors = {};
            if (form.model) {
                form.validate((valid) => {
                    if (valid) {
                        send();
                    }
                });
            } else {
                send();
            }
        },

        handleError(error) {
            const handleUnknownError = () => {
                this.$message.error('Something went wrong. Please try again later.');
                throw error;
            };

            if (error.response) {
                const { data } = error.response;
                if (error.response.status === 422) {
                    this.serverErrors = collectServerError(data.errors);
                } else if (data.message) {
                    this.serverErrorMessage = data.message;
                } else {
                    handleUnknownError();
                }
            } else {
                handleUnknownError();
            }
        },

        resetForm(form) {
            if (form.model) {
                form.resetFields();
            }
        },

        normalizeData(form) {
            return _pickBy(form, Boolean);
        },

        clearErrors(form) {
            form.clearValidate();
            this.serverErrors = {};
        },
    },
};

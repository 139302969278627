// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <div>
        <ul v-if="apps.length > 0" class="list-group">
            <li v-for="app in apps" :key="app.id" class="list-group-item">
                <Item :app="app" @delete="deleteToken" />
            </li>
        </ul>
        <div v-else class="text-center font-weight-bold text-muted my-05">
            {{ $t('authorizedOAuthApps.empty') }}
        </div>
    </div>
</template>

<script>
    import Item from './Item.vue';

    export default {
        components: {
            Item,
        },

        props: {
            apps: {
                type: Array,
                default: () => ([]),
            },
        },

        methods: {
            deleteToken(token) {
                this.$emit('delete', token);
            },
        },
    };
</script>

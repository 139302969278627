<template>
    <el-dialog
        :title="title"
        :visible.sync="show"
        width="25%"
        @close="clear"
    >
        <h6>User</h6>

        <el-autocomplete
            v-if="!form.user"
            v-model="form.search"
            :fetch-suggestions="fetchUserSuggestion"
            :trigger-on-focus="false"
            placeholder="Search user"
            size="small"
            @select="handleSuggestionSelect"
        >
            <template slot-scope="props" class="suggestion-item">
                <Avatar :image="props.item.avatar" class="suggestion-item__avatar mr-2" />
                <span>{{ props.item.name }}</span>
            </template>
        </el-autocomplete>

        <div v-else class="d-flex align-items-center">
            <Avatar :image="form.user.avatar" :username="form.user.username" class="avatar-sm mr-2" />
            <span>{{ form.user.name }}</span>

            <el-button
                type="text"
                icon="el-icon-close"
                class="ml-3"
                @click="form.user = null"
            />
        </div>

        <hr>
        <h6>Roles</h6>

        <!-- Assigned roles -->
        <el-checkbox
            v-for="(role, index) in assignedRoles"
            :key="index"
            :label="role.role"
            disabled
            border
            checked
            size="small"
            class="ml-0 mr-05"
        />

        <!-- Available roles -->
        <el-checkbox-group v-model="form.roles" class="d-inline-block">
            <el-checkbox
                v-for="(role, index) in availableRoles"
                :key="index"
                :label="role.id"
                :disabled="!form.user"
                border
                size="small"
                class="ml-0 mr-05"
            >
                {{ role.role }}
            </el-checkbox>
        </el-checkbox-group>

        <div slot="footer">
            <el-button
                :loading="processing"
                :disabled="submitDisabled"
                type="primary"
                @click="submit"
            >
                Save
            </el-button>
            <el-button @click="close">
                Cancel
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import _map from 'lodash/map';
    import _find from 'lodash/find';
    import _reject from 'lodash/reject';
    import { searchUser } from '~/api/admin/user-roles';
    import form from '~/components/admin/mixins/form';
    import Avatar from '~/components/Avatar.vue';

    const emptyForm = () => ({
        search: null,
        user: null,
        roles: [],
    });

    export default {

        components: {
            Avatar,
        },
        mixins: [form],

        props: {
            roles: Array,
        },

        data: () => ({
            form: emptyForm(),
        }),

        computed: {
            selectedUserRoles() {
                return this.form.user
                    ? this.form.user.roles
                    : [];
            },

            assignedRoles() {
                return _map(this.selectedUserRoles, role => _find(this.roles, { id: role.id }));
            },

            availableRoles() {
                return _reject(this.roles, role => _find(this.selectedUserRoles, { id: role.id }));
            },

            submitDisabled() {
                return !this.form.user || this.form.roles.length <= 0;
            },
        },

        methods: {
            fetchUserSuggestion: (q, done) => searchUser({ q }).then((res) => {
                done(res.data);
            }),

            handleSuggestionSelect(user) {
                this.form.user = user;
                this.form.roles = [];
            },

            clear(done) {
                this.form = emptyForm();
                done();
            },
        },
    };
</script>

<style lang="scss">
    .suggestion-item {
        overflow: hidden;
        text-overflow: ellipsis;
        margin: .2rem 0;

        &__avatar {
            border-radius: 100%;
            width: 30px;
            height: auto;
        }
    }
</style>

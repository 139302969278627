<template>
    <el-date-picker
        v-model="value"
        type="daterange"
        start-placeholder="Start date"
        end-placeholder="End date"
        value-format="yyyy-MM-dd"
        class="w-100"
        @change="change"
    />
</template>

<script>
    import _assign from 'lodash/assign';

    export default {
        data() {
            return {
                value: this.$route.query.timerange || '',
            };
        },

        methods: {
            change() {
                const queryParams = {
                    query: _assign({}, this.$route.query, {
                        timerange: this.value,
                        page: 1,
                    }),
                };

                this.$emit('change', queryParams);
                this.$router.push(queryParams);
            },

            clear() {
                this.value = '';
            },
        },

    };
</script>

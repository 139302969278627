<template>
    <user-roles-list :users="users" :roles="roles" :pagination="pagination" />
</template>
<script>
    import UserRolesList from '@/components/admin/user-roles/List.vue';
    import { fetch, setRoles, unsetRoles } from '@/api/admin/user-roles';
    import { fetch as getAllRole } from '@/api/admin/role';
    import _map from 'lodash/map';
    import _find from 'lodash/find';
    import _assign from 'lodash/assign';
    import _reject from 'lodash/reject';
    import { updateDataWith } from '~/utils/manage-page';

    export default {
        components: {
            UserRolesList,
        },

        data() {
            return {
                users: [],
                pagination: {},
                roles: [],
            };
        },

        provide() {
            return {
                actions: {
                    addRoles: this.handleAddRoles,
                    removeRoles: this.handleRemoveRoles,
                },
            };
        },

        async mounted() {
            const { data, meta } = await fetch().then(res => res.data);
            const roles = await getAllRole().then(res => res.data);
            this.roles = roles;
            this.users = data;
            this.pagination = meta;
        },

        methods: {
            handleAddRoles(user, roles) {
                return setRoles(user.username, roles).then(() => this.addRoles(user, roles));
            },

            handleRemoveRoles(user, roles) {
                return unsetRoles(user.username, roles).then(() => this.removeRoles(user, roles));
            },

            addRoles(user, roles) {
                const addedRoles = _map(roles, id => _find(this.roles, { id }));
                const isNewUser = _find(this.users, { id: user.id }) === undefined;
                if (isNewUser) {
                    this.users = [
                        ...this.users,
                        _assign(user, {
                            roles: addedRoles,
                        }),
                    ];
                } else {
                    this.users = updateDataWith(this.users, 'username', user.username, updatedUser => ({
                        roles: [...updatedUser.roles, ...addedRoles],
                    }));
                }
            },

            removeRoles(user, roles) {
                this.users = updateDataWith(this.users, 'username', user.username, updatedUser => ({
                    roles: _reject(updatedUser.roles, role => roles.includes(role.id)),
                }));
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import {
    getUsers,
    updateAccount,
    banUser,
    unbanUser,
    getBanLogByUser,
    switchInternalMember,
    switchVerified,
} from '~/api/admin/users';
import { updateData } from '~/utils/manage-page';

const initialState = () => ({
    users: {},
});

const actions = {
    async getUsers({ commit }, params = {}) {
        const { data } = await getUsers(params);
        commit('fetch', data);
    },

    async updateAccount({ commit }, user) {
        await updateAccount(user.userId, user.form);
        commit('updateById', user.form);
    },

    async getBanLog({ commit }, user) {
        const data = await getBanLogByUser(user).then(_ => _.data);
        commit('update', { username: user, banned_log: data });

        return data;
    },

    async ban({ commit }, user) {
        const { data } = await banUser(user.user, user.form);
        commit('update', { username: user.user, banned_at: Date.now() });

        return data;
    },

    async unban({ commit }, user) {
        const { data } = await unbanUser(user);
        commit('update', { username: user, banned_at: null });

        return data;
    },

    async switchInternalMember({ commit }, payload) {
        const { data } = await switchInternalMember(payload.username, payload.value);
        commit('update', { username: payload.username, is_sunner: data.is_sunner });

        return data;
    },

    async switchVerified({ commit }, payload) {
        const { data } = await switchVerified(payload.username, payload.value);
        commit('update', { username: payload.username, is_verified: data.is_verified });

        return data;
    },
};

const mutations = {
    fetch(state, data) {
        state.users = data;
    },

    update(state, data) {
        state.users.data = updateData(state.users.data, 'username', data);
    },

    updateById(state, data) {
        state.users.data = updateData(state.users.data, 'id', data);
    },
};

export default {
    state: initialState,
    actions,
    mutations,
    namespaced: true,
};

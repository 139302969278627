// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import _assign from 'lodash/assign';

export default ({ route, router, store }) => {
    if (!store.state.auth.user) {
        store.commit('session/pushMessage', {
            type: 'error',
            message: 'Unauthorized. Please login first.',
        });
        store.dispatch('session/showMessages');

        router.push({
            path: '/login',
            query: _assign({}, route.query, { t: Date.now() }),
        });
    }
};

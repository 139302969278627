// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import axios from '~/libs/axios';

export const getUsers = params => axios.get('/api/admin/users', { params });
export const updateAccount = (userId, data) => axios.put(`/api/admin/users/${userId}`, data);
export const getUserInfo = params => axios.get('/api/admin/user-info', { params });
export const banUser = (username, data) => axios.delete(`/api/admin/users/${username}`, { data });
export const unbanUser = username => axios.put(`/api/admin/users/${username}/unbanned`);
export const unbanService = (username, data) => axios.put(`/api/admin/users/${username}/unban-service`, data);
export const getBanLogByUser = username => axios.get(`/api/admin/users/${username}/user-ban-log`);
export const switchInternalMember = (username, value) => axios.put(`/api/admin/users/${username}/switch-internal-member`, { value });
export const switchVerified = (username, value) => axios.put(`/api/admin/users/${username}/switch-verified`, { value });

export default {
    getUsers,
    updateAccount,
    banUser,
    unbanUser,
    getBanLogByUser,
    switchInternalMember,
    switchVerified,
};

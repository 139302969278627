// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer class="my-3">
        <BRow>
            <BCol span="12" class="d-flex justify-content-center py-4 mb-2">
                <Logo size="medium" />
            </BCol>
        </BRow>
        <BRow>
            <BCol
                sm="12"
                md="10"
                lg="8"
                xl="6"
                class="mx-auto"
            >
                <ElCard>
                    <h1 class="card-title">
                        {{ $t('register.title') }} {{ continueService }}
                    </h1>

                    <p class="card-subtitle mt-2">
                        {{ $t('register.content1') }} <strong>{{ $t('register.content2') }}</strong>!
                        {{ $t('register.content3') }}
                    </p>

                    <RegisterForm class="pt-4" />
                </ElCard>
            </BCol>
        </BRow>
    </BContainer>
</template>

<script>
    import { serviceName } from '~/utils/sso';
    import Logo from '~/components/site/Logo.vue';
    import RegisterForm from '~/components/forms/account/Register.vue';

    export default {
        components: {
            Logo,
            RegisterForm,
        },

        computed: {
            continueService() {
                return serviceName(this.$route.query.service || 'viblo');
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <div class="logo" :class="{ [`logo--${size}`]: size }">
        <RouterLink v-if="router" to="/">
            <img :src="logo" alt="Viblo Accounts" class="logo-image">
        </RouterLink>
        <a v-else href="/">
            <img :src="logo" alt="Viblo Accounts" class="logo-image">
        </a>
    </div>
</template>

<script>
    import logo from '~/assets/logo_full.svg';

    export default {

        props: {
            size: {
                type: String,
                default: 'small',
            },

            router: {
                type: Boolean,
                default: true,
            },
        },
        data: () => ({ logo }),
    };
</script>

<style lang="scss">
    .logo {
        display: inline-block;
        &-image {
            width: auto;
            height: 1.75rem;
        }
        &--small .logo-image {
            height: 1.75rem;
        }
        &--medium .logo-image {
            height: 2.5rem;
        }
        &--large .logo-image {
            height: 3rem;
        }
    }
</style>

<template>
    <div class="main-section">
        <div class="header mb-4">
            <h4>Manage users</h4>
        </div>
        <div class="m-3 d-flex justify-content-start">
            <FilterUsers @changeStatus="filterStatus" />
            <search query-param="q" class="mr-3 w-100" @search="search" />
        </div>
        <users-table :users="users.data" :total="pagination.total" />
        <el-pagination
            v-if="pagination"
            class="mt-3"
            background
            layout="prev, pager, next"
            :total="pagination.total"
            :page-size="pagination.per_page"
            :current-page="pagination.current_page"
            align="center"
            @current-change="changePage"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import _assign from 'lodash/assign';
    import UsersTable from '~/components/admin/users/Table.vue';
    import Search from '~/components/admin/table/Search.vue';
    import FilterUsers from '~/components/admin/table/FilterUsers.vue';

    const filterFromQuery = query => ({
        status: (typeof query.status === 'undefined') ? 'active' : query.status,
        q: (typeof query.q === 'undefined') ? '' : query.q,
    });

    export default {
        head: {
            title: 'Manage Users',
        },

        components: {
            UsersTable,
            Search,
            FilterUsers,
        },

        computed: {
            ...mapState('adminUser', ['users']),

            pagination() {
                return this.users.meta ? this.users.meta : {};
            },
        },

        mounted() {
            const query = filterFromQuery(this.$route.query);
            this.$router.push({ query });
            this.$store.dispatch('adminUser/getUsers', query);
        },

        methods: {
            changePage(page) {
                const query = _assign({}, this.$route.query, { page });
                this.$store.dispatch('adminUser/getUsers', query);
                this.$router.push({ query });
            },

            search({ query }) {
                this.$store.dispatch('adminUser/getUsers', query);
            },

            filterStatus(status) {
                const query = _assign({}, this.$route.query, { status, page: 1 });
                this.$store.dispatch('adminUser/getUsers', query);
                this.$router.push({ query });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .main-section {
        .header {
            color: #303133;
            border-bottom: 1px solid #e6e6e6;
        }
    }
</style>

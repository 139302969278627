<template>
    <el-select
        v-model="value"
        placeholder="Filter University"
        filterable
        clearable
        class="w-100"
        @change="change"
    >
        <el-option
            v-for="(item, index) in data"
            :key="index"
            :value="`${item.id}`"
            :label="`${item.name} (${item.code})`"
        />
    </el-select>
</template>

<script>
    import _assign from 'lodash/assign';
    import { fetch } from '~/api/university';

    export default {
        data() {
            return {
                value: this.$route.query.university || '',
                loading: false,
                data: [],
            };
        },

        async mounted() {
            await this.fetchData();
        },

        methods: {
            async fetchData() {
                try {
                    this.loading = true;
                    const { data } = await fetch();
                    this.data = data;
                } catch (e) {
                    this.data = [];
                } finally {
                    this.loading = false;
                }
            },

            change({ query = {} }) {
                const queryParams = {
                    query: _assign({}, this.$route.query, query, {
                        university: this.value,
                        page: 1,
                    }),
                };

                this.$emit('change', queryParams);
                this.$router.push(queryParams);
            },

            clear() {
                this.value = '';
            },
        },
    };
</script>

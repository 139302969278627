// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import {
    getEmail, storeEmail, destroyEmail, makePrimary,
} from '~/api/email/email';

const initialState = () => ({
    emails: [],
});

const actions = {
    async getEmail({ commit }) {
        const { data } = await getEmail();
        commit('updateEmail', data);
    },

    async storeEmail({ commit }, email) {
        const { data } = await storeEmail(email);
        commit('updateEmail', data);
    },

    async destroyEmail({ commit }, id) {
        const { data } = await destroyEmail(id);
        commit('updateEmail', data);
    },

    async makePrimary({ commit }, id) {
        const { data } = await makePrimary(id);
        commit('updateEmail', data);
    },
};

const mutations = {
    updateEmail(state, data) {
        state.emails = data;
    },
};

export default {
    state: initialState,
    actions,
    mutations,
    namespaced: true,
};

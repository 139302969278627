// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer class="mt-4">
        <BRow>
            <BCol span="12" class="d-flex justify-content-center py-4 mb-2">
                <Logo size="medium" />
            </BCol>
        </BRow>

        <BRow>
            <BCol
                sm="12"
                md="10"
                lg="8"
                class="mx-sm-auto"
            >
                <ElCard>
                    <h1 class="card-title">
                        Reset Password
                    </h1>

                    <p class="card-subtitle">
                        Almost done, change your password to complete. You should keep a strong password
                        to prevent unauthorized access to your account.
                    </p>

                    <ResetPasswordForm :token="$route.params.token" class="mt-4" @success="redirect" />
                </ElCard>
            </BCol>
        </BRow>
    </BContainer>
</template>

<script>
    import Logo from '~/components/site/Logo.vue';
    import ResetPasswordForm from '~/components/forms/security/ResetPassword.vue';

    export default {
        components: {
            Logo,
            ResetPasswordForm,
        },

        methods: {
            redirect() {
                this.$router.push('/login');
            },
        },
    };
</script>

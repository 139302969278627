// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<script>
    import store from '~/store';

    /**
     * @param   {string} image
     * @param   {number} size
     * @returns {string}
     */
    const imageUrl = (image, size) => `${store.state.config.images_url}/${size}x${size}/${image}`;

    /**
     * @param   {object} props
     * @param   {string} props.image
     * @param   {string|number} props.size
     * @param   {string} props.defaultImage
     * @returns {object}
     */
    function getImages(props) {
        const { image, defaultImage } = props;

        if (!image) {
            return {
                src: defaultImage,
            };
        }

        const size = typeof props.size === 'string' ? 60 : props.size;

        return {
            src: imageUrl(image, size),
            srcset: `${imageUrl(image, size * 2)} 2x`,
        };
    }

    /**
     * @param   {object} props
     * @param   {number} props.size
     * @param   {boolean} props.rectangle
     * @returns {object}
     */
    const getAvatarClasses = ({ size, rectangle }) => ({
        avatar: true,
        [`avatar--${size}`]: typeof size === 'string',
        rounded: rectangle,
        'rounded-circle': !rectangle,
    });

    /**
     * @param   {object} props
     * @param   {number} props.size
     * @returns {object|undefined}
     */
    const getStyle = ({ size }) => (typeof size !== 'string'
        ? {
            height: `${size}px`,
            width: `${size}px`,
        } : undefined
    );

    const profileDefault = require('../assets/profile_default.png');

    export default {
        functional: true,

        props: {
            image: {
                type: String,
                default: null,
            },

            size: {
                type: [String, Number],
                default: 'md',
            },

            rectangle: {
                type: Boolean,
                default: false,
            },

            defaultImage: {
                type: String,
                default: profileDefault,
            },
        },

        render: (createElement, { props, data }) => {
            const srcs = getImages(props);
            const className = [getAvatarClasses(props), data.class, data.staticClass];
            const style = getStyle(props);
            const fallbackImage = props.defaultImage || profileDefault;

            return createElement('img', {
                class: className,
                style,
                attrs: {
                    ...srcs,
                    ...data.attrs,
                },
                directives: [
                    {
                        name: 'avatar',
                        value: fallbackImage,
                    },
                ],
            });
        },
    };
</script>

<style lang="scss">
    $avatar-size-xs: 20px !default;
    $avatar-size-sm: 32px !default;
    $avatar-size-md: 37px !default;
    $avatar-size-lg: 45px !default;
    $avatar-size-xl: 60px !default;

    .avatar {
        cursor: pointer;
        width: $avatar-size-md;
        height: $avatar-size-md;

        &--xs {
            width: $avatar-size-xs;
            height: $avatar-size-xs;
        }

        &--sm {
            width: $avatar-size-sm;
            height: $avatar-size-sm;
        }

        &--md {
            width: $avatar-size-md;
            height: $avatar-size-md;
        }

        &--lg {
            width: $avatar-size-lg;
            height: $avatar-size-lg;
        }

        &--xl {
            width: $avatar-size-xl;
            height: $avatar-size-xl;
        }
    }
</style>

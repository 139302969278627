// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import axios from '~/libs/axios';

export const getSettings = params => axios.get('/api/admin/system-settings', { params });
export const storeSettingsItem = data => axios.post('/api/admin/system-settings', data);
export const updateSettingsItem = (id, data) => axios.patch(`/api/admin/system-settings/${id}`, data);
export const deleteSettingsItem = (id, format) => axios.delete(`/api/admin/system-settings/${id}`, { format });

export default {
    getSettings,
    storeSettingsItem,
    updateSettingsItem,
    deleteSettingsItem,
};

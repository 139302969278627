// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

const { dsn } = window.GLOBALS.config.sentry;

const initSentry = (router) => {
    if (!dsn) return;

    Sentry.init({
        Vue,
        dsn: window.GLOBALS.config.sentry.dsn,
        environment: window.GLOBALS.config.sentry.environment,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],
        tracesSampleRate: window.GLOBALS.config.sentry.traces_sample_rate,
    });
};

export default initSentry;

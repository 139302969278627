<template>
    <div>
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-position="top"
            size="mini"
        >
            <el-form-item prop="time" label="How long do you want to ban this user?">
                <el-select v-model="form.time" class="w-100 mb-05">
                    <el-option
                        v-for="time in banTimes"
                        :key="time.value"
                        :label="time.label"
                        :value="time.value"
                    />
                </el-select>
            </el-form-item>

            <el-form-item prop="reason" label="Reason for delete this user">
                <el-select v-model="form.reason" class="w-100 mb-05">
                    <el-option
                        v-for="reason in banReasons"
                        :key="reason.value"
                        :label="reason.label"
                        :value="reason.value"
                    />
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-checkbox v-model="form.is_banned_all">
                    Ban all services
                </el-checkbox>
            </el-form-item>

            <el-form-item v-if="!form.is_banned_all" prop="services">
                <el-select
                    v-model="form.services"
                    style="width: 100%"
                    multiple
                >
                    <el-option
                        v-for="(service, code) in services"
                        :key="code"
                        :label="service.name"
                        :value="code"
                    />
                </el-select>
            </el-form-item>

            <el-form-item prop="source">
                <el-input
                    v-model="form.source"
                    :autosize="{ minRows: 3 }"
                    type="textarea"
                    placeholder="Comment"
                />
            </el-form-item>
        </el-form>
        <span class="d-flex justify-content-end">
            <el-button :disabled="processing" @click="close">
                Cancel
            </el-button>
            <el-button type="primary" :disabled="processing" @click="handleSubmit">
                Submit
            </el-button>
        </span>
    </div>
</template>

<script>
    import processRequest from '~/mixins/processRequest';
    import { services } from '~/utils/sso';

    const rules = {
        time: { required: true, message: 'Please choose an option.' },
        reason: { required: true, message: 'Please choose an option.' },
        services: { type: 'array', required: true, message: 'Please choose an service.' },
    };

    export default {

        mixins: [processRequest],
        data() {
            const form = {
                time: 'forever',
                reason: 'spam',
                source: '',
                is_banned_all: true,
                services: [],
            };

            const banTimes = [
                {
                    value: 'three_days',
                    label: '3 Days',
                },
                {
                    value: 'one_week',
                    label: '1 Week',
                },
                {
                    value: 'two_weeks',
                    label: '2 Week',
                },
                {
                    value: 'one_month',
                    label: '1 Month',
                },
                {
                    value: 'forever',
                    label: 'Forever',
                },
            ];

            const banReasons = [
                {
                    value: 'spam',
                    label: 'Spam',
                },
                {
                    value: 'tos_violation',
                    label: 'Rules Violation',
                },
                {
                    value: 'harashment',
                    label: 'Harashment',
                },
                {
                    value: 'infringes_copyright',
                    label: 'Infringes Copyright',
                },
            ];

            return {
                form,
                banTimes,
                banReasons,
                services,
                rules,
            };
        },

        mounted() {
            this.$store.dispatch('adminUser/getBanLog', this.$route.query.username).then((res) => {
                this.form = res;
            });
        },

        methods: {
            handleSubmit() {
                this.$refs.form.validate((valid) => {
                    if (!valid && !this.form.is_banned_all) {
                        return;
                    }
                    this.submit();
                });
            },

            submit() {
                this.processing = true;
                this.processRequest(this.$store.dispatch('adminUser/ban', {
                    user: this.$route.query.username,
                    form: this.form,
                }))
                    .then((res) => {
                        this.$message.success('User updated!');
                        window.opener.postMessage({
                            user: res, success: true, message: 'User updated!', action: 'banUser',
                        }, '*');
                    }).catch(() => {
                        this.$message.error('Sorry. Something went wrong!');
                        window.opener.postMessage({ success: false, message: 'Sorry. Something went wrong!' }, '*');
                    }).finally(() => {
                        this.processing = false;
                        window.close();
                    });
            },

            close() {
                window.close();
            },
        },
    };
</script>

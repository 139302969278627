<template>
    <div class="table-actions-cell">
        <el-button v-if="state.processing" icon="el-icon-loading" size="mini" />

        <template v-for="(item, index) in actions" v-else>
            <template v-if="typeof item.if === 'function' ? item.if(data) : true">
                <nuxt-link
                    v-if="item.url"
                    :key="index"
                    :to="item.url(data)"
                    class="mr-05"
                >
                    <el-button :type="item.type" :icon="item.icon" size="mini" />
                </nuxt-link>
                <el-button
                    v-else-if="item.icon"
                    :key="index"
                    :type="item.type"
                    :icon="item.icon"
                    :title="item.title"
                    size="mini"
                    @click="onActionClicked(data, item)"
                />
                <img
                    v-else
                    :key="index"
                    :src="item.img"
                    :width="item.width"
                    :style="item.style(data)"
                    :class="item.class(data)"
                    :title="item.tooltip(data)"
                    @click="onActionClicked(data, item)"
                >
            </template>
        </template>
    </div>
</template>

<script>
    import _assign from 'lodash/assign';
    import processRequest from '../../mixins/processRequest';

    export default {
        mixins: [processRequest],

        props: {
            data: Object,
            actions: Array,
        },

        methods: {
            onActionClicked(data, item) {
                if (item.confirm) {
                    this.confirmAction(data, item.confirm);
                } else if (typeof item.msgbox === 'object') {
                    this.openMessageBox(data, item.msgbox);
                } else if (item.action) {
                    item.action(data);
                }
            },

            confirmAction(data, confirm) {
                this.$confirm(confirm.text, confirm.title)
                    .yes(() => this.processRequest(confirm.action)(data));
            },

            openMessageBox(data, msgbox) {
                const defaultConfig = {
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.processRequest(msgbox.action)(data).then(done);
                        } else {
                            done();
                        }
                    },
                };

                this.$msgbox(_assign({}, defaultConfig, msgbox));
            },
        },
    };
</script>

<style lang="scss">
    .table-actions-cell {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;

        .el-button {
           margin: 0 auto 5px auto;
        }
    }
</style>

<template>
    <div>
        <Avatar :image="user.picture" size="sm" class="mr-1" />
        <el-tag v-if="user.is_sunner" :style="userStyle" title="Sun* member">
            {{ user.name }}
        </el-tag>
        <span v-else :style="userStyle">
            {{ user.name }}
        </span>
    </div>
</template>

<script>
    import Avatar from '~/components/Avatar.vue';

    export default {

        components: {
            Avatar,
        },
        props: {
            user: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            userStyle() {
                return this.user.banned_at ? {
                    textDecoration: 'line-through',
                    color: '#9b9b9b !important',
                } : null;
            },
        },
    };
</script>

<template>
    <div>
        <div class="mb-2">
            <el-button icon="el-icon-plus" @click="openForm">
                Assign Role
            </el-button>
        </div>

        <FormDialog ref="assignForm" :roles="roles" :save="assignNewUserRoles" />

        <el-table :data="users">
            <el-table-column
                :formatter="renderName"
                label="User"
                prop="name"
                width="300"
            />

            <el-table-column
                :formatter="renderRoles"
                label="Roles"
                prop="roles"
            />

            <el-table-column
                :formatter="renderActions(tableActions)"
                label="Actions"
                fixed="right"
                width="130"
            />
        </el-table>

        <pagination
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import _map from 'lodash/map';
    import NameCell from '~/components/admin/table/NameCell.vue';

    import table from '../mixins/table';
    import FormDialog from './Form.vue';
    import RolesCell from './RolesCell.vue';

    export default {
        components: {
            FormDialog,
        },

        mixins: [table],

        props: {
            users: Array,
            pagination: Object,
            roles: Array,
        },

        data() {
            const tableActions = [
                {
                    icon: 'el-icon-plus',
                    action: this.assignNewRole,
                },
                {
                    icon: 'el-icon-delete',
                    type: 'danger',
                    confirm: {
                        title: 'Clear roles',
                        text: 'Unassign all roles of this user?',
                        action: this.clearRoles,
                    },
                    if: user => user.roles.length > 0,
                },
            ];

            return { tableActions };
        },

        inject: ['actions'],

        methods: {
            assignNewRole(user) {
                this.$refs.assignForm.open({
                    user,
                    roles: [],
                });
            },

            openForm() {
                this.$refs.assignForm.open();
            },

            renderName(data) {
                return <NameCell user={data}/>;
            },

            renderRoles(data) {
                const confirmRemoveRole = role => this.$confirm(`Remove role ${role.role} for ${data.name}?`, 'Unassign role')
                    .then(() => {
                        this.removeRole(data, role);
                    });

                return <RolesCell roles={data.roles} on-remove-role={confirmRemoveRole}/>;
            },

            assignNewUserRoles({ user, roles }) {
                return this.actions.addRoles(user, roles);
            },

            removeRole(user, role) {
                return this.actions.removeRoles(user, [role.id]);
            },

            clearRoles(user) {
                return this.actions.removeRoles(user, _map(user.roles, 'id'));
            },
        },
    };
</script>

<template>
    <el-dialog
        :title="title"
        :visible.sync="show"
        width="600px"
        @close="close"
        @submit.native.prevent="onSubmit"
    >
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
            size="medium"
        >
            <el-form-item label="Email Domain" prop="value">
                <el-input v-model="form.value" type="text" />
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button
                :loading="processing"
                type="primary"
                @click="onSubmit"
            >
                Save
            </el-button>

            <el-button @click="close">
                Close
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    const rules = {
        value: { required: true, message: 'Email Domain is required.' },
    };

    const emptyState = () => ({
        value: '',
    });

    export default {
        props: {
            title: String,
        },

        data: () => ({
            form: emptyState(),
            rules,
            show: false,
        }),

        methods: {
            open(data = null) {
                if (data) {
                    this.form = data;
                }
                this.show = true;
            },

            onSubmit() {
                this.$refs.form.validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    this.$emit('save', this.form);
                    this.close();
                });
            },

            close() {
                this.show = false;
                this.form = emptyState();
            },
        },
    };
</script>

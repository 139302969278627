// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <ElPopover
        ref="popover"
        :append-to-body="false"
        transition="el-zoom-in-top"
        placement="bottom-end"
    >
        <slot slot="reference" name="reference">
            <i class="viblo-services-toggle fa fa-th" />
        </slot>

        <ul v-if="vibloServices" class="viblo-services-menu">
            <li class="viblo-services-menu__item">
                <a :href="accountsUrl" target="_blank" rel="noopener">
                    <Avatar :image="profile.avatar || profile.picture" class="service-icon" />
                    <span class="service-name">
                        Account
                    </span>
                </a>
            </li>
            <li v-for="(service, name) in vibloServices" :key="name" class="viblo-services-menu__item">
                <a :href="service.homepage" target="_blank" rel="noopener">
                    <span
                        :style="`background: url(${service.icon});`"
                        class="service-icon"
                    />
                    <span class="service-name">
                        {{ service.name }}
                    </span>
                </a>
            </li>
        </ul>
    </ElPopover>
</template>

<script>
    import _set from 'lodash/set';
    import { services as vibloServices } from '~/utils/sso';
    import Avatar from '~/components/Avatar.vue';

    import _viblo from '~/assets/icons/favicon/favicon-viblo.png';
    import _cv from '~/assets/icons/favicon/favicon-viblo-cv.png';
    import _code from '~/assets/icons/favicon/favicon-viblo-code.png';
    import _ctf from '~/assets/icons/favicon/favicon-viblo-ctf.png';
    import _learn from '~/assets/icons/favicon/favicon-viblo-learn.png';
    import _partner from '~/assets/icons/favicon/favicon-viblo-partner.png';
    import _battle from '~/assets/icons/favicon/favicon-viblo-battle.png';

    _set(vibloServices, 'viblo.icon', _viblo);
    _set(vibloServices, 'code.icon', _code);
    _set(vibloServices, 'ctf.icon', _ctf);
    _set(vibloServices, 'cv.icon', _cv);
    _set(vibloServices, 'learning.icon', _learn);
    _set(vibloServices, 'partner.icon', _partner);
    _set(vibloServices, 'battle.icon', _battle);

    export default {
        components: {
            Avatar,
        },

        props: {
            profile: {
                type: Object,
                required: true,
            },

            accountsUrl: {
                type: String,
                default: window.GLOBALS.config.app_url,
            },
        },

        data: () => ({
            vibloServices,
        }),

        methods: {
            open() {
                this.$refs.popover.doShow();
            },

            close() {
                this.$refs.popover.doClose();
            },
        },
    };
</script>

<style lang="scss">
    .viblo-services-toggle {
        font-size: 1.625rem;
        color: #7f7f7f;
        display: inline-block;
        margin-right: 1.5rem;
        font-size: 2rem;
        margin-top: 3px;
        cursor: pointer;
        transition: all 0.1s ease-in;
        &:hover {
            color: #212529;
        }
    }

    .viblo-services-menu {
        padding-left: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        max-width: 280px;
        margin-bottom: 0;

        .viblo-services-menu__item {
            display: block;
            width: 91px;
            height: 91px;
            box-sizing: border-box;
            border: solid 1px transparent;
            margin-bottom: 1rem;
            &:hover {
                border-color: #ddd;
            }
            .service-icon {
                background-color: #fff;
                background-size: contain !important;
                background-repeat: no-repeat !important;
                background-position: center !important;
                width: 48px;
                height: 48px;
                display: block;
                overflow: hidden;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0.6rem;
                margin-bottom: 0.6rem;
            }
            a, .service-name {
                text-decoration: none !important;
                color: #212529;
            }
        }
    }
</style>

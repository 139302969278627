// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <RequestActivationForm
        :send="send"
        title="sendActivationEmail.title"
        submit-label="sendActivationEmail.label"
    >
        <template slot="subtitle">
            {{ $t('sendActivationEmail.content') }}
        </template>

        <template slot="success-alert">
            {{ $t('forgotYourPassword.RequestActivationForm.content1') }}
            <strong>{{ $t('forgotYourPassword.RequestActivationForm.content2') }}</strong>
            {{ $t('forgotYourPassword.RequestActivationForm.content3') }}
            {{ $t('forgotYourPassword.RequestActivationForm.content4') }}
            <strong>{{ sentEmailAddress }}</strong>.
        </template>
    </RequestActivationForm>
</template>

<script>
    import { sendToken } from '~/api/security/activation';
    import RequestActivationForm from '~/components/forms/security/RequestActivation.vue';

    export default {
        components: {
            RequestActivationForm,
        },

        data: () => ({
            sentEmailAddress: '',
        }),

        methods: {
            send(data) {
                this.sentEmailAddress = data.email;
                return sendToken(data);
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <ElCard class="card--form" shadow="never">
            <h1 class="card-title">
                Emails
            </h1>

            <p class="card-subtitle">
                {{ $t('emails.content') }}
            </p>

            <el-alert
                v-if="hasInvalidEmails"
                class="mt-3"
                type="error"
                :closable="false"
            >
                {{ $t('emails.hasInvalidEmails.content1') }}
                <b>{{ $t('emails.hasInvalidEmails.content2') }}</b>
                {{ $t('emails.hasInvalidEmails.content3') }}
            </el-alert>

            <ListEmail :profile="user" class="mt-4" :emails="emails" />

            <AddEmail class="mt-4" :profile="user" />
        </ElCard>
    </BContainer>
</template>

<script>
    import { mapState } from 'vuex';
    import AddEmail from '~/components/forms/emails/AddEmail.vue';
    import ListEmail from '~/components/emails/ListEmail.vue';

    export default {
        components: {
            AddEmail,
            ListEmail,
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('email', ['emails']),
            hasInvalidEmails() {
                if (this.emails) {
                    return this.emails.some(email => email.is_bounced);
                }
                return false;
            },
        },

        mounted() {
            this.$store.dispatch('email/getEmail');
        },
    };
</script>

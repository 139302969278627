// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <ElCard shadow="never">
            <h1 class="card-title">
                {{ $t('personalInfo.title') }}
            </h1>
            <p class="card-subtitle">
                {{ $t('personalInfo.content') }}
            </p>

            <ElForm
                ref="form"
                :model="form"
                :rules="validations"
                label-position="top"
                size="medium"
                class="mt-4"
            >
                <ElAlert v-if="serverErrorMessage" type="error" class="mb-2">
                    {{ serverErrorMessage }}
                </ElAlert>

                <div class="d-flex justify-content-center mt-2">
                    <ChangeAvatarBtn trigger="always">
                        <Avatar :image="profile.picture" :size="128" />
                        <div class="change-avt">
                            {{ $t('personalInfo.changeAvatar.change') }}
                        </div>
                    </ChangeAvatarBtn>
                </div>

                <div class="d-flex flex-row flex-wrap mt-4">
                    <ElFormItem prop="username" :label="$t('personalInfo.form.userName')" class="flex-grow-1 px-1">
                        <ElInput :value="profile.username" disabled />
                    </ElFormItem>
                </div>

                <div class="d-flex flex-row flex-wrap">
                    <ElFormItem
                        :error="serverErrors.name"
                        prop="name"
                        :label="$t('personalInfo.form.displayName')"
                        class="flex-grow-1 px-1"
                    >
                        <ElInput v-model="form.name" @change="text => form.name = text.trim()" />
                    </ElFormItem>
                </div>

                <div class="d-flex flex-row flex-wrap">
                    <ElFormItem
                        :error="serverErrors.birthday"
                        prop="birthday"
                        :label="$t('personalInfo.form.birthday')"
                        class="flex-grow-1 px-1"
                    >
                        <ElDatePicker
                            v-model="form.birthday"
                            value-format="yyyy-MM-dd"
                            prefix-icon="null"
                            type="date"
                            :placeholder="$t('personalInfo.form.birthday')"
                            class="w-100"
                        />
                    </ElFormItem>

                    <ElFormItem
                        :error="serverErrors.gender"
                        prop="gender"
                        :label="$t('personalInfo.form.gender')"
                        class="flex-grow-1 px-1"
                    >
                        <ElSelect
                            v-model="form.gender"
                            :clearable="true"
                            :placeholder="$t('personalInfo.form.select')"
                            class="w-100"
                        >
                            <ElOption
                                v-for="item in gender"
                                :key="item.value"
                                :label="$t(item.label)"
                                :value="item.value"
                            />
                        </ElSelect>
                    </ElFormItem>
                </div>
            </ElForm>

            <div class="d-flex justify-content-end button-info">
                <ElButton size="medium" plain @click="$router.push('/')">
                    {{ $t('personalInfo.form.cancel') }}
                </ElButton>
                <ElButton
                    :loading="processing"
                    size="medium"
                    type="primary"
                    plain
                    class="button-update"
                    @click="submit($refs.form, update)"
                >
                    {{ $t('personalInfo.form.update') }}
                </ElButton>
            </div>
        </ElCard>
    </BContainer>
</template>

<script>
    import _flow from 'lodash/flow';
    import _pick from 'lodash/fp/pick';
    import _assign from 'lodash/fp/assign';
    import formMixin from '~/mixins/form';
    import { updateInfo } from '~/api/profile/personal';
    import Avatar from '~/components/Avatar.vue';
    import ChangeAvatarBtn from '~/components/buttons/ChangeAvatar.vue';

    export default {

        components: {
            ChangeAvatarBtn,
            Avatar,
        },
        mixins: [formMixin],

        props: {
            profile: Object,
        },

        data() {
            const form = _flow(
                _pick(['name', 'email', 'position', 'birthday', 'gender', 'job', 'address', 'phone', 'university', 'real_name']),
                _assign({ current_password: '' }),
            )(this.profile);

            const validations = {
                name: [
                    { required: true, message: this.$t('personalInfo.validations.name.required') },
                    { max: 50, message: this.$t('personalInfo.validations.name.max') },
                ],
                job: [
                    { required: true, message: this.$t('personalInfo.validations.job.required') },
                    { max: 255, message: this.$t('personalInfo.validations.job.max') },
                ],
                gender: [
                    { required: true, message: this.$t('personalInfo.validations.gender') },
                    { type: 'enum', enum: ['male', 'female', 'other'] },
                ],
                birthday: [
                    { required: true, message: this.$t('personalInfo.validations.birthday') },
                ],
            };

            const gender = [
                {
                    value: 'male',
                    label: 'personalInfo.form.male',
                },
                {
                    value: 'female',
                    label: 'personalInfo.form.female',
                },
                {
                    value: 'other',
                    label: 'personalInfo.form.other',
                },
            ];

            return { form, validations, gender };
        },

        methods: {
            async update() {
                this.processing = true;
                try {
                    const { data } = await updateInfo(this.form);

                    const message = this.dirtyEmail
                        ? this.$t('personalInfo.updateEmailSuccess')
                        : this.$t('personalInfo.updateSuccess');

                    this.$message({
                        message,
                        showClose: true,
                        duration: 12000,
                        type: 'success',
                    });

                    this.$store.commit('auth/updateUser', data);
                    this.clearErrors(this.$refs.form);
                    this.$router.push('/');
                } catch (error) {
                    this.handleError(error);
                } finally {
                    this.processing = false;
                }
            },
        },
    };
</script>

<style lang="scss">
    .card-title {
        font-size: 1.375rem;
        font-weight: 400;
    }
    .card-subtitle {
        font-size: 0.875rem;
        padding-top: 0.3rem !important;
    }
    .el-form-item__label {
        margin-bottom: 0;
    }
    .el-input--prefix .el-input__inner {
        padding-left: 15px !important;
    }
    .el-input--suffix .el-input__inner {
        padding-right: 15px !important;
    }
    .change-avt{
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        font-size: 0.875rem;
        min-width: 100%;
        background: rgba(0, 0, 0, 0.6);
        color: #ffffff;
        visibility: visible;
        opacity: 1;
        transition: all 0.15s ease-in-out;
    }

    @media screen and (max-width: 360px) {
        .button-info {
            flex-direction: column;

            .button-update {
                margin-left: 0px !important;
                margin-top: 10px;
            }
        }
    }
</style>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <ElCard class="card--form">
            <h1 class="card-title">
                {{ $t('connectedAccounts.title') }}
            </h1>

            <p class="card-subtitle">
                {{ $t('connectedAccounts.content') }}
            </p>

            <ConnectedAccountsTable
                :accounts="accounts"
                class="mt-4"
                @disconnected="removeDisconnect"
            />
        </ElCard>
    </BContainer>
</template>

<script>
    import _find from 'lodash/find';
    import _findIndex from 'lodash/findIndex';
    import { getConnectedAccounts } from '~/api/social-accounts';
    import ConnectedAccountsTable from '~/components/socialAccounts/List.vue';

    export default {
        components: {
            ConnectedAccountsTable,
        },

        data: () => ({
            accounts: [],
        }),

        async beforeRouteEnter(to, from, next) {
            const { data: accounts } = await getConnectedAccounts();
            const getAccount = provider => _find(accounts, { provider }) || { provider };

            next((vm) => {
                vm.accounts = [
                    getAccount('facebook'),
                    getAccount('google'),
                    getAccount('github'),
                ];
            });
        },

        methods: {
            removeDisconnect(provider) {
                const index = _findIndex(this.accounts, account => account.provider === provider);
                this.accounts.splice(index, 1, { provider });
            },
        },
    };
</script>

<style lang="scss">
    .connected-account {
        &--provider {
            text-transform: capitalize;

            .fab {
                font-size: 1rem;
            }

            .fa-facebook {
                color: #3b5998 !important;
            }

            .fa-google {
                color: #E94820 !important;
            }

            .fa-github {
                color: #333 !important;
            }
        }

        .fa-plug, .fa-unplug {
            font-size: 1.1rem;
            margin-right: 1rem;
        }

        .fa-unplug {
            position: relative;

            &:before {
                content: '\f1e6';
                color: inherit;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0.5rem;
                left: -.25rem;
                width: 1.5rem;
                background: #409eff;
                height: 2px;
                transform: rotate(145deg);
                border-top: 1px solid #fff;
            }
        }
    }
</style>

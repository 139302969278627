<template>
    <section>
        <el-table :data="emails">
            <el-table-column prop="id" width="50" />

            <el-table-column
                label="Email or Domain"
                prop="value"
                show-overflow-tooltip
            />

            <el-table-column label="Actions" fixed="right" width="120">
                <template slot-scope="scope">
                    <actions-cell
                        :data="scope.row"
                        :actions="actionButtons"
                    />
                </template>
            </el-table-column>
        </el-table>
    </section>
</template>

<script>
    import ActionsCell from '~/components/admin/table/ActionsCell.vue';

    export default {
        components: {
            ActionsCell,
        },

        props: {
            emails: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                actionButtons: [
                    {
                        icon: 'el-icon-edit',
                        action: item => this.$emit('edit', item),
                    },
                    {
                        icon: 'el-icon-delete',
                        type: 'danger',
                        confirm: {
                            title: 'Delete blacklist item',
                            text: 'Delete this email/domain?',
                            action: this.delete,
                        },
                    },
                ],
            };
        },

        methods: {
            delete(item) {
                try {
                    this.$store.dispatch('adminSystemSetting/destroy', item.id);
                    this.$message.success('Deleted');
                } catch (e) {
                    this.$message.error('Something went wrong. Cannot delete this item');
                }
            },
        },
    };
</script>

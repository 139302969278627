// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import Vue from 'vue';
import Meta from 'vue-meta';
import _isString from 'lodash/isString';
import _isArray from 'lodash/isArray';
import _map from 'lodash/map';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import store from './store';
import router from './router';
import messages from './locales/index';

import handleBrokenAvatar from './directives/avatar';

import initSentry from './plugins/sentry';
import elementUI from './plugins/element-ui';
import components from './plugins/components';
import filters from './plugins/filters';
import enabledMiddleware from './middleware';

if (module.hot) {
    module.hot.accept();
}

const handleMiddleware = (middleware, context) => {
    if (!enabledMiddleware[middleware]) return Promise.resolve();
    return Promise.resolve(enabledMiddleware[middleware](context));
};

// Enable route middleware:
router.beforeResolve((route, from, next) => {
    if (!route.meta.middleware) return next();

    const { middleware } = route.meta;
    const context = {
        route, from, router, store, next,
    };

    if (_isString(middleware)) return handleMiddleware(middleware, context).then(() => next());
    if (_isArray(middleware)) return Promise.all(_map(middleware, m => handleMiddleware(m))).then(() => next());

    return next();
});

// Sentry for web
initSentry(router);

Vue.use(Meta);
Vue.use(elementUI);
Vue.use(components);
Vue.use(filters);
Vue.use(VueI18n);
Vue.directive('avatar', handleBrokenAvatar);

const i18n = new VueI18n({
    locale: 'vi',
    messages,
});

const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
});

app.$mount('#app');
app.$store.dispatch('session/showMessages');

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import _get from 'lodash/get';
import _mapValues from 'lodash/mapValues';
import _pick from 'lodash/pick';
import _trim from 'lodash/trim';

export const serviceUrl = (name, url = '/') => {
    const subdomain = _get(window.GLOBALS.config.sso.services, `${name}.subdomain`);
    const ssoDomain = window.GLOBALS.config.sso.domain;
    const protocol = window.location.protocol || 'https:';
    const path = _trim(url, '/');

    return subdomain
        ? `${protocol}//${subdomain}.${ssoDomain}/${path}`
        : `${protocol}//${ssoDomain}/${path}`;
};

export const services = _mapValues(
    _pick(window.GLOBALS.config.sso.services, 'viblo', 'code', 'ctf', 'cv', 'learning', 'partner', 'battle'),
    ({ code, name, subdomain }) => ({
        name,
        subdomain,
        homepage: serviceUrl(code),
    }),
);

export const serviceName = name => _get(services, `${name}.name`);

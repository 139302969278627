// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <section class="change-password">
        <ElForm
            ref="form"
            :model="form"
            :rules="validations"
            size="medium"
        >
            <ElAlert v-if="serverErrorMessage" type="error" class="mb-2">
                {{ serverErrorMessage }}
            </ElAlert>

            <ElFormItem
                :error="serverErrors.current_password"
                prop="current_password"
                :label="$t('changePassword.formChange.currentPassword')"
            >
                <ElInput v-model="form.current_password" type="password" />
            </ElFormItem>

            <ElFormItem
                :error="serverErrors.new_password"
                prop="new_password"
                :label="$t('changePassword.formChange.newPassword')"
            >
                <ElInput v-model="form.new_password" type="password" />
            </ElFormItem>

            <ElFormItem
                :error="serverErrors.new_password_confirmation"
                prop="new_password_confirmation"
                :label="$t('changePassword.formChange.confirmNewPassword')"
            >
                <ElInput v-model="form.new_password_confirmation" type="password" />
            </ElFormItem>
        </ElForm>

        <div class="d-flex justify-content-end button-info">
            <ElButton size="medium" plain @click="$router.push('/')">
                {{ $t('personalInfo.form.cancel') }}
            </ElButton>
            <ElButton
                :loading="processing"
                size="medium"
                type="primary"
                plain
                class="button-update"
                @click="submit($refs.form, update)"
            >
                {{ $t('changePassword.formChange.btnChange') }}
            </ElButton>
        </div>
    </section>
</template>

<script>
    import formMixin from '~/mixins/form';
    import { confirmed, noDifferent } from '~/utils/form/validations';
    import { changePassword } from '~/api/security/password';

    export default {
        mixins: [formMixin],

        data() {
            const form = {};
            const validations = {
                current_password: [
                    { required: true, message: this.$t('changePassword.validations.currentPassword') },
                ],
                new_password: [
                    { required: true, message: this.$t('changePassword.validations.newPassword.required') },
                    { min: 8, message: this.$t('changePassword.validations.newPassword.min') },
                    { validator: noDifferent(form, 'current_password'), message: this.$t('changePassword.validations.newPassword.notDifferent') },
                ],
                new_password_confirmation: [
                    { required: true, message: this.$t('changePassword.validations.passwordConfirmation.required') },
                    { validator: confirmed(form, 'new_password'), message: this.$t('changePassword.validations.passwordConfirmation.match') },
                ],
            };

            return { form, validations };
        },

        methods: {
            async update() {
                this.processing = true;
                try {
                    await changePassword(this.form);
                    this.$message({
                        message: this.$t('changePassword.success'),
                        showClose: true,
                        duration: 12000,
                        type: 'success',
                    });

                    this.clearErrors(this.$refs.form);
                    this.$refs.form.resetFields();
                } catch (error) {
                    this.handleError(error);
                } finally {
                    this.processing = false;
                }
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer class="my-5">
        <BRow>
            <BCol span="12" class="d-flex justify-content-center py-4 mb-2">
                <Logo size="medium" />
            </BCol>
        </BRow>
        <BRow>
            <BCol
                sm="12"
                md="10"
                lg="8"
                class="offset-md-1 offset-lg-2"
            >
                <ElCard>
                    <h1 class="card-title">
                        {{ $t(title) }}
                    </h1>

                    <p class="card-subtitle mt-4">
                        <slot name="subtitle" />
                    </p>

                    <ElForm
                        ref="form"
                        :model="form"
                        :rules="validations"
                        class="py-4"
                        @submit.native.prevent="() => {}"
                    >
                        <ElAlert
                            v-if="sentEmailAddress"
                            type="success"
                            class="mb-2"
                            title
                            show-icon
                        >
                            <slot name="success-alert">
                                {{ $t('forgotYourPassword.RequestActivationForm.content1') }}
                                <strong>{{ $t('forgotYourPassword.RequestActivationForm.content2') }}</strong>
                                {{ $t('forgotYourPassword.RequestActivationForm.content3') }}
                                {{ $t('forgotYourPassword.RequestActivationForm.content4') }}
                                <strong>{{ sentEmailAddress }}</strong>.
                            </slot>
                        </ElAlert>

                        <ElFormItem
                            :error="serverErrors.email"
                            prop="email"
                            :label="$t('forgotYourPassword.RequestActivationForm.emailAddress')"
                        >
                            <ElInput v-model="form.email" @change="onChange" />
                        </ElFormItem>

                        <div class="d-flex justify-content-end">
                            <ElButton
                                :loading="processing"
                                :disabled="!form.email"
                                size="medium"
                                type="primary"
                                @click="submit($refs.form, handle)"
                            >
                                {{ $t(submitLabel) }}
                            </ElButton>
                        </div>
                    </ElForm>
                </ElCard>
            </BCol>
        </BRow>
    </BContainer>
</template>

<script>
    import formMixin from '~/mixins/form';
    import Logo from '~/components/site/Logo.vue';

    export default {
        components: {
            Logo,
        },

        mixins: [formMixin],

        props: {
            title: String,
            send: {
                type: Function,
                required: true,
            },
            submitLabel: {
                type: String,
                default: 'Submit',
            },
        },

        data() {
            return {
                form: {},
                sentEmailAddress: null,
                validations: {
                    email: [
                        { required: true, message: this.$t('forgotYourPassword.RequestActivationForm.required') },
                        { type: 'email', message: this.$t('forgotYourPassword.RequestActivationForm.type') },
                    ],
                },
            };
        },

        methods: {
            async handle() {
                this.processing = true;
                try {
                    await this.send(this.form);

                    this.sentEmailAddress = this.form.email;
                    this.$refs.form.resetFields();
                } catch (error) {
                    this.handleError(error);
                } finally {
                    this.processing = false;
                }
            },

            onChange(text) {
                this.sentEmailAddress = '';
                this.form.email = text.toLowerCase().trim();
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <RequestActivationForm :send="send" title="forgotYourPassword.title" submit-label="forgotYourPassword.submitLabel">
        <template slot="subtitle">
            {{ $t('forgotYourPassword.content') }}
        </template>

        <template slot="success-alert">
            {{ $t('forgotYourPassword.sendSuccess.content1') }}
            <strong>{{ $t('forgotYourPassword.sendSuccess.content2') }}</strong>
            {{ $t('forgotYourPassword.sendSuccess.content3') }}
            {{ $t('forgotYourPassword.sendSuccess.content4') }}
            <strong>{{ sentEmailAddress }}</strong>.
        </template>
    </RequestActivationForm>
</template>

<script>
    import { forgotPassword } from '~/api/security/password';
    import RequestActivationForm from '~/components/forms/security/RequestActivation.vue';

    export default {
        components: {
            RequestActivationForm,
        },

        data: () => ({
            sentEmailAddress: '',
        }),

        methods: {
            send(data) {
                this.sentEmailAddress = data.email;
                return forgotPassword(data);
            },
        },
    };
</script>

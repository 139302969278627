// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import Vue from 'vue';

import locale from 'element-ui/lib/locale';
import langEn from 'element-ui/lib/locale/lang/en';

import Container from 'element-ui/lib/container';
import Aside from 'element-ui/lib/aside';
import Header from 'element-ui/lib/header';
import Footer from 'element-ui/lib/footer';
import Main from 'element-ui/lib/main';
import Menu from 'element-ui/lib/menu';
import SubMenu from 'element-ui/lib/submenu';
import MenuItem from 'element-ui/lib/menu-item';
import MenuItemGroup from 'element-ui/lib/menu-item-group';

import Message from 'element-ui/lib/message';
import Notification from 'element-ui/lib/notification';
import MessageBox from 'element-ui/lib/message-box';

import Form from 'element-ui/lib/form';
import FormItem from 'element-ui/lib/form-item';
import Input from 'element-ui/lib/input';
import Select from 'element-ui/lib/select';
import Option from 'element-ui/lib/option';
import CheckboxGroup from 'element-ui/lib/checkbox-group';
import Checkbox from 'element-ui/lib/checkbox';
import Switch from 'element-ui/lib/switch';
import DatePicker from 'element-ui/lib/date-picker';

import Alert from 'element-ui/lib/alert';

import Button from 'element-ui/lib/button';
import ButtonGroup from 'element-ui/lib/button-group';

import Card from 'element-ui/lib/card';
import Popover from 'element-ui/lib/popover';
import Dialog from 'element-ui/lib/dialog';

import Tag from 'element-ui/lib/tag';

import Table from 'element-ui/lib/table';
import TableColumn from 'element-ui/lib/table-column';

import Pagination from 'element-ui/lib/pagination';

import RadioGroup from 'element-ui/lib/radio-group';
import Radio from 'element-ui/lib/radio';
import Autocomplete from 'element-ui/lib/autocomplete';

import Dropdown from 'element-ui/lib/dropdown';
import DropdownMenu from 'element-ui/lib/dropdown-menu';
import DropdownItem from 'element-ui/lib/dropdown-item';

function install() {
    locale.use(langEn);

    Vue.prototype.$msgbox = MessageBox;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$prompt = MessageBox.prompt;
    Vue.prototype.$notify = Notification;
    Vue.prototype.$message = Message;

    Vue.component('ElContainer', Container);
    Vue.component('ElAside', Aside);
    Vue.component('ElHeader', Header);
    Vue.component('ElFooter', Footer);
    Vue.component('ElMain', Main);
    Vue.component('ElMenu', Menu);
    Vue.component('ElSubMenu', SubMenu);
    Vue.component('ElMenuItem', MenuItem);
    Vue.component('ElMenuGroup', MenuItemGroup);

    Vue.component('ElForm', Form);
    Vue.component('ElFormItem', FormItem);
    Vue.component('ElInput', Input);
    Vue.component('ElSelect', Select);
    Vue.component('ElOption', Option);
    Vue.component('ElCheckboxGroup', CheckboxGroup);
    Vue.component('ElCheckbox', Checkbox);
    Vue.component('ElSwitch', Switch);
    Vue.component('ElDatePicker', DatePicker);

    Vue.component('ElButton', Button);
    Vue.component('ElButtonGroup', ButtonGroup);
    Vue.component('ElAlert', Alert);

    Vue.component('ElCard', Card);
    Vue.component('ElPopover', Popover);
    Vue.component('ElDialog', Dialog);

    Vue.component('ElTag', Tag);

    Vue.component('ElTable', Table);
    Vue.component('ElTableColumn', TableColumn);

    Vue.component('ElPagination', Pagination);

    Vue.component('ElRadioGroup', RadioGroup);
    Vue.component('ElRadio', Radio);

    Vue.component('ElAutocomplete', Autocomplete);

    Vue.component('ElDropdown', Dropdown);
    Vue.component('ElDropdownMenu', DropdownMenu);
    Vue.component('ElDropdownItem', DropdownItem);
}

export default { install };

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <div>
        <ElForm
            ref="form"
            :model="form"
            :rules="validations"
            size="medium"
            class="mt-4"
        >
            <ElAlert v-if="serverErrorMessage" type="error" class="mb-2">
                {{ serverErrorMessage }}
            </ElAlert>
            <ElFormItem
                :error="serverErrors.email"
                prop="email"
                :label="$t('emails.addEmail.email.label')"
            >
                <ElInput
                    v-model="form.email"
                    name="email"
                    placeholder="you@your.domain"
                    @change="text => form.email = text.toLowerCase().trim()"
                />
            </ElFormItem>

            <ElFormItem
                v-if="dirtyEmail"
                :error="serverErrors.current_password"
                prop="current_password"
                :label="$t('emails.addEmail.passWord')"
            >
                <ElInput v-model="form.current_password" type="password" name="password" />
            </ElFormItem>
        </ElForm>

        <div class="d-flex justify-content-end button-info">
            <ElButton size="medium" plain @click="$router.push('/')">
                {{ $t('emails.addEmail.cancel') }}
            </ElButton>
            <ElButton
                :loading="processing"
                size="medium"
                type="primary"
                plain
                class="button-update"
                @click="submit($refs.form, update)"
            >
                {{ $t('emails.addEmail.add') }}
            </ElButton>
        </div>
    </div>
</template>

<script>
    import formMixin from '~/mixins/form';
    import { requiredIf } from '~/utils/form/validations';

    export default {
        mixins: [formMixin],
        props: {
            profile: Object,
        },
        data() {
            const form = {
                email: '',
                current_password: '',
            };
            const validations = {
                email: [
                    { required: true, message: this.$t('emails.addEmail.validations.email.required') },
                    { type: 'email', message: this.$t('emails.addEmail.validations.email.type') },
                    { max: 255, message: this.$t('emails.addEmail.validations.email.max') },
                ],
                current_password: {
                    validator: requiredIf,
                    condition: () => this.dirtyEmail,
                    message: this.$t('emails.addEmail.validations.passWord'),
                },
            };
            return { form, validations };
        },
        computed: {
            dirtyEmail() {
                return this.form.email !== '';
            },
        },
        methods: {
            async update() {
                this.processing = true;
                try {
                    await this.$store.dispatch('email/storeEmail', this.form);
                    this.clearErrors(this.$refs.form);
                    this.$refs.form.resetFields();
                    this.$message({
                        type: 'success',
                        message: this.$t('emails.addEmail.success'),
                    });
                } catch (error) {
                    this.handleError(error);
                } finally {
                    this.processing = false;
                }
            },
        },
    };
</script>

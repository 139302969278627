<template>
    <div class="main-section">
        <div class="header mb-4">
            <h4 class="text-capitalize">
                {{ title }}
            </h4>
        </div>

        <div class="m-3 d-flex justify-content-start">
            <FilterTimeRange ref="filterTimeRange" class="mr-3" />
            <FilterUniversity ref="filterUniversity" class="mr-3" />
            <search ref="search" query-param="q" class="w-100 mr-3" />
            <el-button
                type="success"
                icon="el-icon-download"
                plain
                @click="exportExcel"
            >
                Excel
            </el-button>
        </div>

        <UsersTable :users="users" :total="meta.total" />

        <el-pagination
            v-if="meta"
            class="mt-3"
            background
            layout="prev, pager, next"
            :total="meta.total"
            :page-size="meta.per_page"
            :current-page="meta.current_page"
            align="center"
            @current-change="changePage"
        />
    </div>
</template>

<script>
    import _assign from 'lodash/assign';
    import { getUserJoinEvent } from '~/api/admin/event';
    import UsersTable from '~/components/admin/event/Table.vue';
    import Search from '~/components/admin/table/Search.vue';
    import FilterUniversity from '~/components/admin/table/FilterUniversity.vue';
    import FilterTimeRange from '~/components/admin/table/FilterTimeRange.vue';

    export default {

        components: {
            UsersTable,
            Search,
            FilterUniversity,
            FilterTimeRange,
        },

        data() {
            return {
                users: [],
                meta: {},
            };
        },

        computed: {
            title() {
                return `Viblo ${this.$route.params.service} Contest`;
            },
        },

        beforeRouteUpdate(to, from, next) {
            this.getData(to.params.service, to.query);
            if (to.params.service !== from.params.service) {
                this.clearParams();
            }
            next();
        },

        mounted() {
            this.getData(this.$route.params.service, this.$route.query);
        },

        methods: {
            async getData(service, query) {
                const { data, meta } = await getUserJoinEvent(service, query).then(res => res.data);
                this.users = data;
                this.meta = meta;
            },

            changePage(page) {
                const query = _assign({}, this.$route.query, { page });
                this.$router.push({ query });
            },

            clearParams() {
                this.$refs.filterUniversity.clear();
                this.$refs.search.clear();
                this.$refs.filterTimeRange.clear();
            },

            exportExcel() {
                // eslint-disable-next-line global-require
                const queryString = require('query-string');
                const { service } = this.$route.params.service;
                const query = queryString.stringify(this.$route.query, {
                    skipNull: true,
                });
                window.location.href = `/api/admin/events/${service}/user-export?${query}`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .main-section {
        .header {
            color: #303133;
            border-bottom: 1px solid #e6e6e6;
        }
    }
</style>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import _delay from 'lodash/delay';
import _forEach from 'lodash/forEach';
import Message from 'element-ui/lib/message';

const initialState = () => ({
    flash: window.GLOBALS.session,
    messages: window.GLOBALS.messages || [],
});

const actions = {
    showMessages({ state, commit }) {
        let after = 0;

        _forEach(state.messages, (item) => {
            const { type, message, duration = 5000 } = item;

            const show = () => {
                Message({ type, message, duration });
                commit('popMessage');
            };

            _delay(show, after);

            after += message.duration || 5000;
        });
    },
};

const mutations = {
    popMessage(state) {
        state.messages = state.messages.slice(0, state.messages.length - 1);
    },

    pushMessage(state, message) {
        state.messages.push(message);
    },
};

export default {
    state: initialState,
    actions,
    mutations,
    namespaced: true,
};

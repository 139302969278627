/**
 * @param {Promise} promise
 */
function processPromise(promise) {
    if (this.state.processing) {
        return Promise.resolve({});
    }

    this.state.processing = true;

    return promise.then((response) => {
        this.state.processing = false;
        return response;
    }).catch((error) => {
        this.state.processing = false;
        throw error;
    });
}

function processRequest(request) {
    if (typeof request === 'function') {
        return (function process() {
            // eslint-disable-next-line prefer-rest-params
            const promise = request.call(this, ...arguments);
            return processPromise.call(this, promise);
        }).bind(this);
    }
    return processPromise.call(this, request);
}

export const mixin = {
    data: () => ({
        state: {
            processing: false,
        },
    }),

    methods: {
        processRequest,
    },
};

export default mixin;

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <h2>{{ $t('tutorialOAuthApp.titlePage') }}</h2>
        <div class="step">
            <h3>{{ $t('tutorialOAuthApp.step1.title') }}</h3>
            <div class="content-step">
                <p>
                    {{ $t('tutorialOAuthApp.step1.goToPage') }}
                    <a href="https://accounts.viblo.asia/security/apps" target="_blank">https://accounts.viblo.asia/security/apps</a>
                    {{ $t('tutorialOAuthApp.step1.clickButton') }}
                </p>
                <img class="image-app-oauth" :src="newApp" alt="New App OAuth">
                <p>{{ $t('tutorialOAuthApp.step1.infomationApp') }}</p>
                <ul>
                    <li>{{ $t('tutorialOAuthApp.step1.appId') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step1.appSecret') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step1.displayName') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step1.homepageURL') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step1.applicationDescription') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step1.redirect') }}</li>
                </ul>
            </div>
        </div>
        <div class="step">
            <h3>{{ $t('tutorialOAuthApp.step2.title') }}</h3>
            <div class="content-step">
                {{ $t('tutorialOAuthApp.step2.gotoPage') }}
                <a
                    href="http://accounts.viblo.asia/authorize?grant_type=authorization_code&client_id=&scope=profile&response_type=code"
                >
                    http://accounts.viblo.asia/authorize?grant_type=authorization_code&client_id=&scope=profile&response_type=code
                </a>
                {{ $t('tutorialOAuthApp.step2.parameters') }}
                <ul>
                    <li>{{ $t('tutorialOAuthApp.step2.parameter1') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step2.parameter2') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step2.parameter3') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step2.parameter4') }}</li>
                </ul>
                <img class="image-app-oauth" :src="authorized" alt="User Authorized">
                <p>
                    {{ $t('tutorialOAuthApp.step2.description') }}
                </p>
            </div>
        </div>

        <div class="step">
            <h3>{{ $t('tutorialOAuthApp.step3.title') }}</h3>
            <p class="content-step">
                {{ $t('tutorialOAuthApp.step3.description') }}
                <ul>
                    <li>{{ $t('tutorialOAuthApp.step3.parameter1') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step3.parameter2') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step3.parameter3') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step3.parameter4') }}</li>
                    <li>{{ $t('tutorialOAuthApp.step3.parameter5') }}</li>
                </ul>
                <img class="image-app-oauth" :src="getAccessToken" alt="Get access token">
            </p>
        </div>

        <div class="step">
            <h3>{{ $t('tutorialOAuthApp.step4.title') }}</h3>
            <div class="content-step">
                <p>
                    {{ $t('tutorialOAuthApp.step4.description') }}
                </p>
                <p>{{ $t('tutorialOAuthApp.step4.result') }}</p>
                <img class="image-app-oauth" :src="sendAccessToken" alt="Send access token">
            </div>
        </div>
    </BContainer>
</template>
<script>
    import newApp from '~/assets/new_app.png';
    import authorized from '~/assets/user_authorized.png';
    import getAccessToken from '~/assets/get_access_token.png';
    import sendAccessToken from '~/assets/send_access_token.png';

    export default {
        data: () => ({
            newApp, authorized, getAccessToken, sendAccessToken,
        }),
    };
</script>

<style lang="scss" scoped>
    .step {
        margin-top: 4rem;
    }

    .content-step {
        margin: 2rem 0 0 2rem;
    }

    .image-app-oauth {
        width: 100%;
        height: auto;
    }
</style>

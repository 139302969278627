<template>
    <el-dialog
        :title="title"
        class="report-form"
        width="30%"
        :visible.sync="show"
        :before-close="close"
    >
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-position="top"
            size="mini"
        >
            <el-form-item prop="time" label="How long do you want to ban this user?">
                <el-select v-model="form.time" class="w-100 mb-05">
                    <el-option
                        v-for="time in banTimes"
                        :key="time.value"
                        :label="time.label"
                        :value="time.value"
                    />
                </el-select>
            </el-form-item>

            <el-form-item prop="reason" label="Reason for delete this user">
                <el-select v-model="form.reason" class="w-100 mb-05">
                    <el-option
                        v-for="reason in banReasons"
                        :key="reason.value"
                        :label="reason.label"
                        :value="reason.value"
                    />
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-checkbox v-model="form.is_banned_all">
                    Ban all services
                </el-checkbox>
            </el-form-item>

            <el-form-item v-if="!form.is_banned_all" prop="services">
                <el-select
                    v-model="form.services"
                    style="width: 100%"
                    multiple
                >
                    <el-option
                        v-for="(service, code) in services"
                        :key="code"
                        :label="service.name"
                        :value="code"
                    />
                </el-select>
            </el-form-item>

            <el-form-item prop="source">
                <el-input
                    v-model="form.source"
                    :autosize="{ minRows: 3 }"
                    type="textarea"
                    placeholder="Comment"
                />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">
                Cancel
            </el-button>
            <el-button type="primary" @click="submit">
                Submit
            </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { services } from '~/utils/sso';

    const rules = {
        time: { required: true, message: 'Please choose an option.' },
        reason: { required: true, message: 'Please choose an option.' },
        services: { type: 'array', required: true, message: 'Please choose an service.' },
    };

    const emptyState = () => ({
        time: 'forever',
        reason: 'spam',
        source: '',
        is_banned_all: true,
        services: [],
    });

    export default {
        props: {
            title: {
                type: String,
                default: 'Ban User',
            },
        },

        data() {
            const banTimes = [
                {
                    value: 'three_days',
                    label: '3 Days',
                },
                {
                    value: 'one_week',
                    label: '1 Week',
                },
                {
                    value: 'two_weeks',
                    label: '2 Week',
                },
                {
                    value: 'one_month',
                    label: '1 Month',
                },
                {
                    value: 'forever',
                    label: 'Forever',
                },
            ];

            const banReasons = [
                {
                    value: 'spam',
                    label: 'Spam',
                },
                {
                    value: 'tos_violation',
                    label: 'Rules Violation',
                },
                {
                    value: 'harashment',
                    label: 'Harashment',
                },
                {
                    value: 'infringes_copyright',
                    label: 'Infringes Copyright',
                },
            ];

            return {
                show: false,
                form: emptyState(),
                rules,
                services,
                banTimes,
                banReasons,
            };
        },

        methods: {
            submit() {
                this.$refs.form.validate((valid) => {
                    if (!valid && !this.form.is_banned_all) {
                        return;
                    }
                    this.$emit('save', this.form);
                    this.close();
                });
            },

            open(data = null) {
                this.show = true;
                this.form = data || emptyState();
            },

            close() {
                this.show = false;
                this.form = emptyState();
            },
        },
    };
</script>

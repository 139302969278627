<template>
    <el-table :data="members" style="width: 100%">
        <el-table-column label="ID" prop="id" width="80" />

        <el-table-column
            prop="name"
            :formatter="renderName"
            :show-overflow-tooltip="true"
            label="Name"
            min-width="150"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Username"
            prop="username"
            width="150"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Email"
            prop="email"
            width="250"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Phone"
            prop="phone"
            width="150"
        />

        <el-table-column :formatter="renderTime('birthday', 'dd/MM/yyyy')" label="Birthday" width="150" />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Gender"
            prop="gender"
            width="100"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="University"
            prop="university"
            width="150"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Address"
            prop="address"
        />
    </el-table>
</template>

<script>
    import table from '../mixins/table';
    import NameCell from '~/components/admin/table/NameCell.vue';

    export default {
        mixins: [table],

        props: {
            members: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            renderName(user) {
                return <NameCell user={user} />;
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <div v-if="error" class="p-2">
        <h1 class="card-title">
            {{ error.title }}
        </h1>
        <p class="card-subtitle">
            <span>{{ error.detail.message }}</span>
            <span>{{ error.detail.hint }}</span>
        </p>
    </div>

    <BContainer v-else class="p-4">
        <BRow>
            <BCol span="12" class="d-flex justify-content-center py-4 mb-2">
                <VibloLogo size="medium" />
            </BCol>
        </BRow>

        <BRow v-if="app">
            <BCol
                :xs="12"
                :sm="10"
                :lg="7"
                class="authorize ml-auto mr-auto"
            >
                <ElCard class="authorize-card">
                    <header class="authorize-card__header d-flex justify-content-center">
                        <Avatar
                            :image="user.picture"
                            :title="`${user.name} @${user.username}`"
                            size="xl"
                            class="mx-2"
                        />
                        <span class="fas fa-exchange-alt" />
                        <Avatar
                            :image="app.logo"
                            :title="app.name"
                            :default-image="defaultLogo"
                            size="xl"
                            class="mx-2"
                            rectangle
                        />
                    </header>

                    <h1 class="card-title text-center my-4">
                        {{ $t('authorize.title') }} {{ app.name }}
                    </h1>

                    <section v-if="app.description" class="authorize-card__app">
                        <p class="mb-0">
                            <a :href="app.homepage" target="_blank" rel="noopener">
                                {{ app.name }}
                            </a>
                            - {{ app.description }}
                        </p>
                    </section>

                    <section class="authorize-card__body">
                        <p>
                            {{ $t('authorize.appAccess', { name: app.name }) }}
                            <strong>{{ user.name }} @{{ user.username }}</strong>.
                        </p>

                        <div v-if="!!scopes.length" class="authorize-card__scopes">
                            <p class="mb-1">
                                {{ $t('authorize.appWillBe') }}
                            </p>

                            <ul>
                                <li v-for="(scope, key) in scopes" :key="key">
                                    <i class="fa fa-check" />
                                    <span>{{ $t(`authorize.${scope.id}`) }}</span>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section class="authorize-card__footer">
                        <p class="authorize-card__warning">
                            {{ $t('authorize.content') }}
                        </p>

                        <ElForm
                            method="post"
                            class="authorize-card__actions text-right"
                            action="/oauth/authorize"
                        >
                            <input type="hidden" name="_token" :value="csrfToken">
                            <input type="hidden" name="client_id" :value="app.id">
                            <input type="hidden" name="state" :value="$route.state">

                            <ElButton
                                size="small"
                                name="_method"
                                value="DELETE"
                                native-type="submit"
                            >
                                {{ $t('authorize.deny') }}
                            </ElButton>

                            <ElButton
                                size="small"
                                name="_method"
                                value="POST"
                                type="primary"
                                native-type="submit"
                            >
                                {{ $t('authorize.allow') }}
                            </ElButton>
                        </ElForm>
                    </section>
                </ElCard>
            </BCol>
        </BRow>
    </BContainer>
</template>

<script>
    import _get from 'lodash/get';
    import { mapState } from 'vuex';
    import { getAuthorize } from '~/api/security/tokens';

    import defaultLogo from '~/assets/icons/svg/3d.svg';

    import VibloLogo from '~/components/site/Logo.vue';
    import Avatar from '~/components/Avatar.vue';

    export default {
        components: {
            VibloLogo,
            Avatar,
        },

        data: () => ({
            app: null,
            scopes: [],
            exception: null,
        }),

        computed: {
            ...mapState('auth', ['user']),

            defaultLogo() {
                return defaultLogo;
            },

            csrfToken() {
                return document.head.querySelector('meta[name=csrf-token]').getAttribute('content');
            },

            error() {
                if (!this.exception) return null;

                const response = _get(this.exception, 'response.data');

                return {
                    title: this.exception.message,
                    detail: {
                        error: _get(response, 'error', 'unknown'),
                        message: _get(response, 'message', 'Something went wrong.'),
                        hint: _get(response, 'hint'),
                    },
                };
            },

            redirectUrl() {
                const redirectUri = this.$route.query.redirect_uri || '';

                if (!redirectUri) return null;

                return redirectUri.split('?')[0];
            },
        },

        async beforeRouteEnter(to, from, next) {
            try {
                const { data } = await getAuthorize(to.query);

                next((vm) => {
                    vm.app = data.client;
                    vm.scopes = data.scopes;
                });
            } catch (e) {
                const redirect = _get(e, 'response.data.location');

                if (redirect) {
                    window.location = redirect;
                    return;
                }

                next((vm) => {
                    vm.exception = e;
                });
            }
        },
    };
</script>

<style lang="scss">
    .authorize {
        &-card {
            strong {
                font-weight: 500;
            }

            &__header {
                .avatar {
                    cursor: unset;
                }
                .fa-exchange-alt {
                    font-size: 2rem;
                    color: #d6d6d6;
                    display: block;
                    align-self: center;
                    margin-left: .5rem;
                    margin-right: .5rem;
                }
            }

            &__body {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }

            &__scopes ul {
                padding-left: 1.5rem;
                list-style-type: none;

                li .fa-check {
                    color: green;
                    padding-right: 0.625rem;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }
            }

            &__warning {
                border-left: solid 2px #e6a23c;
                font-size: 0.875rem;
                padding-left: 1rem;
            }

            &__actions {
                margin-top: 2rem;
            }
        }
    }
</style>

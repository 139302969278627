// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <ElTable :data="apps">
        <ElTableColumn type="index" width="80" />

        <ElTableColumn prop="name" :label="$t('oAuthApplications.table.displayName')">
            <template slot-scope="{ row }">
                <RouterLink :to="`/security/apps/${row.hash_id}`">
                    {{ row.name }}
                </RouterLink>
            </template>
        </ElTableColumn>

        <ElTableColumn prop="hash_id" :label="$t('oAuthApplications.table.appID')" width="150" />
        <ElTableColumn
            prop="created_at"
            :label="$t('oAuthApplications.table.createdAt')"
            :formatter="humanizeTime"
        />

        <ElTableColumn :label="$t('oAuthApplications.table.actions')" width="200">
            <template slot-scope="{ row }">
                <ElButton
                    plain
                    type="danger"
                    size="small"
                    icon="el-icon-delete"
                    @click.prevent="deleteApp(row)"
                >
                    {{ $t('oAuthApplications.table.deleteApp') }}
                </ElButton>
            </template>
        </ElTableColumn>
    </ElTable>
</template>

<script>
    import { deleteApp } from '~/api/security/tokens';
    import { displayError } from '~/utils/pages';
    import { formatTime } from '~/filters/time';

    export default {
        props: {
            apps: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            humanizeTime(item) {
                return formatTime(item.created_at);
            },

            deleteApp(app) {
                this.$confirm(this.$t('oAuthApplications.deleteApp.content'), this.$t('oAuthApplications.deleteApp.title'))
                    .then(async () => {
                        try {
                            await deleteApp(app.hash_id);

                            this.$message.success(this.$t('oAuthApplications.deleteApp.success'));
                            this.$emit('deleted', app);
                        } catch (e) {
                            displayError(this.$message.error, e);
                        }
                    })
                    .catch(() => {});
            },
        },
    };
</script>

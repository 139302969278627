// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <ElHeader class="d-flex align-items-center shadow px-8" :class="{'background-header': !user.picture}">
        <ElContainer class="d-flex justify-content-between align-items-center">
            <Logo />

            <div class="d-flex align-items-center">
                <SwitchLanguage class="mr-5" />
                <div v-if="user" class="d-inline-flex align-items-center">
                    <ServicesMenu :profile="user" :accounts-url="accountsUrl" />
                    <UserMenu ref="menu" :profile="user" :accounts-url="accountsUrl">
                        <template slot="menu">
                            <div v-if="isAdmin || isEventEditor" class="user-menu__menu-item">
                                <a
                                    href="/admin"
                                    target="_blank"
                                    rel="noopener"
                                    class="link link--plain"
                                    @click="$refs.menu.close()"
                                >
                                    <i class="fa fas fa-tachometer-alt" />{{ $t('userMenu.goToDashboard') }}
                                </a>
                            </div>
                        </template>
                    </UserMenu>
                </div>
            </div>
        </ElContainer>
    </ElHeader>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';

    import Logo from '~/components/site/Logo.vue';
    import UserMenu from '~/components/UserMenu.vue';
    import ServicesMenu from '~/components/ServicesMenu.vue';
    import SwitchLanguage from '~/components/SwitchLanguage.vue';

    export default {
        components: {
            Logo,
            UserMenu,
            ServicesMenu,
            SwitchLanguage,
        },

        data: () => ({
            accountsUrl: `${window.location.protocol}//${window.location.host}`,
        }),

        computed: {
            ...mapState('auth', ['user']),
            ...mapGetters('auth', ['isAdmin', 'isEventEditor']),

            getLocale() {
                return this.$i18n.locale;
            },
        },

        watch: {
            getLocale() {
                localStorage.setItem('locale', this.$i18n.locale);
            },
        },
    };
</script>

<style lang="scss">
    .menu-icon {
        vertical-align: middle;
        .fa {
            cursor: pointer;
        }
    }
    .background-header {
        background-color: white;
        z-index: 2010;
        position: fixed;
        width: 100%;
    }
</style>

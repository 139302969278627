// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <ElAlert
        v-if="registeredSuccess"
        :closable="false"
        type="success"
        class="my-4"
    >
        <div v-if="mustVerifyEmail">
            <p>
                {{ $t('register.mustVerifyEmail.welcome') }} <strong>{{ form.name }}</strong>,
                {{ $t('register.mustVerifyEmail.content1') }}
                <strong>{{ $t('register.mustVerifyEmail.content2') }}</strong>.
                {{ $t('register.mustVerifyEmail.content3') }} <strong>{{ form.email }}</strong>.
                {{ $t('register.mustVerifyEmail.content4') }}
            </p>
            <p>
                {{ $t('register.mustVerifyEmail.noReceive') }}
                <RouterLink to="/send-activation">
                    <strong>{{ $t('register.mustVerifyEmail.resend') }}</strong>
                </RouterLink>
                {{ $t('register.mustVerifyEmail.activationEmail') }}
            </p>
        </div>
        <p v-else>
            {{ $t('register.mustVerifyEmail.welcome') }}
            <strong>{{ form.name }}</strong>{{ $t('register.mustVerifyEmail.yourAccount') }}
            <strong>{{ $t('register.mustVerifyEmail.registeredSuccessfully') }}</strong>.
            {{ $t('register.mustVerifyEmail.accountActivated') }} <RouterLink to="/login">
                {{ $t('register.mustVerifyEmail.login') }}
            </RouterLink> {{ $t('register.mustVerifyEmail.explore') }}
        </p>
    </ElAlert>

    <div v-else>
        <ElForm ref="form" :model="form" :rules="validations">
            <ElAlert v-if="serverErrorMessage" type="error" class="mb-2">
                {{ serverErrorMessage }}
            </ElAlert>

            <ElFormItem :error="serverErrors.name" prop="name">
                <ElInput
                    v-model="form.name"
                    :placeholder="$t('register.form.yourName')"
                    @change="text => form.name = text.trim()"
                />
            </ElFormItem>

            <BRow>
                <BCol sm="6">
                    <ElFormItem :error="serverErrors.email" prop="email">
                        <ElInput
                            v-model="form.email"
                            :disabled="socialData && socialData.email"
                            :title="
                                hasSocialProfile ? $t('register.form.emailMatched') : ''
                            "
                            :placeholder="$t('register.form.emailAddress')"
                            @change="text => form.email = text.toLowerCase().trim()"
                        />
                    </ElFormItem>
                </BCol>
                <BCol sm="6">
                    <ElFormItem :error="serverErrors.username" prop="username">
                        <ElInput
                            v-model="form.username"
                            :placeholder="$t('register.form.username')"
                            @change="text => form.username = text.trim()"
                        />
                    </ElFormItem>
                </BCol>
            </BRow>

            <ElFormItem :error="serverErrors.password" prop="password">
                <ElInput v-model="form.password" type="password" :placeholder="$t('register.form.password')" />
            </ElFormItem>

            <ElFormItem :error="serverErrors.password_confirmation" prop="password_confirmation">
                <ElInput
                    v-model="form.password_confirmation"
                    type="password"
                    :placeholder="$t('register.form.confirmPassword')"
                />
            </ElFormItem>

            <ElFormItem :error="serverErrors.terms" prop="terms">
                <ElCheckbox v-model="form.terms" class="mb-0">
                    <span>{{ $t('register.form.agree') }}</span>
                    <a :href="serviceUrl('viblo', '/terms')" target="_blank" rel="noopener">
                        {{ $t('register.form.termsOfService') }}
                    </a>
                </ElCheckbox>
            </ElFormItem>
        </ElForm>

        <div class="text-center">
            <ElButton
                :disabled="!form.terms"
                :loading="processing"
                type="primary"
                class="w-100 my-2"
                @click="submit($refs.form, register)"
            >
                {{ $t('register.form.signUp') }}
            </ElButton>
        </div>

        <div class="d-flex align-items-center justify-content-between my-3">
            <hr class="flex-fill m-0">
            <span class="mx-3">
                {{ $t('register.form.orLoginWith') }}
            </span>
            <hr class="flex-fill m-0">
        </div>

        <SocialLogin class="my-3" />
    </div>
</template>

<script>
    import _get from 'lodash/get';
    import _assign from 'lodash/assign';
    import { register } from '~/api/auth';
    import { serviceUrl } from '~/utils/sso';
    import { confirmed, accepted } from '~/utils/form/validations';

    import formMixin from '~/mixins/form';
    import SocialLogin from '~/components/auth/SocialLogin.vue';

    export default {
        components: {
            SocialLogin,
        },

        mixins: [formMixin],

        data() {
            const socialData = _get(this.$store.state.session, 'flash.social', {});
            const { email, name, username } = _get(this.$store.state.session, 'flash.social', {});
            const form = _assign({}, { email, name, username });
            const validations = {
                name: [
                    { required: true, message: this.$t('register.form.validations.name.required') },
                    { max: 50, message: this.$t('register.form.validations.name.max') },
                ],
                email: [
                    { required: true, message: this.$t('register.form.validations.email.required') },
                    { type: 'email', message: this.$t('register.form.validations.email.type') },
                    { max: 255, message: this.$t('register.form.validations.email.max') },
                ],
                username: [
                    { required: true, message: this.$t('register.form.validations.username.required') },
                    { max: 20, message: this.$t('register.form.validations.username.max') },
                ],
                password: [
                    { required: true, message: this.$t('register.form.validations.password.required') },
                    { min: 8, message: this.$t('register.form.validations.password.min') },
                ],
                password_confirmation: [
                    { required: true, message: this.$t('register.form.validations.passwordConfirmation.required') },
                    { validator: confirmed(form, 'password'), message: this.$t('register.form.validations.passwordConfirmation.confirmed') },
                ],
                terms: [
                    { validator: accepted, message: this.$t('register.form.validations.terms') },
                ],
            };

            return {
                socialData,
                form,
                validations,
                registeredSuccess: false,
                mustVerifyEmail: null,
            };
        },

        computed: {
            hasSocialProfile() {
                return !!_get(this.$store.state.session, 'flash.social');
            },
        },

        beforeDestroy() {
            this.$store.state.session.flash = null;
        },

        methods: {
            serviceUrl,

            async register() {
                try {
                    this.processing = true;
                    const res = await register(this.form);
                    this.registeredSuccess = true;
                    this.mustVerifyEmail = res.data.must_verify_email;
                } catch (e) {
                    this.handleError(e);
                } finally {
                    this.processing = false;
                }
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import config from './config';
import session from './session';
import dialogs from './dialogs';
import email from './email';
import adminUser from './admin/users';

import adminSystemSetting from './admin/system-settings';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auth,
        config,
        session,
        dialogs,
        email,
        adminUser,
        adminSystemSetting,
    },
});

export default store;

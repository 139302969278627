<template>
    <div class="main-section">
        <div class="header mb-4">
            <h4>Email Blacklist</h4>
        </div>
        <div class="mb-2">
            <el-button icon="el-icon-plus" @click="() => $refs.create.open()">
                Add Blacklist Item
            </el-button>
        </div>

        <email-list
            :emails="settings"
            @edit="(item) => $refs.edit.open(item)"
        />

        <email-form
            ref="create"
            :validate-form="true"
            title="Add New Blacklist Item"
            @save="create"
        />
        <email-form
            ref="edit"
            :validate-form="true"
            title="Edit Blacklist Item"
            @save="edit"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import EmailList from '~/components/admin/email-blacklist/List.vue';
    import EmailForm from '~/components/admin/email-blacklist/Form.vue';

    export default {
        name: 'EmailBlacklistSettings',

        components: {
            EmailList,
            EmailForm,
        },

        head: {
            title: 'Email Blacklist',
        },

        computed: {
            ...mapState('adminSystemSetting', ['settings']),
        },

        mounted() {
            this.$store.dispatch('adminSystemSetting/getSettings', { item: 'domain_email_blacklist' });
        },

        methods: {
            create({ value }) {
                this.$store.dispatch('adminSystemSetting/storeSetting', {
                    item: 'domain_email_blacklist',
                    value,
                });
            },

            edit(item) {
                this.$store.dispatch('adminSystemSetting/updateSetting', item);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .main-section {
        .header {
            color: #303133;
            border-bottom: 1px solid #e6e6e6;
        }
    }
</style>

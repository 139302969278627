<template>
    <List :roles="roles" :actions="{ store, update, destroy }" />
</template>

<script>
    import List from '@/components/admin/roles/List.vue';
    import {
        fetch, store, update, destroy,
    } from '@/api/admin/role';
    import { updateData } from '~/utils/manage-page';

    export default {
        components: {
            List,
        },

        data() {
            return {
                roles: [],
            };
        },

        async mounted() {
            const { data } = await fetch();

            this.roles = data;
        },

        methods: {
            store(role) {
                return store(role).then((response) => {
                    const newRole = response.data;
                    this.roles = [...this.roles, newRole];
                });
            },

            async update(role, input) {
                await update(role.id, input);
                this.roles = updateData(this.roles, 'id', input);
            },

            destroy(role) {
                destroy(role.id).then(() => {
                    this.roles = this.roles.filter(item => item.id !== role.id);
                });
            },
        },
    };
</script>

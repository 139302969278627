// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import _assign from 'lodash/assign';
import _find from 'lodash/find';

const initialState = () => ({
    user: window.GLOBALS.currentUser,
});

const getters = {
    authenticated: ({ user }) => Boolean(user),
    isAdmin: ({ user }) => Boolean(user && user.is_admin),
    isEventEditor: ({ user }) => (user && _find(user.roles, { role: 'event-editor' })),
};

const mutations = {
    updateUser(state, data) {
        state.user = _assign({}, state.user, data);
    },
};

export default {
    state: initialState,
    getters,
    mutations,
    namespaced: true,
};

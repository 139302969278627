// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import _curry from 'lodash/curry';
import _get from 'lodash/get';
import _last from 'lodash/last';
import _keys from 'lodash/keys';

export const displayError = _curry((showError, error) => {
    if (error.response) {
        const statusText = _get(error, 'response.statusText');
        const message = _get(error, 'response.data.message');
        const errors = _get(error, 'response.data.errors');
        const flatErrors = errors ? _last(errors[_last(_keys(errors))]) : null;

        showError(flatErrors || message || statusText);
    } else {
        showError('Something went wrong!');
    }
});

export default {
    displayError,
};

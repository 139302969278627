// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <ElCard class="card--form">
            <h1 class="card-title">
                {{ $t('changePassword.title') }}
            </h1>

            <p class="card-subtitle">
                {{ $t('changePassword.content') }}
            </p>

            <ChangePasswordForm class="mt-4" title="Password" />
        </ElCard>
    </BContainer>
</template>

<script>
    import ChangePasswordForm from '~/components/forms/security/ChangePassword.vue';

    export default {
        components: {
            ChangePasswordForm,
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <ElCard>
            <header>
                <h1 class="card-title">
                    {{ $t('oAuthApplications.formNewApp.title') }}
                </h1>
                <p class="card-subtitle">
                    {{ $t('oAuthApplications.formNewApp.content') }}
                </p>
            </header>

            <OAuthAppForm
                ref="form"
                submit-label="oAuthApplications.formNewApp.registerApplication"
                @submit="register"
            />
        </ElCard>
    </BContainer>
</template>

<script>
    import { createApp } from '~/api/security/tokens';
    import OAuthAppForm from '~/components/forms/security/OAuthApp.vue';

    export default {
        components: {
            OAuthAppForm,
        },

        methods: {
            async register(form) {
                try {
                    const { data: client } = await createApp(form);
                    this.$message.success(this.$t('oAuthApplications.formNewApp.success'));
                    this.$router.push(`/security/apps/${client.hash_id}`);
                } catch (e) {
                    this.$refs.form.handleError(e);
                }
            },
        },
    };
</script>

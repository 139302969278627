<template>
    <div v-popover:popover class="table-services-cell">
        <el-tag
            v-for="service in services"
            :key="service"
            class="ml-1"
        >
            {{ service }}
        </el-tag>
        <el-popover
            ref="popover"
            :title="title"
            width="300"
            trigger="hover"
        >
            <el-tag
                v-for="service in services"
                :key="service"
                class="ml-1"
            >
                {{ service }}
            </el-tag>
        </el-popover>
    </div>
</template>

<script>
    export default {
        props: {
            services: {
                type: Array,
                default: () => {},
            },
            title: {
                type: String,
                default: 'Services',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .table-services-cell {
        display: table-cell;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>

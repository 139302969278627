<template>
    <div>
        <el-tag
            v-for="(role, index) in roles"
            :key="index"
            closable
            class="mr-2"
            @close="$emit('remove-role', role)"
        >
            {{ role.role }}
        </el-tag>
    </div>
</template>

<script>
    export default {
        props: {
            roles: Array,
        },
    };
</script>

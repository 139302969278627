const vi = {
    homePage: {
        title: 'Chào mừng,',
        content: 'Quản lý thông tin cá nhân của bạn và bảo mật với Viblo Accounts',
        myProfile: 'Thông tin của tôi',
        passWord: 'Mật khẩu',
        connectedAccounts: 'Tài khoản được liên kết',
        accessTokens: 'Mã bảo mật truy cập',
        oAuthApps: 'OAuth Applications',
        vibloServices: 'Dịch vụ Viblo',
    },

    menuAside: {
        home: 'Trang Chủ',
        myProfile: 'Thông Tin Của Tôi',
        personalInfo: 'Thông Tin Cá Nhân',
        contactInfo: 'Thông Tin Liên Hệ',
        security: 'Bảo Mật',
        password: 'Mật Khẩu',
        connectedAccounts: 'Tài khoản được liên kết',
        developerSettings: 'Cài đặt nhà phát triển',
        oAuthApplications: 'OAuth Applications',
        personalAccessTokens: 'Mã bảo mật truy cập cá nhân',
        authorizedOAuthApps: 'OAuth Applications được ủy quyền',
        collapseSidebar: 'Thu gọn Thanh bên',
    },

    userMenu: {
        edit: 'Sửa',
        signOut: 'Đăng xuất',
        goToDashboard: 'Đến trang quản lý',
    },

    personalInfo: {
        title: 'Thông Tin Cá Nhân',
        content: 'Quản lý thông tin cá nhân của bạn.',
        changeAvatar: {
            title: 'Nhấp chuột để tải lên ảnh đại diện của bạn.',
            change: 'Thay đổi',
            changeDialog: {
                title: 'Thay đổi hình ảnh đại diện',
                content: 'Chỉ 1 bước để dễ dàng tạo ảnh đại diện tuyệt đẹp',
                tip: 'Mẹo: Sử dụng con lăn để thu phóng, kéo để di chuyển ảnh.',
                save: 'Lưu',
                FileMismatch: 'Loại tệp của bạn không khớp. Vui lòng thử một loại tệp khác: {values}.',
                sizeExceed: 'Kích thước tệp vượt quá. Vui lòng chọn một tệp nhỏ hơn ',
            },
        },
        form: {
            userName: 'Tên tài khoản',
            displayName: 'Tên hiển thị',
            birthday: 'Ngày sinh',
            gender: 'Giới tính',
            cancel: 'Hủy bỏ',
            update: 'Cập nhật',
            male: 'Nam',
            female: 'Nữ',
            other: 'Khác',
            select: 'Chọn',
        },
        validations: {
            name: {
                required: 'Tên là bắt buộc',
                max: 'Tên không được dài hơn 50 ký tự',
            },
            job: {
                required: 'Chức danh công việc là bắt buộc',
                max: 'Chức danh công việc không được dài hơn 255 ký tự',
            },
            gender: 'Giới tính là bắt buộc',
            birthday: 'Ngày sinh là bắt buộc',
        },
        updateEmailSuccess: 'Cập nhật thành công! Chúng tôi đã gửi một email với một liên kết xác nhận đến địa chỉ email mới của bạn. Hãy kiểm tra hộp thư đến của bạn!',
        updateSuccess: 'Thông tin cá nhân của bạn được cập nhật thành công!',
    },

    contactInfo: {
        title: 'Thông Tin Liên Hệ',
        content: 'Quản lý thông tin liên hệ của bạn.',
        realName: 'Tên thật',
        phone: 'Số điện thoại',
        address: 'Địa chỉ',
        university: 'Trường đại học',
        validations: {
            realName: {
                required: 'Tên thật là bắt buộc',
                max: 'Tên thật không được dài hơn 50 kí tự',
            },
            phone: {
                required: 'Số điện thoại là bắt buộc',
                length: 'Số điện thoại không được dài hơn 11 ký tự',
                pattern: 'Định dạng số điện thoại không hợp lệ.',
            },
            address: {
                required: 'Địa chỉ là bắt buộc',
                length: 'Địa chỉ không được dài hơn 255 ký tự',
            },
        },
        success: 'Thông tin liên hệ của bạn đã được cập nhật thành công!',
        selectUniversity: 'Chọn trường đại học',
    },

    emails: {
        content: 'Email dự phòng của bạn cũng sẽ nhận được những thông báo liên quan đến bảo mật tài khoản và cũng được sử dụng để đặt lại mật khẩu.',
        hasInvalidEmails: {
            content1: 'Chúng tôi không thể gửi email đến một số địa chỉ email của bạn. Những địa chỉ email đó đã được đánh dấu bằng',
            content2: 'không hợp lệ',
            content3: 'trong danh sách dưới đây. Vui lòng thêm và đặt địa chỉ email hợp lệ làm địa chỉ email chính và xóa mọi địa chỉ không hợp lệ để ẩn cảnh báo này.',
        },
        addEmail: {
            email: {
                label: 'Thêm địa chỉ email mới',
            },
            passWord: 'Mật khẩu hiện tại',
            cancel: 'Bỏ qua',
            add: 'Thêm',
            validations: {
                email: {
                    required: 'Email là bắt buộc',
                    type: 'Phải là một email hợp lệ',
                    max: 'Email không được dài hơn 255 ký tự',
                },
                passWord: 'Mật khẩu của bạn là bắt buộc để thêm email',
            },
            success: 'Thêm email thành công!',
        },
        listEmail: {
            primary: 'Email Chính',
            verified: 'Đã xác minh',
            unverified: 'Chưa được xác minh',
            invalid: 'không hợp lệ',
            verification: 'Gửi email xác minh.',
            resend: 'Gửi lại',
            makePrimary: 'Đặt làm email chính',
            destroy: {
                title: 'Bạn có chắc không ?',
                content: 'Xóa email',
                btn: 'Bỏ qua',
                success: 'Đã xóa',
            },
            methodResend: {
                success1: 'Chúng tôi đã gửi một email xác minh tới ',
                success2: '. Hãy làm theo hướng dẫn trong đó.',
                error: 'Đã xảy ra lỗi. Vui lòng thử lại sau.',
            },
            methodMakePrimary: {
                title: 'Bạn có chắc không ?',
                content: 'Thay đổi email chính',
                btn: 'Bỏ qua',
                success: 'Đã hoàn tất thay đổi email chính',
            },
        },
    },

    changePassword: {
        title: 'Đổi mật khẩu',
        content: 'Thay đổi mật khẩu cho tài khoản của bạn. Bạn nên đặt mật khẩu mạnh để chặn những truy cập trái phép vào tài khoản của mình.',
        formChange: {
            currentPassword: 'Mât khẩu hiện tại',
            newPassword: 'Mật khẩu mới',
            confirmNewPassword: 'Xác nhận mật khẩu mới',
            btnChange: 'Đổi mật khẩu',
        },
        validations: {
            currentPassword: 'Mật khẩu hiện tại là bắt buộc',
            newPassword: {
                required: 'Mật khẩu mới là bắt buộc',
                min: 'Mật khẩu phải có ít nhất 8 ký tự',
                notDifferent: 'Mật khẩu mới và mật khẩu hiện tại phải khác nhau.',
            },
            passwordConfirmation: {
                required: 'Xác nhận mật khẩu là bắt buộc',
                match: 'Xác nhận mật khẩu không khớp',
            },
        },
        success: 'Mật khẩu đã được thay đổi!',
    },

    connectedAccounts: {
        title: 'Tài khoản được liên kết',
        content: 'Liên kết tài khoản Viblo với các tài khoản mạng xã hội của bạn. Những tài khoản này có thể sử dụng để đăng nhập vào Viblo bằng 1 cú nhấp chuột.',
        table: {
            provider: 'Nhà cung cấp',
            account: 'Tài khoản',
            actions: 'Hành động',
            disconnect: 'Ngắt kết nối',
            connect: 'Kết nối',
            success: 'tài khoản bị ngắt kết nối',
            error: 'Đã xảy ra lỗi. Chúng tôi không thể ngắt kết nối tài khoản của bạn',
        },
        noAccount: 'Không có tài khoản nào được kết nối',
    },

    oAuthApplications: {
        title: 'OAuth Applications',
        newApp: 'Tạo mới OAuth Applications',
        content: 'Quản lý các OAuth Applications của bạn được sử dụng để truy cập API Viblo.',
        table: {
            displayName: 'Tên hiển thị',
            appID: 'Mã ứng dụng',
            createdAt: 'Ngày tạo',
            actions: 'Hành động',
            deleteApp: 'Xóa app',
        },
        deleteApp: {
            content: 'Xóa OAuth Applications sẽ làm mất hiệu lực của bất kỳ mã bảo mật truy cập nào được người dùng ủy quyền.',
            title: 'Xóa ứng dụng?',
            success: 'Đã xóa OAuth Applications thành công!',
        },
        formNewApp: {
            title: 'Tạo mới OAuth Applications',
            content: 'Quản lý các OAuth Applications của bạn được sử dụng để truy cập API Viblo.',
            appSecret: 'Ứng dụng bí mật',
            homepage: 'URL trang chủ',
            description: 'Mô tả ứng dụng',
            redirect: 'URL gọi lại ủy quyền',
            registerApplication: 'Đăng ký ứng dụng',
            success: 'Đã tạo thành công!',
            manageApp: 'Quản lý thông tin OAuth Applications của bạn.',
            saveChanges: 'Lưu thay đổi',
            reset: 'Đặt lại',
            validations: {
                name: 'Tên ứng dụng không được để trống',
                homepage: 'URL trang chủ là bắt buộc',
                description: 'Mô tả không được dài hơn 300 ký tự',
                redirect: 'URL gọi lại ủy quyền là bắt buộc',
            },
        },
    },

    personalTokens: {
        title: 'Mã bảo mật truy cập cá nhân',
        content: 'Mã bảo mật truy cập cá nhân là khóa API cho tiện ích mở rộng trình duyệt Viblo, plugin Viblo Atom. Những mã bảo mật này có thể được sử dụng để đăng nhập vào tài khoản của bạn.',
        generateToken: 'Tạo mã bảo mật mới',
        tokenList: {
            tokenName: 'Tên mã bảo mật',
            expiresAt: 'Hết hạn lúc',
            actions: 'Hành động',
            revokeToken: 'Thu hồi mã bảo mật',
        },
        methodRevoke: {
            title: 'Bạn có chắc không?',
            content: 'Bất kỳ ứng dụng nào đang sử dụng mã bảo mật này sẽ không thể truy cập vào tài khoản của bạn được nữa. Bạn có chắc chắn muốn xóa mã bảo mật này không?',
            error: 'Không thể xóa mã bảo mật này.',
        },
        createToken: {
            title: 'Mã bảo mật truy cập cá nhân mới',
            content: 'Mã bảo mật truy cập cá nhân là khóa API cho Tiện ích mở rộng trình duyệt Viblo, plugin Viblo Atom. Những mã bảo mật này có thể được sử dụng để đăng nhập vào tài khoản của bạn.',
            generateToken: 'Tạo mã bảo mật',
            titleCreateSuccess: 'Mã bảo mật được tạo thành công',
            description: 'Đảm bảo sao chép mã bảo mật truy cập cá nhân mới của bạn ngay bây giờ. Bạn sẽ không thể nhìn thấy nó lần nữa!',
            copyToken: 'Sao chép mã bảo mật truy cập',
            requiredName: 'Tên mã bảo mật là bắt buộc.',
        },
        beforeRouterLeave: {
            title: 'Bạn có chắc không?',
            content: 'Đảm bảo sao chép mã bảo mật truy cập cá nhân mới của bạn ngay bây giờ. Bạn sẽ không thể nhìn thấy nó lần nữa!',
        },
        copiedClipboard: 'Sao chép vào bộ nhớ đệm',
    },

    authorizedOAuthApps: {
        title: 'OAuth Applications được ủy quyền',
        firstContent: 'Bạn đã được cấp',
        lastContent: 'ứng dụng truy cập vào tài khoản của bạn.',
        empty: 'Không có gì ở đây cả.',
        time: 'Lần sử dụng cuối cùng',
        revokeToken: 'Thu hồi mã bảo mật',
        methodRevoke: {
            title: 'Bạn chắc chứ?',
            content: 'Bất kỳ ứng dụng nào đang sử dụng mã bảo mật này sẽ không thể truy cập vào tài khoản của bạn được nữa. Bạn có chắc chắn muốn xóa mã bảo mật này không?',
            error: 'Không thể xóa mã bảo mật này.',
        },
    },

    login: {
        loginTo: 'Đăng nhập vào',
        unverifiedEmail: 'Gửi lại email xác minh',
        username: 'Tên người dùng hoặc email',
        password: 'Mật khẩu',
        login: 'Đăng nhập',
        forgotPassword: 'Quên mật khẩu?',
        createAccount: 'Tạo tài khoản',
        orLoginWith: 'Đăng nhập bằng',
        requiredName: 'Tên người dùng/email là bắt buộc',
        requiredPassword: 'Mật khẩu là bắt buộc',
    },

    register: {
        title: 'Đăng ký tài khoản cho',
        content1: 'Chào mừng bạn đến',
        content2: 'Nền tảng Viblo',
        content3: 'Tham gia cùng chúng tôi để tìm kiếm thông tin hữu ích cần thiết để cải thiện kỹ năng IT của bạn. Vui lòng điền thông tin của bạn vào biểu mẫu bên dưới để tiếp tục.',
        mustVerifyEmail: {
            welcome: 'Chào mừng',
            content1: 'tài khoản của bạn đã được',
            content2: 'đăng ký thành công',
            content3: 'Chúng tôi đã gửi cho bạn một email kích hoạt tại địa chỉ email',
            content4: 'Vui lòng kiểm tra hộp thư đến của bạn để hoàn thành.',
            noReceive: 'Nếu bạn không nhận được email kích hoạt từ chúng tôi, vui lòng ấn',
            resend: 'gửi lại',
            activationEmail: ' email kích hoạt.',
            yourAccount: ', tài khoản của bạn đã được ',
            registeredSuccessfully: 'đăng ký thành công',
            accountActivated: 'Tài khoản của bạn đã được kích hoạt. Hãy',
            login: 'đăng nhập',
            explore: 'để khám phá Viblo.',
        },
        form: {
            yourName: 'Tên của bạn',
            emailMatched: 'Địa chỉ E-Mail phải khớp với tài khoản xã hội của bạn.',
            emailAddress: 'Địa chỉ email của bạn',
            username: 'Tên tài khoản',
            password: 'Mật khẩu',
            confirmPassword: 'Xác nhận mật khẩu của bạn',
            agree: 'Tôi đồng ý',
            termsOfService: 'Điều khoản dịch vụ của Viblo',
            signUp: 'Đăng ký',
            orLoginWith: 'Đăng nhập với',
            validations: {
                name: {
                    required: 'Tên là bắt buộc',
                    max: 'Phải ít hơn 50 ký tự',
                },
                email: {
                    required: 'Email là bắt buộc',
                    type: 'Phải là một email hợp lệ',
                    max: 'Email không được dài hơn 255 ký tự',
                },
                username: {
                    required: 'Tên tài khoản là bắt buộc',
                    max: 'Phải ít hơn 20 ký tự',
                },
                password: {
                    required: 'Mật khẩu là bắt buộc',
                    min: 'Mật khẩu phải có ít nhất 8 ký tự',
                },
                passwordConfirmation: {
                    required: 'Nhập lại mật khẩu là bắt buộc',
                    confirmed: 'Mật khẩu không khớp',
                },
                terms: 'Vui lòng đồng ý với Điều khoản dịch vụ của chúng tôi',
            },
        },
    },

    forgotYourPassword: {
        title: 'Quên mật khẩu',
        submitLabel: 'Gửi email cho tôi',
        content: 'Bạn quên mật khẩu của mình? Đừng lo lắng! Hãy cung cấp cho chúng tôi email bạn sử dụng để đăng ký tài khoản Viblo. Chúng tôi sẽ gửi cho bạn một liên kết để đặt lại mật khẩu của bạn qua email đó.',
        sendSuccess: {
            content1: 'Chúng tôi đã ',
            content2: 'gửi một email',
            content3: 'có lên kết để đặt lại mật khẩu của bạn.',
            content4: 'Có thể mất từ 1 đến 2 phút để hoàn thành. Hãy kiểm tra hộp thư đến của bạn',
        },
        RequestActivationForm: {
            content1: 'Chúng tôi ',
            content2: 'đã gửi một email',
            content3: 'với một liên kết xác nhận đến địa chỉ email của bạn. Có thể mất từ 1 đến 2 phút để hoàn thành.',
            content4: 'Hãy kiểm tra hộp thư đến của bạn',
            emailAddress: 'Địa chỉ email của bạn',
            required: 'Email là bắt buộc.',
            type: 'Vui lòng nhập địa chỉ email chính xác.',
        },
    },

    sendActivationEmail: {
        title: 'Gửi email kích hoạt tài khoản',
        label: 'Gửi email kích hoạt cho tôi ',
        content: 'Email kích hoạt tài khoản được gửi khi đăng ký tài khoản Viblo. Nếu bạn không nhận được nó, vui lòng cung cấp cho chúng tôi địa chỉ email của bạn đã đăng ký Viblo. Chúng tôi sẽ gửi lại cho bạn email kích hoạt tài khoản.',
    },

    authorize: {
        title: 'Ủy quyền',
        appAccess: 'Ứng dụng "{name}" muốn truy cập vào tài khoản của bạn',
        appWillBe: 'Ứng dụng này sẽ có thể:',
        profile: 'Đọc tất cả các dữ liệu cá nhân của bạn như: email, thông tin cá nhân',
        openid: 'Phát hành id_token',
        email: 'Truy cập địa chỉ email của bạn (chỉ đọc)',
        content: 'Bằng cách nhấp vào Cho phép, bạn sẽ cho phép ứng dụng này sử dụng thông tin của bạn theo các điều khoản dịch vụ và chính sách bảo mật của họ. Bạn có thể thay đổi/xóa các quyền này bất kỳ lúc nào trong cài đặt tài khoản của bạn.',
        deny: 'Từ chối',
        allow: 'Cho phép',
    },

    learnMore: {
        title: 'Tìm hiểu thêm',
        content: 'về cách sử dụng App OAuth.',
    },

    tutorialOAuthApp: {
        titlePage: 'Sử dụng App OAuth để truy cập các thông tin của bạn',
        step1: {
            title: '1. Tạo 1 Oauth App',
            goToPage: 'Truy cập vào',
            clickButton: 'bấm vào nút tạo mới App OAuth',
            infomationApp: ' OAuth App sau khi được tạo sẽ có đầy đủ các thông tin',
            appId: 'Mã ứng dụng',
            appSecret: 'Ứng dụng bí mật',
            displayName: 'Tên hiển thị',
            homepageURL: 'URL trang chủ',
            applicationDescription: 'Mô tả ứng dụng',
            redirect: 'URL ủy quyền gọi lại',
        },
        step2: {
            title: '2. Lấy mã ủy quyền',
            gotoPage: 'Ứng dụng của bạn cần có một nút chuyển hướng đến đường dẫn',
            parameters: 'có các parameter như sau, trong đó:',
            parameter1: 'grant_type: authorization_code',
            parameter2: 'client_id: App ID mà bạn vừa tạo ở trên',
            parameter3: 'scope: Openid, profile, email',
            parameter4: 'response_type: code',
            description: 'Sau đó người dùng được hỏi liệu họ có sẵn sàng cấp quyền mà ứng dụng của bạn đang yêu cầu hay không. Quá trình này được gọi là người sử dụng đồng ý. Nếu người dùng chọn "Cho phép" thì Viblo Accounts sẽ gửi cho cho ứng dụng của bạn vừa đăng kí ở trên theo "Authorization call back URL" một Authorization Code. Nếu người dùng "từ chối" Viblo Accounts sẽ quay trở lại trang chủ.',
        },
        step3: {
            title: '3. Lấy mã thông báo truy cập',
            description: 'Mã truy cập là một chuỗi mờ xác định người dùng, ứng dụng hoặc Trang. Ứng dụng có thể dùng mã này để thực hiện lệnh gọi API. Khi ai đó kết nối với một ứng dụng thông qua phương thức Đăng nhập của Viblo, ứng dụng đó sẽ lấy mã truy cập để cấp quyền truy cập tạm thời, an toàn vào API của Viblo Accounts bằng cách convert authorization code về  access_token từ Viblo Accounts. Với các parameter như sau:',
            parameter1: 'client_id: App ID mà bạn vừa tạo ở trên',
            parameter2: 'client_secret: App secret mà bạn vừa tạo ở trên',
            parameter3: 'code: Authorization Code vừa nhận được ở bước 2',
            parameter4: 'grant_type: authorization_code',
            parameter5: 'scope: Openid, profile, email',
        },
        step4: {
            title: '4. Gửi mã thông báo truy cập',
            description: 'Sau khi ứng dụng có được một access token ở bước trên, sẽ thực hiện gửi đến api của Viblo Accounts trong một request HTTP header Authorization.',
            result: 'Kết quả cuối cùng nhận được là thông tin của người dùng.',
        },
    },
};

export default vi;

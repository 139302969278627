// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _assign from 'lodash/assign';

export const withMiddleware = (middleware, route) => {
    if (_isEmpty(route.children)) {
        return _assign({}, route, { meta: { ...route.meta, middleware } });
    }

    const children = _map(route.children, child => withMiddleware(middleware, child));

    return _assign({}, route, { children });
};

export default {
    withMiddleware,
};

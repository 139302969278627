<script>
    import fromUnixTime from 'date-fns/fromUnixTime';
    import { format } from 'date-fns';

    export default {

        functional: true,
        props: {
            time: [String, Number],
            format: {
                type: String,
                default: 'MM/dd/yyyy HH:mm',
            },
        },

        render: (h, { props }) => (props.time
            ? <span><i class='el-icon-time'></i> { format(fromUnixTime(props.time), props.format) }</span>
            : null),
    };
</script>

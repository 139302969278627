// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <ElCard>
            <header>
                <h1 class="card-title d-flex align-items-center justify-content-between">
                    <span>{{ $t('authorizedOAuthApps.title') }}</span>
                </h1>

                <p class="card-subtitle">
                    {{ $t('authorizedOAuthApps.firstContent') }}
                    {{ apps.length }}
                    {{ $t('authorizedOAuthApps.lastContent') }}
                </p>
            </header>

            <section class="mt-4">
                <ApplicationsList :apps.sync="apps" @delete="deleteToken" />
            </section>
        </ElCard>
    </BContainer>
</template>
<script>
    import _filter from 'lodash/filter';
    import { getToken } from '~/api/security/tokens';
    import ApplicationsList from '~/components/security/applications/List.vue';

    export default {
        components: {
            ApplicationsList,
        },

        data() {
            return {
                apps: [],
            };
        },

        computed: {

        },
        created() {
            getToken()
                .then(({ data }) => { this.apps = data; })
                .catch(() => {});
        },
        methods: {
            deleteToken(token) {
                this.apps = _filter(this.apps, item => item.id !== token.id);
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
const initialState = () => ({
    profilePictureVisible: false,
});

const mutations = {
    toggleProfilePictureDialog(state, value = false) {
        state.profilePictureVisible = value;
    },
};


export default {
    state: initialState,
    mutations,
    namespaced: true,
};

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <div :class="{ 'container-fluid': fluid }" class="container">
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            fluid: Boolean,
        },
    };
</script>

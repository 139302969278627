// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <div class="app-item">
        <div class="app-item__info">
            <div class="el-badge">
                <span class="app-item__name">
                    <a :href="app.client.homepage" rel="noopener" target="_blank">
                        {{ app.client.name }}
                    </a>
                </span>
            </div>

            <div class="app-item__description">
                {{ $t('authorizedOAuthApps.time') }} {{ app.updated_at | humanizeTime }}
            </div>
        </div>

        <el-button
            icon="el-icon-delete"
            size="small"
            type="danger"
            plain
            @click.prevent="revoke(app)"
        >
            {{ this.$t('authorizedOAuthApps.revokeToken') }}
        </el-button>
    </div>
</template>

<script>
    import { revokeToken } from '~/api/security/tokens';

    export default {
        props: {
            app: Object,
        },

        methods: {
            revoke(token) {
                this.$confirm(this.$t('authorizedOAuthApps.methodRevoke.content'), this.$t('authorizedOAuthApps.methodRevoke.title'))
                    .then(async () => {
                        await revokeToken(token.id);
                        this.$emit('delete', token);
                    })
                    .catch((e) => {
                        if (e.response) {
                            this.$message.error(this.$t('authorizedOAuthApps.methodRevoke.error'));
                        }
                    });
            },
        },
    };
</script>

<style lang="scss">
    .app-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__info {
            min-width: 0;
            padding-right: 1rem;
        }

        &__name {
            font-weight: bold;
        }

        &__description {
            display: flex;
        }
    }
</style>

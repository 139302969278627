// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import _isEmpty from 'lodash/isEmpty';

export function accepted(rule, value, callback) {
    if (value === true) {
        callback();
    } else {
        callback(new Error(rule.message));
    }
}

export function confirmed(model, prop) {
    return (rule, value, callback) => {
        if (value === model[prop]) {
            callback();
        } else {
            callback(new Error(rule.message));
        }
    };
}

export function noDifferent(model, prop) {
    return (rule, value, callback) => {
        if (value !== model[prop]) {
            callback();
        } else {
            callback(new Error(rule.message));
        }
    };
}

export function requiredIf({ condition, message }, value, callback) {
    if (condition() && _isEmpty(value)) {
        callback(new Error(message));
    } else {
        callback();
    }
}

<template>
    <div>
        <h1 class="card-title">
            Unban {{ username }}
        </h1>

        <p class="card-subtitle mb-3">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-position="top"
                size="mini"
            >
                <el-form-item>
                    <el-checkbox v-model="form.is_unbanned_all">
                        Unban all services
                    </el-checkbox>
                </el-form-item>

                <el-form-item v-if="!form.is_unbanned_all" prop="services">
                    <el-select
                        v-model="form.services"
                        style="width: 100%"
                        multiple
                    >
                        <el-option
                            v-for="(service, code) in banServices"
                            :key="code"
                            :label="service.name"
                            :value="code"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
        </p>

        <div class="d-flex justify-content-end">
            <el-button
                type="info"
                size="mini"
                :disabled="processing"
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                type="success"
                size="mini"
                :disabled="processing"
                @click="confirm"
            >
                Confirm
            </el-button>
        </div>
    </div>
</template>

<script>
    import _pick from 'lodash/pick';
    import processRequest from '~/mixins/processRequest';
    import { services } from '~/utils/sso';
    import { unbanService } from '~/api/admin/users';

    const rules = {
        services: { type: 'array' },
    };

    export default {

        mixins: [processRequest],
        data() {
            return {
                processing: false,
                username: this.$route.query.username,
                form: {
                    is_unbanned_all: true,
                    services: [],
                },
                rules,
                banLog: {},
            };
        },

        computed: {
            banServices() {
                return this.banLog.is_banned_all ? services : _pick(services, this.banLog.services);
            },
        },

        mounted() {
            this.$store.dispatch('adminUser/getBanLog', this.username).then((res) => {
                this.banLog = res;
            });
        },

        methods: {
            confirm() {
                this.processing = true;
                this.processRequest(unbanService(this.username, this.form))
                    .then((res) => {
                        this.$message.success('User updated!');
                        window.opener.postMessage({
                            user: res.data, success: true, message: 'User updated!', action: 'unbanUser',
                        }, '*');
                    }).catch(() => {
                        this.$message.error('Sorry. Something went wrong!');
                        window.opener.postMessage({ success: false, message: 'Sorry. Something went wrong!' }, '*');
                    }).finally(() => {
                        this.processing = false;
                        window.close();
                    });
            },

            cancel() {
                window.close();
            },
        },

    };
</script>

<template>
    <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link uppercase flex items-center font-bold text-sm">
            <img src="@/assets/icon-world.png" alt="icon language" class="image-world">
            <span class="leading-none block ml-1">{{ $i18n.locale }}</span>
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="lang in langs"
                :key="lang.key"
                :command="lang.key"
            >
                <a class="dropdown-item px-4">
                    {{ lang.lable }}
                </a>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    export default {
        name: 'SwitchLocale',
        data() {
            return {
                langs: [
                    {
                        key: 'vi',
                        lable: 'Tiếng việt',
                    },
                    {
                        key: 'en',
                        lable: 'English',
                    },
                ],
            };
        },

        methods: {
            handleCommand(value) {
                this.$i18n.locale = value;
            },
        },
    };
</script>

<style>
    .el-dropdown-link {
        padding: 3px 6px;
        border-radius: 5px;
        background-color: #babcbf;
        color: #001b45;
        text-transform: uppercase;
        font-weight: 700;
        font-size: .875rem;
    }
    .image-world {
        height: 1rem;
    }
</style>

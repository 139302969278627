<template>
    <el-table :data="users" style="width: 100%" @filter-change="filter">
        <el-table-column label="ID" prop="id" width="80" />

        <el-table-column
            prop="name"
            :formatter="renderName"
            :show-overflow-tooltip="true"
            :label="`Name (${totalNumberFormatted})`"
            min-width="150"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Username"
            prop="username"
            width="150"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Email"
            prop="email"
            width="250"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Phone"
            prop="phone"
            width="150"
        />

        <el-table-column :formatter="renderTime('birthday', 'dd/MM/yyyy')" label="Birthday" width="150" />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Gender"
            prop="gender"
            width="100"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="University"
            prop="university"
            width="150"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Address"
            prop="address"
        />

        <el-table-column :formatter="renderTime('joined_at', 'dd/MM/yyyy HH:mm')" label="Updated at" width="180" />
    </el-table>
</template>

<script>
    import table from '../mixins/table';
    import NameCell from '~/components/admin/table/NameCell.vue';

    export default {
        mixins: [table],

        props: {
            users: Array,
            total: {
                type: Number,
                default: 0,
            },
        },

        computed: {
            totalNumberFormatted() {
                return this.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
        },

        methods: {
            renderName(user) {
                return <NameCell user={user} />;
            },
        },
    };
</script>

<template>
    <BContainer class="mt-4">
        <BRow>
            <BCol span="12" class="d-flex justify-content-center py-4 mb-2">
                <Logo size="medium" />
            </BCol>
        </BRow>

        <BRow>
            <BCol
                sm="12"
                md="10"
                lg="8"
                class="mx-sm-auto"
            >
                <ElCard>
                    <RouterView />
                </ElCard>
            </BCol>
        </BRow>
    </BContainer>
</template>

<script>
    import Logo from '~/components/site/Logo.vue';

    export default {
        components: {
            Logo,
        },

        data() {
            return {
                user: {},
            };
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import Vue from 'vue';

import Row from '~/components/grid/Row.vue';
import Col from '~/components/grid/Col.vue';
import Container from '~/components/grid/Container.vue';

function install() {
    Vue.component('BRow', Row);
    Vue.component('BCol', Col);
    Vue.component('BContainer', Container);
}

export default { install };

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import _get from 'lodash/get';

const initialState = () => window.GLOBALS.config;

const getters = {
    get: key => state => _get(state, key),
};

export default {
    state: initialState,
    getters,
    namespaced: true,
};

<template>
    <div class="social-login">
        <ul class="list-unstyled d-flex align-items-center justify-content-between flex-wrap mb-0">
            <ElButton
                icon="fab fa-facebook-f mr-1"
                size="medium"
                class="social-login__button facebook"
                @click="login('facebook')"
            >
                Facebook
            </ElButton>

            <ElButton
                icon="fab fa-google mr-1"
                size="medium"
                class="social-login__button google"
                @click="login('google')"
            >
                Google
            </ElButton>

            <ElButton
                icon="fab fa-github mr-1"
                size="medium"
                class="social-login__button github"
                @click="login('github')"
            >
                Github
            </ElButton>
        </ul>
    </div>
</template>

<script>
    export default {
        methods: {
            login(provider) {
                window.location.href = `/auth/${provider}${window.location.search}`;
            },
        },
    };
</script>


<style lang="scss">
    @import "../../styles/bootstrap/mixins";

    .social-login__button {
        flex: 1;

        &.facebook .fab {
            color: #3b5998 !important;
        }

        &.google .fab {
            color: #E94820 !important;
        }

        &.github .fab {
            color: #333 !important;
        }
    }

    @include media-breakpoint-down(sm) {
        .social-login__button {
            flex-basis: 100%;
            margin: 0 0 .5rem 0 !important;
        }
    }
</style>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <div :class="classes" class="row">
        <slot />
    </div>
</template>

<script>
    import _map from 'lodash/map';

    const makeClasses = (className, values) => (
        typeof values === 'string'
            ? `${className}-${values}`
            : _map(
                values,
                (value, breakpoint) => `${className}-${breakpoint}-${value}`,
            )
    );

    export default {
        props: {
            justify: {
                type: [String, Object],
                default: null,
            },

            align: {
                type: [String, Object],
                default: null,
            },

            noGutters: Boolean,
        },

        computed: {
            classes() {
                const alignClasses = makeClasses('align-items', this.align);
                const justifyClasses = makeClasses('justify-content', this.justify);

                return [
                    alignClasses,
                    justifyClasses,
                    { 'no-gutters': this.noGutters },
                ];
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <div v-if="authenticated" class="viblo-layout is-default">
        <Header />

        <ElContainer class="viblo-container">
            <Sidebar />

            <ElMain class="viblo-main">
                <RouterView />
            </ElMain>
        </ElContainer>

        <ChangeAvatarDialog />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Header from '~/components/site/Header.vue';
    import Sidebar from '~/components/site/AdminSidebar.vue';
    import ChangeAvatarDialog from '~/components/dialogs/ChangeAvatar.vue';

    export default {
        components: {
            Header,
            Sidebar,
            ChangeAvatarDialog,
        },

        computed: {
            ...mapGetters('auth', ['authenticated']),
        },
    };
</script>

<style lang="css">
    .viblo-container {
        min-height: calc(100vh - 60px - 1rem);
    }
</style>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <div class="login-form">
        <ElAlert
            v-if="errorMsg"
            :title="errorMsg"
            :closable="false"
            type="error"
            class="mb-3"
        >
            <template v-if="errorCode === 'unverified_email'">
                <RouterLink to="/send-activation">
                    {{ $t('login.unverifiedEmail') }}
                </RouterLink>
            </template>
        </ElAlert>

        <ElForm
            ref="form"
            :model="form"
            :rules="rules"
            size="medium"
        >
            <ElFormItem prop="username">
                <ElInput
                    v-model="form.username"
                    autofocus
                    :placeholder="$t('login.username')"
                    @keydown.native.enter="login"
                    @change="text => form.username = text.trim()"
                >
                    <template slot="prepend">
                        <i class="fa fa-user" aria-hidden="true" />
                    </template>
                </ElInput>
            </ElFormItem>
            <ElFormItem prop="password">
                <ElInput
                    v-model="form.password"
                    type="password"
                    :placeholder="$t('login.password')"
                    @keydown.native.enter="login"
                    @change="text => form.password = text.trim()"
                >
                    <template slot="prepend">
                        <i class="fa fa-lock" aria-hidden="true" />
                    </template>
                </ElInput>
            </ElFormItem>
        </ElForm>

        <ElButton
            :loading="processing"
            type="primary"
            class="w-100"
            @click="submit($refs.form, login)"
        >
            {{ $t('login.login') }}
        </ElButton>

        <div class="d-flex justify-content-between my-2">
            <RouterLink to="/forgot-password">
                <small>{{ $t('login.forgotPassword') }}</small>
            </RouterLink>

            <RouterLink to="/register">
                <small>{{ $t('login.createAccount') }}</small>
            </RouterLink>
        </div>

        <div class="d-flex align-items-center justify-content-between my-4">
            <hr class="flex-fill m-0">
            <span class="mx-3">
                {{ $t('login.orLoginWith') }}
            </span>
            <hr class="flex-fill m-0">
        </div>

        <SocialLogin />
    </div>
</template>

<script>
    import { login } from '~/api/auth';
    import formMixin from '~/mixins/form';
    import SocialLogin from './SocialLogin.vue';

    export default {
        components: {
            SocialLogin,
        },

        mixins: [formMixin],

        data() {
            const errorMsg = this.$route.query.error_message;
            const errorCode = this.$route.query.error_code;
            return {
                errorMsg,
                errorCode,

                rules: {
                    username: [{ required: true, message: this.$t('login.requiredName'), trigger: [] }],
                    password: [{ required: true, message: this.$t('login.requiredPassword'), trigger: [] }],
                },

                form: {
                    username: '',
                    password: '',
                },
            };
        },

        methods: {
            escapeJavascript(str) {
                const continueSplit = str.split(':');

                if (continueSplit[0] === 'javascript') {
                    return encodeURIComponent(str);
                }

                return str;
            },

            async login() {
                this.processing = true;

                try {
                    await login(this.form);

                    if (this.$route.query.service) {
                        window.location.href = `/sso/auth${window.location.search}`;
                    } else {
                        const continueUrl = this.$route.query.continue || '/';
                        window.location.href = this.escapeJavascript(continueUrl);
                    }
                } catch (error) {
                    this.processing = false;

                    if (error.response) {
                        this.errorCode = error.response.data.error;
                        this.errorMsg = error.response.data.message;
                    } else {
                        throw error;
                    }
                }
            },
        },
    };
</script>

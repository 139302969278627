// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <ElAside class="viblo-aside position-relative" width="200">
        <ElMenu
            :default-openeds="['10', '20', '30']"
            :default-active="$route.path"
            :collapse="collapse"
            :collapse-transition="false"
            class="viblo-aside__menu"
            router
        >
            <ElMenuItem index="/">
                <i class="fa fa-home" />
                <span>{{ $t('menuAside.home') }}</span>
            </ElMenuItem>

            <ElSubMenu index="10">
                <template slot="title">
                    <i class="fa fa-id-card" />
                    <span>{{ $t('menuAside.myProfile') }}</span>
                </template>
                <ElMenuItem index="/profile/personal">
                    <i class="fa fa-user" />
                    <span>{{ $t('menuAside.personalInfo') }}</span>
                </ElMenuItem>
                <ElMenuItem index="/profile/contact">
                    <i class="fa fa-address-card" />
                    <span>{{ $t('menuAside.contactInfo') }}</span>
                </ElMenuItem>
                <ElMenuItem index="/profile/emails">
                    <i class="fa fa-envelope" />
                    <span>Emails</span>
                </ElMenuItem>
            </ElSubMenu>

            <ElSubMenu index="20">
                <template slot="title">
                    <i class="fa fa-shield-alt" />
                    <span>{{ $t('menuAside.security') }}</span>
                </template>

                <ElMenuItem index="/security/password">
                    <i class="fa fa-key" />
                    <span>{{ $t('menuAside.password') }}</span>
                </ElMenuItem>

                <ElMenuItem index="/security/connected-accounts">
                    <i class="fa fa-object-group" />
                    <span>{{ $t('menuAside.connectedAccounts') }}</span>
                </ElMenuItem>
            </ElSubMenu>

            <ElSubMenu index="30">
                <template slot="title">
                    <i class="fa fa-cube" />
                    <span>{{ $t('menuAside.developerSettings') }}</span>
                </template>


                <ElMenuItem index="/security/apps">
                    <i class="fa fa-cubes" />
                    <span>{{ $t('menuAside.oAuthApplications') }}</span>
                </ElMenuItem>

                <ElMenuItem index="/security/tokens">
                    <i class="fa fa-user-secret" />
                    <span>{{ $t('menuAside.personalAccessTokens') }}</span>
                </ElMenuItem>

                <ElMenuItem index="/security/applications">
                    <i class="fa fa-certificate" />
                    <span>{{ $t('menuAside.authorizedOAuthApps') }}</span>
                </ElMenuItem>
            </ElSubMenu>

            <ElMenuItem class="btn-collapse" @click.native="toggleSidebar">
                <i :class="{ [`fa-toggle--collapsed`]: collapse }" class="fa fa-toggle" />
                <span>{{ $t('menuAside.collapseSidebar') }}</span>
            </ElMenuItem>
        </ElMenu>
    </ElAside>
</template>

<script>
    export default {
        data: () => ({
            collapse: Boolean(window.localStorage.getItem('sidebar-collapse')),
        }),

        created() {
            window.addEventListener('resize', this.handlerCloseSidebar);
        },

        destroyed() {
            window.removeEventListener('resize', this.handlerCloseSidebar);
        },

        methods: {
            toggleSidebar() {
                const value = !this.collapse;

                this.collapse = value;
                window.localStorage.setItem('sidebar-collapse', value ? true : '');
            },

            handlerCloseSidebar() {
                if (window.innerWidth < 600) {
                    localStorage.setItem('sidebar-collapse', true);
                    this.collapse = Boolean(localStorage.getItem('sidebar-collapse'));
                }
            },
        },
    };
</script>

<style lang="scss">
    .viblo-aside {
        position: relative;
        top: 1rem;
        &__menu {
            height: 100%;
            transition: width 0.3s ease-in-out;
        }
        .fa {
            font-size: 1.125rem;
            margin-right: 0.5rem;
            width: 20px;
        }
        .btn-collapse {
            border-top: solid 1px #ecf5ff;
            width: 100%;
            .fa-toggle:before {
                content: '\f053\f053';
                font-size: 0.875rem;
                line-height: 1.5rem;
            }
            .fa-toggle--collapsed:before {
                content: '\f054\f054';
            }
        }
    }
</style>

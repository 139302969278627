// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import axios from '~/libs/axios';

export const getPersonalAccessTokens = () => axios.get('/oauth/personal-access-tokens');
export const generateToken = data => axios.post('/oauth/personal-access-tokens', data);
export const revokeToken = (tokenId, data) => axios.delete(`/oauth/personal-access-tokens/${tokenId}`, { data });
export const revokeAll = data => axios.delete('/oauth/personal-access-tokens', { data });

export const getOAuthApps = () => axios.get('/oauth/clients');
export const fetchApp = id => axios.get(`/oauth/clients/${id}`);
export const createApp = data => axios.post('/oauth/clients', data);
export const updateApp = (id, data) => axios.put(`/oauth/clients/${id}`, data);
export const deleteApp = id => axios.delete(`/oauth/clients/${id}`);

export const getAuthorize = params => axios.get('/oauth/authorize', { params });
export const authorizeApp = data => axios.post('/oauth/authorize', data);
export const getToken = () => axios.get('/oauth/tokens');

<template>
    <el-table :data="users" style="width: 100%" @filter-change="filter">
        <el-table-column label="ID" prop="id" width="80" />

        <el-table-column v-if="$route.params.service === 'ctf'" type="expand" width="20">
            <template slot-scope="props">
                Members:
                <TableMember :members="props.row.members" />
            </template>
        </el-table-column>

        <el-table-column
            v-if="$route.params.service === 'ctf'"
            :show-overflow-tooltip="true"
            label="Team"
            prop="meta.team_name"
            width="100"
        />

        <el-table-column
            v-if="$route.params.service === 'ctf'"
            :show-overflow-tooltip="true"
            label="Status"
            prop="meta.team_lead"
            width="100"
            :filters="filterTeamStatus"
            :filtered-value="filteredTeamStatus"
            :filter-multiple="false"
            column-key="status"
        >
            <template slot-scope="scope">
                {{ scope.row.meta ? scope.row.meta.team_lead ? 'Leader' : 'Member' : '' }}
            </template>
        </el-table-column>

        <el-table-column
            prop="name"
            :formatter="renderName"
            :show-overflow-tooltip="true"
            :label="`Name (${totalNumberFormatted})`"
            min-width="150"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Username"
            prop="username"
            width="150"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Email"
            prop="email"
            width="250"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Phone"
            prop="phone"
            width="150"
        />

        <el-table-column :formatter="renderTime('birthday', 'dd/MM/yyyy')" label="Birthday" width="150" />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Gender"
            prop="gender"
            width="100"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="University"
            prop="university"
            width="150"
        />

        <el-table-column
            :show-overflow-tooltip="true"
            label="Address"
            prop="address"
        />

        <el-table-column :formatter="renderTime('joined_at', 'dd/MM/yyyy HH:mm')" label="Requested at" width="180" />
    </el-table>
</template>

<script>
    import _assign from 'lodash/assign';
    import table from '../mixins/table';
    import NameCell from '~/components/admin/table/NameCell.vue';
    import TableMember from './TableMember.vue';

    const filterTeamStatus = [
        {
            text: 'Leader',
            value: '1',
        },
        {
            text: 'Member',
            value: '0',
        },
    ];

    export default {
        components: {
            TableMember,
        },

        mixins: [table],

        props: {
            users: Array,
            total: {
                type: Number,
                default: 0,
            },
        },

        data() {
            return {
                filterTeamStatus,
            };
        },

        computed: {
            filteredTeamStatus() {
                if (this.$route.query.team_status) {
                    return Array.isArray(this.$route.query.team_status)
                        ? this.$route.query.team_status : [this.$route.query.team_status];
                }

                return null;
            },

            totalNumberFormatted() {
                return this.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
        },

        methods: {
            renderName(user) {
                return <NameCell user={user} />;
            },

            filter(filters) {
                const query = _assign({}, this.$route.query, { team_status: filters.status[0], page: 1 });
                this.$router.push({ query });
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <PersonalInfo :profile="user" class="py-4" />
</template>

<script>
    import { mapState } from 'vuex';
    import PersonalInfo from '~/components/forms/profile/PersonalInfo.vue';

    export default {
        components: {
            PersonalInfo,
        },

        computed: {
            ...mapState('auth', ['user']),
        },
    };
</script>

<template>
    <el-dialog
        :title="title"
        class="edit-account-form"
        width="30%"
        :visible.sync="show"
        :before-close="close"
    >
        <el-form
            ref="form"
            :model="profile"
            :rules="rules"
            label-position="top"
            size="mini"
        >
            <el-form-item prop="name" label="Display Name">
                <el-input v-model="profile.name" />
            </el-form-item>
            <el-form-item prop="username" label="Username">
                <el-input v-model="profile.username" />
            </el-form-item>
            <el-form-item prop="email" label="Email">
                <el-input v-model="profile.email" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">
                Cancel
            </el-button>
            <el-button type="primary" @click="submit">
                Submit
            </el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: 'Edit Account',
            },
            profile: {
                type: Object,
            },
        },

        data() {
            const rules = {
                name: [
                    { required: true, message: this.$t('register.form.validations.name.required') },
                    { max: 50, message: this.$t('register.form.validations.name.max') },
                ],
                email: [
                    { required: true, message: this.$t('register.form.validations.email.required') },
                    { type: 'email', message: this.$t('register.form.validations.email.type') },
                    { max: 255, message: this.$t('register.form.validations.email.max') },
                ],
                username: [
                    { required: true, message: this.$t('register.form.validations.username.required') },
                    { max: 20, message: this.$t('register.form.validations.username.max') },
                ],
            };

            return {
                show: false,
                rules,
            };
        },

        methods: {
            submit() {
                this.$refs.form.validate(() => {
                    this.$emit('save', this.profile);
                });
            },

            open() {
                this.show = true;
            },

            close() {
                this.show = false;
            },
        },
    };
</script>

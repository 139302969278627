<template>
    <div>
        <ban-form ref="ban" @save="ban" />
        <edit-account-form
            v-if="currentUser"
            ref="editAccount"
            :profile="currentUser"
            @save="editAccount"
        />
        <el-table :data="users" style="width: 100%" @filter-change="filterSuner">
            <el-table-column label="ID" prop="id" width="80" />

            <el-table-column
                prop="name"
                :formatter="renderName"
                :show-overflow-tooltip="true"
                :label="`Name (${totalNumberFormatted})`"
                :filters="filterStatusSuner"
                :filtered-value="filteredSunner"
                :filter-multiple="false"
                column-key="sunner"
                min-width="150"
            />

            <el-table-column
                :formatter="renderServices"
                label="Services"
                prop="Services"
                width="250"
            />
            <el-table-column
                :show-overflow-tooltip="true"
                label="Username"
                prop="username"
                min-width="150"
            />

            <el-table-column
                :show-overflow-tooltip="true"
                label="Email"
                prop="email"
                width="250"
            />

            <el-table-column :formatter="renderTime('created_at')" label="Created" width="180" />

            <el-table-column
                :show-overflow-tooltip="true"
                label="Verified"
                prop="is_verified"
                width="180"
            >
                <template slot-scope="scope">
                    <el-switch
                        :value="scope.row.is_verified"
                        :disabled="processing"
                        @change="switchVerified(scope.row)"
                    />
                </template>
            </el-table-column>

            <el-table-column
                :formatter="renderActions(tableActions)"
                label="Actions"
                fixed="right"
                width="120"
            />
        </el-table>
    </div>
</template>

<script>
    import _assign from 'lodash/assign';
    import _cloneDeep from 'lodash/cloneDeep';
    import _pick from 'lodash/pick';
    import BanForm from './BanUserForm.vue';
    import EditAccountForm from './EditAccountForm.vue';
    import table from '../mixins/table';
    import processRequest from '~/mixins/processRequest';
    import NameCell from '~/components/admin/table/NameCell.vue';
    import ServicesCell from '~/components/admin/table/ServicesCell.vue';
    import { displayError } from '~/utils/pages';

    export default {
        components: {
            BanForm,
            EditAccountForm,
        },

        mixins: [table, processRequest],

        props: {
            users: Array,
            total: {
                type: Number,
                default: 0,
            },
        },

        data() {
            const filterStatusSuner = [
                {
                    text: 'Sunner',
                    value: 'sunner',
                },
                {
                    text: 'Not Sunner',
                    value: 'not_sunner',
                },
            ];
            const tableActions = [
                {
                    // eslint-disable-next-line global-require
                    img: require('~/assets/images/bear.png'),
                    action: this.toggleSunner,
                    class: _ => ({
                        'hover-sunner': _.is_sunner,
                        'hover-not-sunner': !_.is_sunner,
                    }),
                    width: 30,
                    style: _ => ({
                        marginRight: '5px',
                        cursor: 'pointer',
                        filter: _.is_sunner ? 'none' : 'grayscale(1)',
                    }),
                    tooltip: _ => (_.is_sunner ? 'Set not sunner' : 'Set sunner'),
                    if: _ => !_.banned_at,
                },
                {
                    icon: 'el-icon-edit-outline',
                    size: 'small',
                    type: 'primary',
                    action: this.openEditAccountForm,
                },
                {
                    icon: 'el-icon-remove',
                    size: 'small',
                    type: 'danger',
                    action: this.openBanForm,
                    if: _ => !_.banned_at,
                },
                {
                    icon: 'el-icon-remove',
                    size: 'small',
                    type: 'danger',
                    action: this.openEditBanForm,
                    if: _ => _.banned_at,
                },
                {
                    icon: 'el-icon-refresh',
                    size: 'small',
                    action: this.openUnbanConfirm,
                    if: _ => _.banned_at,
                },
            ];

            return {
                currentUser: {
                    id: 0,
                },
                processing: false,
                filterStatusSuner,
                tableActions,
            };
        },

        computed: {
            filteredSunner() {
                if (this.$route.query.internal_member) {
                    return Array.isArray(this.$route.query.internal_member)
                        ? this.$route.query.internal_member : [this.$route.query.internal_member];
                }

                return null;
            },

            totalNumberFormatted() {
                return this.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
        },

        methods: {
            renderName(user) {
                return <NameCell user={user} />;
            },

            renderServices(user) {
                const title = `Services (${user.services.length})`;
                return <ServicesCell title={title} services={user.services}/>;
            },

            openBanForm(user) {
                this.currentUser = user;
                this.$refs.ban.open();
            },

            openEditBanForm(user) {
                this.currentUser = user;
                this.$store.dispatch('adminUser/getBanLog', user.username)
                    .then((res) => {
                        this.$refs.ban.open(res);
                    });
            },

            openEditAccountForm(user) {
                this.currentUser = _cloneDeep(_pick(user, ['id', 'name', 'username', 'email']));
                if (this.$refs.editAccount) {
                    this.$refs.editAccount.open();
                }
            },

            editAccount(data) {
                this.processRequest(this.$store.dispatch('adminUser/updateAccount', {
                    userId: this.currentUser.id,
                    form: data,
                })).then(() => {
                    this.$message.success('User updated!');
                    this.$refs.editAccount.close();
                }).catch((e) => {
                    displayError(this.$message.error, e);
                });
            },

            openUnbanConfirm(user) {
                this.$confirm('Unban this user?', 'Unban User')
                    .then(() => this.unban(user));
            },

            ban(data) {
                this.processRequest(this.$store.dispatch('adminUser/ban', {
                    user: this.currentUser.username,
                    form: data,
                })).then(() => {
                    this.$message.success('User banned!');
                }).catch(() => {
                    this.$message.error('Sorry. Something went wrong!');
                });
            },

            unban(user) {
                this.currentUser = user;
                this.processRequest(this.$store.dispatch('adminUser/unban', user.username))
                    .then(() => {
                        this.$message.success('User unbanned!');
                    }).catch(() => {
                        this.$message.error('Sorry. Something went wrong!');
                    });
            },

            toggleSunner(user) {
                this.processRequest(this.$store.dispatch('adminUser/switchInternalMember', {
                    username: user.username,
                    value: !user.is_sunner,
                }))
                    .then(() => {
                        this.$message.success('User updated!');
                    }).catch(() => {
                        this.$message.error('Sorry. Something went wrong!');
                    });
            },

            switchVerified(user) {
                if (!this.processing) {
                    this.processing = true;
                    this.processRequest(this.$store.dispatch('adminUser/switchVerified', {
                        username: user.username,
                        value: !user.is_verified,
                    }))
                        .then(() => {
                            this.$message.success('User updated!');
                        }).catch(() => {
                            this.$message.error('Sorry. Something went wrong!');
                        }).finally(() => {
                            this.processing = false;
                        });
                }
            },

            filterSuner(filters) {
                const query = _assign({}, this.$route.query, { internal_member: filters.sunner[0], page: 1 });
                this.$store.dispatch('adminUser/getUsers', query);
                this.$router.push({ query });
            },
        },
    };
</script>

<style lang="scss">
    .hover-sunner {
        &:hover {
            filter: grayscale(1) !important
        }
    }
    .hover-not-sunner {
        &:hover {
            filter: none !important
        }
    }
</style>

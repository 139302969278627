<template>
    <el-dialog
        :title="title"
        :visible.sync="show"
        width="30%"
        @close="clear"
    >
        <el-form ref="form" :model="form">
            <el-form-item label="Role" :error="errors.role">
                <el-input v-model="form.role" placeholder="Role" />
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button type="primary" :loading="processing" @click="submit">
                Save
            </el-button>
            <el-button @click="close">
                Cancel
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import form from '../mixins/form';

    const emptyState = () => ({
        role: '',
    });

    export default {

        mixins: [form],
        props: {
            title: {
                type: String,
                default: '',
            },
        },

        data: () => ({
            form: emptyState(),
        }),

        methods: {
            clear(done) {
                this.form = emptyState();
                done();
            },
        },
    };
</script>

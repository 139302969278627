// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <ElCard shadow="never">
            <h1 class="card-title">
                {{ $t('contactInfo.title') }}
            </h1>
            <p class="card-subtitle">
                {{ $t('contactInfo.content') }}
            </p>

            <ElForm
                ref="form"
                :model="form"
                :rules="validations"
                label-position="top"
                size="medium"
                class="mt-4"
            >
                <ElAlert v-if="serverErrorMessage" type="error" class="mb-2">
                    {{ serverErrorMessage }}
                </ElAlert>

                <div class="d-flex flex-row flex-wrap">
                    <ElFormItem
                        :error="serverErrors.real_name"
                        prop="real_name"
                        :label="$t('contactInfo.realName')"
                        class="flex-grow-1 px-1"
                    >
                        <ElInput v-model="form.real_name" @change="text => form.real_name = text.trim()" />
                    </ElFormItem>

                    <ElFormItem
                        :error="serverErrors.phone"
                        prop="phone"
                        :label="$t('contactInfo.phone')"
                        class="flex-grow-1 px-1"
                    >
                        <ElInput
                            v-model="form.phone"
                            :placeholder="$t('contactInfo.phone')"
                            @change="text => form.phone = text.trim()"
                        />
                    </ElFormItem>
                </div>

                <div class="d-flex flex-row flex-wrap">
                    <ElFormItem
                        :error="serverErrors.adress"
                        prop="address"
                        :label="$t('contactInfo.address')"
                        class="flex-grow-1 px-1"
                    >
                        <ElInput
                            v-model="form.address"
                            :placeholder="$t('contactInfo.address')"
                            @change="text => form.address = text.trim()"
                        />
                    </ElFormItem>

                    <ElFormItem
                        :error="serverErrors.univesity"
                        prop="university"
                        :label="$t('contactInfo.university')"
                        class="flex-grow-1 px-1"
                    >
                        <SelectUniversity v-model="form.university" />
                    </ElFormItem>
                </div>
            </ElForm>

            <div class="d-flex justify-content-end button-info">
                <ElButton size="medium" plain @click="$router.push('/')">
                    {{ $t('personalInfo.form.cancel') }}
                </ElButton>
                <ElButton
                    :loading="processing"
                    size="medium"
                    type="primary"
                    plain
                    class="button-update"
                    @click="submit($refs.form, update)"
                >
                    {{ $t('personalInfo.form.update') }}
                </ElButton>
            </div>
        </ElCard>
    </BContainer>
</template>

<script>
    import _flow from 'lodash/flow';
    import _pick from 'lodash/fp/pick';
    import _assign from 'lodash/fp/assign';
    import formMixin from '~/mixins/form';
    import { updateContactInfo } from '~/api/profile/contact';
    import SelectUniversity from './SelectUniversity.vue';

    export default {
        components: {
            SelectUniversity,
        },

        mixins: [formMixin],

        props: {
            profile: Object,
        },

        data() {
            const form = _flow(
                _pick(['name', 'email', 'position', 'birthday', 'gender', 'job', 'address', 'phone', 'university', 'real_name']),
                _assign({ current_password: '' }),
            )(this.profile);

            const validations = {
                real_name: [
                    { required: true, message: this.$t('contactInfo.validations.realName.required') },
                    { max: 50, message: this.$t('contactInfo.validations.realName.max') },
                ],
                phone: [
                    { required: true, message: this.$t('contactInfo.validations.phone.required') },
                    { length: 11, message: this.$t('contactInfo.validations.phone.length') },
                    { pattern: /^(^[0]{1})+([0-9]{9,10}$)/, message: this.$t('contactInfo.validations.phone.pattern') },
                ],
                address: [
                    { required: true, message: this.$t('contactInfo.validations.address.required') },
                    { max: 255, message: this.$t('contactInfo.validations.address.length') },
                ],
            };

            return { form, validations };
        },

        methods: {
            async update() {
                this.processing = true;
                try {
                    const { data } = await updateContactInfo(this.form);

                    const message = this.$t('contactInfo.success');

                    this.$message({
                        message,
                        showClose: true,
                        duration: 12000,
                        type: 'success',
                    });

                    this.$store.commit('auth/updateUser', data);
                    this.clearErrors(this.$refs.form);
                    this.$router.push('/');
                } catch (error) {
                    this.handleError(error);
                } finally {
                    this.processing = false;
                }
            },
        },
    };
</script>

<style lang="scss">
    .card-title {
        font-size: 1.375rem;
        font-weight: 400;
    }
    .card-subtitle {
        font-size: 0.875rem;
        padding-top: 0.3rem !important;
    }
    .el-form-item__label {
        margin-bottom: 0;
    }
    .el-input--prefix .el-input__inner {
        padding-left: 15px !important;
    }
    .el-input--suffix .el-input__inner {
        padding-right: 15px !important;
    }
</style>

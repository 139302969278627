// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <section class="reset-password">
        <ElForm
            ref="form"
            :model="form"
            :rules="validations"
            size="medium"
        >
            <ElAlert v-if="serverErrorMessage" type="error" class="mb-2">
                {{ serverErrorMessage }}
            </ElAlert>

            <ElFormItem
                :error="serverErrors.email"
                prop="email"
                label="Email Address"
            >
                <ElInput v-model="form.email" />
            </ElFormItem>

            <ElFormItem
                :error="serverErrors.password"
                prop="password"
                label="New Password"
            >
                <ElInput v-model="form.password" type="password" />
            </ElFormItem>

            <ElFormItem
                :error="serverErrors.password_confirmation"
                prop="password_confirmation"
                label="Confirm New Password"
            >
                <ElInput v-model="form.password_confirmation" type="password" />
            </ElFormItem>
        </ElForm>

        <div class="d-flex justify-content-end">
            <ElButton
                :loading="processing"
                size="medium"
                type="primary"
                @click="submit($refs.form, update)"
            >
                Change password
            </ElButton>
        </div>
    </section>
</template>

<script>
    import _assign from 'lodash/assign';
    import formMixin from '~/mixins/form';
    import { confirmed } from '~/utils/form/validations';
    import { resetPassword } from '~/api/security/password';

    export default {
        mixins: [formMixin],

        props: {
            token: {
                type: String,
                required: true,
            },
        },

        data() {
            const form = { };
            const validations = {
                email: [
                    { required: true, message: 'Email is required' },
                    { type: 'email', message: 'Please input correct email address.' },
                ],
                password: [
                    { required: true, message: 'New password is required' },
                    { min: 8, message: 'Password must has at least 8 characters' },
                ],
                password_confirmation: [
                    { required: true, message: 'Password confirmation is required' },
                    { validator: confirmed(form, 'password'), message: 'Password confirmation does not match' },
                ],
            };

            return { form, validations };
        },

        methods: {
            async update() {
                this.processing = true;
                try {
                    await resetPassword(_assign({}, this.form, { token: this.token }));
                    this.$message({
                        message: 'Password changed!',
                        showClose: true,
                        duration: 12000,
                        type: 'success',
                    });

                    this.clearErrors(this.$refs.form);
                    this.$refs.form.resetFields();
                    this.$emit('success');
                } catch (error) {
                    this.handleError(error);
                } finally {
                    this.processing = false;
                }
            },
        },
    };
</script>

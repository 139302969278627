// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <ElCard>
            <header>
                <h1 class="card-title">
                    {{ $t('personalTokens.createToken.title') }}
                </h1>
                <p class="card-subtitle">
                    {{ $t('personalTokens.createToken.content') }}
                </p>
            </header>

            <ElForm
                v-if="!token"
                ref="form"
                :model="form"
                :rules="validations"
                size="medium"
                class="mt-4"
                @submit.native.prevent="submit($refs.form, generateToken)"
            >
                <ElFormItem
                    :error="serverErrors.name"
                    prop="name"
                    :label="$t('personalTokens.tokenList.tokenName')"
                >
                    <ElInput v-model="form.name" />
                </ElFormItem>

                <ElFormItem class="pt-2">
                    <ElButton
                        :loading="processing"
                        type="primary"
                        plain
                        @click="submit($refs.form, generateToken)"
                    >
                        {{ $t('personalTokens.createToken.generateToken') }}
                    </ElButton>

                    <RouterLink to="/security/tokens">
                        <ElButton>{{ $t('personalInfo.form.cancel') }}</ElButton>
                    </RouterLink>
                </ElFormItem>
            </ElForm>

            <section v-else class="mt-4">
                <!-- eslint-disable -->
                <el-alert
                    :closable="false"
                    type="success"
                    :title="$t('personalTokens.createToken.titleCreateSuccess')"
                    :description="$t('personalTokens.createToken.description')"
                    class="new-token-alert"
                    show-icon
                />
                <!-- eslint-enable -->

                <ElForm class="token-results">
                    <ElFormItem :label="token.name">
                        <ElInput v-model="token.accessToken" size="medium" readonly>
                            <template slot="append">
                                <ElButton
                                    ref="copyToken"
                                    v-tooltip
                                    type="primary"
                                    icon="fa fa-clipboard"
                                    :title="$t('personalTokens.createToken.copyToken')"
                                />
                            </template>
                        </ElInput>
                    </ElFormItem>
                </ElForm>
            </section>
        </ElCard>
    </BContainer>
</template>

<script>
    import Clipboard from 'clipboard';
    import formMixin from '~/mixins/form';
    import { generateToken } from '~/api/security/tokens';

    export default {
        mixins: [formMixin],

        data() {
            const form = {};
            const token = null;
            const processing = false;
            const validations = {
                name: [
                    { required: true, message: this.$t('personalTokens.createToken.requiredName') },
                ],
            };

            return {
                form, token, processing, validations,
            };
        },

        beforeDestroy() {
            if (this.$_clipboard) {
                this.$_clipboard.destroy();
            }
        },

        beforeRouteLeave(to, from, next) {
            if (!this.token) return next();

            return this.$confirm(this.$t('personalTokens.beforeRouterLeave.content'), this.$t('personalTokens.beforeRouterLeave.title'))
                .then(() => next())
                .catch(() => next(false));
        },

        methods: {
            async generateToken() {
                try {
                    this.processing = true;

                    const { data: { accessToken, token } } = await generateToken(this.form);

                    this.setToken({ accessToken, ...token });
                } catch (error) {
                    this.handleError(error);
                } finally {
                    this.processing = false;
                }
            },

            setToken(token) {
                this.token = token;

                this.$nextTick(() => {
                    this.$_clipboard = new Clipboard(this.$refs.copyToken.$el, {
                        text: () => this.token.accessToken,
                    });

                    this.$_clipboard.on('success', () => {
                        this.$message(this.$t('personalTokens.copiedClipboard'));
                    });
                });
            },
        },
    };
</script>

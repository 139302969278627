<template>
    <div>
        <div class="mb-2">
            <el-button icon="el-icon-plus" @click="create">
                Add Role
            </el-button>
        </div>

        <el-table :data="roles">
            <el-table-column label="ID" prop="id" />

            <el-table-column label="Role" prop="role" />

            <el-table-column
                :formatter="renderActions(tableActions)"
                label="Actions"
                fixed="right"
                width="120"
            />
        </el-table>

        <role-form ref="create" :save="store" title="New role" />
        <role-form ref="edit" :save="update" title="Edit role" />
    </div>
</template>

<script>
    import table from '../mixins/table';
    import RoleForm from './Form.vue';

    export default {
        components: {
            RoleForm,
        },

        mixins: [table],

        props: {
            roles: Array,
            actions: Object,
        },

        data() {
            return {
                editing: null,
                tableActions: [
                    {
                        icon: 'el-icon-edit',
                        action: this.edit,
                    },
                    {
                        icon: 'el-icon-delete',
                        type: 'danger',
                        confirm: {
                            title: 'Delete role',
                            text: 'Delete this role?',
                            action: this.actions.destroy,
                        },
                    },
                ],
            };
        },

        methods: {
            create() {
                this.$refs.create.open();
            },

            edit(role) {
                this.editing = role;
                this.$refs.edit.open(role);
            },

            store(role) {
                return this.actions.store(role);
            },

            update(input) {
                return this.actions.update(this.editing, input);
            },
        },
    };
</script>

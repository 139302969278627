// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <section>
        <el-card
            v-for="backupEmail in emails"
            :key="backupEmail.id"
            class="box-card mt-3"
            shadow="never"
        >
            {{ backupEmail.email }}
            <el-tag
                v-if="backupEmail.is_primary"
                effect="dark"
                type="success"
                size="small"
            >
                {{ $t('emails.listEmail.primary') }}
            </el-tag>
            <el-tag
                v-if="backupEmail.is_verified"
                effect="Plain"
                type="success"
                size="small"
            >
                {{ $t('emails.listEmail.verified') }}
            </el-tag>
            <el-tag
                v-else
                effect="Plain"
                type="info"
                size="small"
            >
                {{ $t('emails.listEmail.unverified') }}
            </el-tag>
            <el-tag
                v-if="backupEmail.is_bounced"
                effect="Plain"
                type="danger"
                size="small"
            >
                {{ $t('emails.listEmail.invalid') }}
            </el-tag>
            <template v-if="!backupEmail.is_primary">
                <el-button
                    class="btn-delete pull-right"
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    @click="destroy(backupEmail)"
                />
                <div v-if="!backupEmail.is_verified" class="pull-right mr-3">
                    {{ $t('emails.listEmail.verification') }}
                    <a href="#" @click="resend(backupEmail)">
                        {{ $t('emails.listEmail.resend') }}
                    </a>
                </div>
                <div v-else class="pull-right mr-3">
                    <a href="#" @click="makePrimary(backupEmail)">
                        {{ $t('emails.listEmail.makePrimary') }}
                    </a>
                </div>
            </template>
        </el-card>
    </section>
</template>

<script>
    import { resendEmail } from '~/api/email/email';

    export default {
        props: {
            profile: Object,
            emails: Array,
        },

        data() {
            return {
                processing: false,
            };
        },

        methods: {
            destroy(email) {
                const { id } = email;
                this.$confirm(this.$t('emails.listEmail.destroy.title'), this.$t('emails.listEmail.destroy.content'), {
                    confirmButtonText: 'OK',
                    cancelButtonText: this.$t('emails.listEmail.destroy.btn'),
                }).then(async () => {
                    await this.$store.dispatch('email/destroyEmail', id);
                    this.$message({
                        type: 'success',
                        message: this.$t('emails.listEmail.destroy.success'),
                    });
                });
            },

            async resend(email) {
                try {
                    await resendEmail(email.id);
                    this.$message({
                        type: 'success',
                        message: this.$t('emails.listEmail.methodResend.success1').concat(`${email.email}`).concat(this.$t('emails.listEmail.methodResend.success2')),
                    });
                } catch (error) {
                    this.$message.error(this.$t('emails.listEmail.methodResend.error'));
                }
            },

            makePrimary(email) {
                const { id } = email;
                this.$confirm(this.$t('emails.listEmail.methodMakePrimary.title'), this.$t('emails.listEmail.methodMakePrimary.content'), {
                    confirmButtonText: 'OK',
                    cancelButtonText: this.$t('emails.listEmail.methodMakePrimary.btn'),
                }).then(async () => {
                    await this.$store.dispatch('email/makePrimary', id);
                    await this.$store.commit('auth/updateUser', email);
                    this.$message({
                        type: 'success',
                        message: this.$t('emails.listEmail.methodMakePrimary.success'),
                    });
                });
            },
        },
    };
</script>

<style lang="scss">
    .pull-right{
        float: right;
    }

    .box-card {
        border: none !important;
        border-bottom: 1px solid #ebeef5 !important;

        .el-card__body {
            padding: 15px !important;
        }
    }
</style>

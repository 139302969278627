// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <ElCard>
            <header>
                <h1 class="card-title d-flex align-items-center justify-content-between">
                    <span>{{ $t('oAuthApplications.title') }}</span>

                    <div class="d-inline-block">
                        <router-link to="/security/apps/new">
                            <el-button type="primary" size="small" plain>
                                {{ $t('oAuthApplications.newApp') }}
                            </el-button>
                        </router-link>
                    </div>
                </h1>
                <p>
                    <router-link to="apps-tutorial">
                        {{ $t('learnMore.title') }}
                    </router-link>
                    {{ $t('learnMore.content') }}
                </p>
                <p class="card-subtitle">
                    {{ $t('oAuthApplications.content') }}
                </p>
            </header>

            <OauthAppsList :apps="apps" class="mt-4" @deleted="removeApp" />
        </ElCard>
    </BContainer>
</template>

<script>
    import _filter from 'lodash/filter';
    import { getOAuthApps } from '~/api/security/tokens';
    import OauthAppsList from '~/components/security/oauthApps/AppsList.vue';

    export default {
        components: {
            OauthAppsList,
        },

        data: () => ({ apps: [] }),

        async beforeRouteEnter(to, from, next) {
            const { data: apps } = await getOAuthApps(to.params.app);

            next((vm) => {
                vm.apps = apps;
            });
        },

        methods: {
            removeApp(app) {
                this.apps = _filter(this.apps, item => item.id !== app.id);
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <ElPopover
        ref="popover"
        :key="triggerUserMenu"
        :append-to-body="false"
        transition="el-zoom-in-top"
        placement="bottom-end"
        popper-class="user-menu__popper border"
        :trigger="triggerUserMenu"
    >
        <slot slot="reference" :profile="profile">
            <Avatar :image="profile.picture || profile.avatar" />
        </slot>

        <div class="user-menu__top d-flex justify-content-between">
            <Avatar :image="profile.picture || profile.avatar" :size="70" />

            <div class="flex-fill overflow-hidden">
                <div class="user-menu__info">
                    <div class="text-primary font-weight-bold text-truncate">
                        {{ profile.name }}
                    </div>

                    <div class="text-muted text-truncate">
                        @{{ profile.username }}
                    </div>
                </div>

                <a :href="accountsUrl" target="_blank">
                    <ElButton class="user-menu__edit-btn" type="primary" size="medium">
                        {{ $t('userMenu.edit') }}
                    </ElButton>
                </a>
            </div>
        </div>

        <hr>

        <slot name="menu" />

        <hr v-if="$slots.menu">

        <div class="user-menu__menu-item">
            <a :href="signOutUrl" @click="close">
                <i class="fa fa-sign-out-alt" /> {{ $t('userMenu.signOut') }}
            </a>
        </div>
    </ElPopover>
</template>

<script>
    import Avatar from './Avatar.vue';

    export default {
        components: {
            Avatar,
        },

        props: {
            service: {
                type: String,
            },

            profile: {
                type: Object,
                required: true,
            },

            showEdit: {
                type: Boolean,
                default: true,
            },

            accountsUrl: {
                type: String,
                default: window.GLOBALS.config.app_url,
            },
        },

        data() {
            return {
                triggerUserMenu: 'click',
            };
        },

        computed: {
            signOutUrl() {
                const url = `${this.accountsUrl}/logout`;

                return this.service
                    ? `${url}?service=${this.service}`
                    : url;
            },
        },

        mounted() {
            this.$root.$on('changleTriggerUserMenu', (text) => {
                this.triggerUserMenu = text;
            });

            this.$root.$on('doneTour', (text) => {
                this.triggerUserMenu = text;
            });
        },

        methods: {
            open() {
                this.$refs.popover.doShow();
            },

            close() {
                this.$refs.popover.doClose();
            },

            signOut() {
                window.location = this.signOutUrl;
            },
        },
    };
</script>

<style lang="scss">
    .user-menu {
        &__popper {
            padding: 0 !important;
            min-width: 250px !important;
            max-width: 300px;

            hr {
                margin: 0;
            }

            .popper__arrow {
                &:after {
                    border-bottom-color: #f0f0f0 !important;
                }
            }
        }

        &__top {
            padding: .75rem 1rem;
            background-color: #f0f0f0;
            overflow: hidden;

            .avatar {
                margin-right: 1rem;
            }
        }

        &__info {
            margin-bottom: .5rem;
        }

        &__edit-btn {
            padding: 6px 12px !important;
        }

        &__menu-item {
            font-size: 1rem;

            a {
                display: block;
                padding: .75rem 1rem;
                outline: none;
                color: inherit;

                &:hover {
                    color: inherit;
                    text-decoration: none;
                }
            }

            .fa {
                margin-right: 1rem;
            }

            &:hover {
                background-color: #f6f6f7;
            }
        }
    }
</style>

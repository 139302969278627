import _map from 'lodash/map';
import _assign from 'lodash/assign';
import _findIndex from 'lodash/findIndex';

/**
 * @param {Array} data
 * @param {String} keyField
 * @param {Object} newData
 */
export const updateData = (data, keyField, newData) => _map(
    data,
    item => (item[keyField] !== newData[keyField]
        ? item
        : _assign({}, item, newData)),
);

/**
 * @param {Array} data
 * @param {String} keyField
 * @param {any} key
 * @param {Function} update
 */
export const updateDataWith = (data, keyField, key, update) => {
    const updatedIndex = _findIndex(data, { [keyField]: key });

    if (updatedIndex === -1) {
        return data;
    }

    const newData = update(data[updatedIndex]);
    const updatedItem = _assign({}, data[updatedIndex], newData);

    return [
        ...data.slice(0, updatedIndex),
        updatedItem,
        ...data.slice(updatedIndex + 1),
    ];
};

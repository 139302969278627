// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <ElCard v-if="app">
            <header>
                <h1 class="card-title d-flex align-items-center justify-content-between">
                    <span>{{ app.name }}</span>
                </h1>

                <p class="card-subtitle">
                    {{ $t('oAuthApplications.formNewApp.manageApp') }}
                </p>
            </header>

            <OAuthAppForm
                ref="form"
                :app="app"
                submit-label="oAuthApplications.formNewApp.saveChanges"
                @submit="updateApp"
            />
        </ElCard>
    </BContainer>
</template>

<script>
    import { fetchApp, updateApp } from '~/api/security/tokens';
    import OAuthAppForm from '~/components/forms/security/OAuthApp.vue';

    export default {
        components: {
            OAuthAppForm,
        },

        data: () => ({ app: null }),

        async beforeRouteEnter(to, from, next) {
            const { data: app } = await fetchApp(to.params.app);

            next((vm) => {
                vm.app = app;
            });
        },

        methods: {
            async updateApp(form) {
                try {
                    await updateApp(this.app.hash_id, form);
                    this.$message.success('Updated successfully!');
                } catch (e) {
                    this.$refs.form.handleError(e);
                }
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer>
        <ElCard>
            <header>
                <h1 class="card-title d-flex align-items-center justify-content-between">
                    <span>{{ $t('personalTokens.title') }}</span>

                    <div class="d-inline-block">
                        <router-link to="/security/tokens/new">
                            <el-button type="primary" size="small" plain>
                                {{ $t('personalTokens.generateToken') }}
                            </el-button>
                        </router-link>
                    </div>
                </h1>

                <p class="card-subtitle">
                    {{ $t('personalTokens.content') }}
                </p>
            </header>

            <section class="mt-4">
                <TokensList :tokens="tokens" @delete="deleteToken" />
            </section>
        </ElCard>
    </BContainer>
</template>

<script>
    import _filter from 'lodash/filter';
    import { getPersonalAccessTokens } from '~/api/security/tokens';
    import TokensList from '~/components/security/accessTokens/TokensList.vue';

    export default {
        components: {
            TokensList,
        },

        data: () => ({ tokens: [] }),

        created() {
            getPersonalAccessTokens()
                .then(({ data }) => {
                    this.tokens = data;
                })
                .catch(() => {});
        },

        methods: {
            deleteToken(token) {
                this.tokens = _filter(this.tokens, item => item.id !== token.id);
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import Vue from 'vue';
import { formatTime, humanizeTime } from '~/filters/time';

function install() {
    Vue.filter('formatTime', formatTime);
    Vue.filter('humanizeTime', humanizeTime);
}

export default { install };

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <ElForm
        ref="form"
        :model="form"
        :rules="validations"
        size="medium"
        class="oauth-app-form mt-4"
    >
        <div v-if="app" class="d-flex">
            <ElFormItem :label="$t('oAuthApplications.table.appID')" class="flex-grow-1 pr-1">
                <ElInput :value="app.hash_id" readonly />
            </ElFormItem>

            <ElFormItem :label="$t('oAuthApplications.formNewApp.appSecret')" class="flex-grow-1 pl-1">
                <ElInput
                    :value="!showSecret ? '*'.repeat(20) : app.secret"
                    :type="!showSecret ? 'password' : undefined"
                    readonly
                >
                    <ElButton
                        slot="append"
                        size="small"
                        @click.prevent="showSecret = !showSecret"
                    >
                        <i :class="{ 'icon-hide': showSecret }" class="el-icon-view" />
                    </ElButton>
                </ElInput>
            </ElFormItem>
        </div>

        <ElFormItem
            :error="serverErrors.name"
            prop="name"
            :label="$t('oAuthApplications.table.displayName')"
        >
            <ElInput v-model="form.name" />
        </ElFormItem>

        <ElFormItem
            :error="serverErrors.homepage"
            prop="homepage"
            :label="$t('oAuthApplications.formNewApp.homepage')"
        >
            <ElInput v-model="form.homepage" />
        </ElFormItem>

        <ElFormItem
            :error="serverErrors.description"
            prop="description"
            :label="$t('oAuthApplications.formNewApp.description')"
        >
            <ElInput v-model="form.description" />
        </ElFormItem>

        <ElFormItem
            :error="serverErrors.redirect"
            prop="redirect"
            :label="$t('oAuthApplications.formNewApp.redirect')"
        >
            <ElInput v-model="form.redirect" />
        </ElFormItem>

        <ElFormItem class="pt-2">
            <ElButton
                :loading="processing"
                type="primary"
                plain
                @click="submit($refs.form, () => $emit('submit', form))"
            >
                {{ $t(submitLabel) }}
            </ElButton>

            <RouterLink v-if="!app" to="/security/apps" class="mr-2">
                <ElButton>{{ $t('personalInfo.form.cancel') }}</ElButton>
            </RouterLink>

            <ElButton v-else class="mr-2" @click.prevent="$refs.form.resetFields()">
                {{ $t('oAuthApplications.formNewApp.reset') }}
            </ElButton>
        </ElFormItem>
    </ElForm>
</template>

<script>
    import _pick from 'lodash/pick';
    import formMixin from '~/mixins/form';

    export default {
        mixins: [formMixin],

        props: {
            app: {
                type: Object,
                default: () => null,
            },

            submitLabel: {
                type: String,
                required: true,
            },
        },

        data() {
            const form = _pick(this.app || {}, ['name', 'homepage', 'description', 'redirect']);
            const validations = {
                name: [
                    { required: true, message: this.$t('oAuthApplications.formNewApp.validations.name') },
                ],
                homepage: [
                    { required: true, message: this.$t('oAuthApplications.formNewApp.validations.homepage') },
                ],
                description: [
                    { max: 300, message: this.$t('oAuthApplications.formNewApp.validations.description') },
                ],
                redirect: [
                    { required: true, message: this.$t('oAuthApplications.formNewApp.validations.redirect') },
                ],
            };

            return { form, validations, showSecret: false };
        },
    };
</script>

<style lang="css">
    .oauth-app-form .el-icon-view.icon-hide:after {
        content: '';
        display: block;
        position: absolute;
        top: 1rem;
        left: 0.7rem;
        width: 1.5rem;
        background: #a0a0a0;
        height: 2px;
        transform: rotate(145deg);
        border-top: 1px solid #fff;
    }
</style>

const en = {
    homePage: {
        title: 'Welcome,',
        content: 'Manage your profile information and security with Viblo Accounts',
        myProfile: 'My Profile',
        passWord: 'Password',
        connectedAccounts: 'Connected Accounts',
        accessTokens: 'Access Tokens',
        oAuthApps: 'OAuth Apps',
        vibloServices: 'Viblo Services',
    },

    menuAside: {
        home: 'Home',
        myProfile: 'My Profile',
        personalInfo: 'Personal Info',
        contactInfo: 'Contact Info',
        security: 'Security',
        password: 'Password',
        connectedAccounts: 'Connected Accounts',
        developerSettings: 'Developer Settings',
        oAuthApplications: 'OAuth Applications',
        personalAccessTokens: 'Personal Access Tokens',
        authorizedOAuthApps: 'Authorized OAuth Apps',
        collapseSidebar: 'Collapse Sidebar',
    },

    userMenu: {
        edit: 'Edit',
        signOut: 'Sign out',
        goToDashboard: 'Go to dashboard',
    },

    personalInfo: {
        title: 'Personal Info',
        content: 'Manage your personal info.',
        changeAvatar: {
            title: 'Click to upload your avatar.',
            change: 'Change',
            changeDialog: {
                title: 'Change Profile Picture',
                content: 'Only 1 step to easily create a stunning avatar from a photo.',
                tip: 'Tips: Use scroll wheel to zoom, drag to move profile picture.',
                save: 'Save',
                FileMismatch: 'Your file type is mismatch. Please try another file type: {values}',
                sizeExceed: 'File size exceeds. Please choose a file smaller than',
            },
        },
        form: {
            userName: 'Username',
            displayName: 'Display Name',
            birthday: 'Birthday',
            gender: 'Gender',
            cancel: 'Cancel',
            update: 'Update',
            male: 'Male',
            female: 'Female',
            other: 'Other',
            select: 'Select',
        },
        validations: {
            name: {
                required: 'Name is required',
                max: 'Name must not be longer than 50 characters',
            },
            job: {
                required: 'Job Title is required',
                max: 'Job Title must not be longer than 255 characters',
            },
            gender: 'Gender is required',
            birthday: 'Birthday is required',
        },
        updateEmailSuccess: 'Updated successfully! We have sent an email with a confirmation link to your new email address. Please check your inbox!',
        updateSuccess: 'Your personal info are updated successfully!',
    },

    contactInfo: {
        title: 'Contact Info',
        content: 'Manage your contact information.',
        realName: 'Real Name',
        phone: 'Phone',
        address: 'Address',
        university: 'University',
        validations: {
            realName: {
                required: 'Real name is required',
                max: 'Real name must not be longer than 50 characters',
            },
            phone: {
                required: 'Phone is required',
                length: 'Phone must not be longer than 11 characters',
                pattern: 'Phone format is invalid.',
            },
            address: {
                required: 'Address is required',
                length: 'Address must not be longer than 255 characters',
            },
        },
        success: 'Your contact info are updated successfully!',
        selectUniversity: 'Select University',
    },

    emails: {
        content: 'Your backup email address will be used as an additional destination for security-relevant account notifications and can also be used for password resets.',
        hasInvalidEmails: {
            content1: 'We couldn\'t send email to some of your email addresses. Those email addresses have been marked with',
            content2: 'invalid',
            content3: 'tag in the list below. Please add and set a valid email addresses as primary and remove any invalid ones to hide this warning.',
        },
        addEmail: {
            email: {
                label: 'Add new email address',
            },
            passWord: 'Current password',
            cancel: 'Cancel',
            add: 'Add',
            validations: {
                email: {
                    required: 'Email is required',
                    type: 'Must be a valid email',
                    max: 'Email must not be longer than 255 characters',
                },
                passWord: 'Your password is required to add email',
            },
            success: 'Add email successfully !',
        },
        listEmail: {
            primary: 'Primary',
            verified: 'Verified',
            unverified: 'Unverified',
            invalid: 'Invalid',
            verification: 'Verification email sent.',
            resend: 'Resend',
            makePrimary: 'Make Primary',
            destroy: {
                title: 'Are you sure ?',
                content: 'Delete email',
                btn: 'Cancel',
                success: 'Delete completed',
            },
            methodResend: {
                success1: 'We sent a verification email to ',
                success2: '. Please follow the instructions in it.',
                error: 'Something went wrong. Please try again later.',
            },
            methodMakePrimary: {
                title: 'Are you sure ?',
                content: 'Change primary email',
                btn: 'Cancel',
                success: 'Change primary email completed',
            },
        },
    },

    changePassword: {
        title: 'Change Password',
        content: 'Change your account password. You should keep a strong password to prevent unauthorized access to your account.',
        formChange: {
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            confirmNewPassword: 'Confirm New Password',
            btnChange: 'Change password',
        },
        validations: {
            currentPassword: 'Current password is required',
            newPassword: {
                required: 'New password is required',
                min: 'Password must has at least 8 characters',
                notDifferent: 'The new password and current password must be different.',
            },
            passwordConfirmation: {
                required: 'Password confirmation is required',
                match: 'Password confirmation does not match',
            },
        },
        success: 'Password changed!',
    },

    connectedAccounts: {
        title: 'Connected Accounts',
        content: 'Connect Viblo account to your social accounts. These social accounts can be used to login to Viblo by 1-click.',
        table: {
            provider: 'Provider',
            account: 'Account',
            actions: 'Actions',
            disconnect: 'Disconnect',
            connect: 'Connect',
            success: 'account disconnected',
            error: 'Something went wrong. We could not disconnect your account',
        },
        noAccount: 'No account connected',
    },

    oAuthApplications: {
        title: 'OAuth Applications',
        newApp: 'New oauth app',
        content: 'Manage your oauth applications which are used to access the Viblo API.',
        table: {
            displayName: 'Display name',
            appID: 'App ID',
            createdAt: 'Created at',
            actions: 'Actions',
            deleteApp: 'Delete app',
        },
        deleteApp: {
            content: 'Deleting the OAuth application will invalidate any access tokens authorized by users.',
            title: 'Delete application?',
            success: 'Deleted oauth app successfully!',
        },
        formNewApp: {
            title: 'New OAuth Application',
            content: 'Manage your oauth applications which are used to access the Viblo API.',
            appSecret: 'App secret',
            homepage: 'Homepage URL',
            description: 'Application description',
            redirect: 'Authorization callback URL',
            registerApplication: 'Register application',
            success: 'Created successfully!',
            manageApp: 'Manage your OAuth app information.',
            saveChanges: 'Save changes',
            reset: 'Reset',
            validations: {
                name: 'Application name can not be blank',
                homepage: 'Homepage URL is required',
                description: 'Description must not be longer than 300 characters',
                redirect: 'Authorization callback URL is required',
            },
        },
    },

    personalTokens: {
        title: 'Personal Access Tokens',
        content: 'Personal Access Tokens are API-keys for Viblo Browser Extension, Viblo Atom plugin. These tokens can be used to sign in to your account.',
        generateToken: 'Generate new token',
        tokenList: {
            tokenName: 'Token name',
            expiresAt: 'Expires at',
            actions: 'Actions',
            revokeToken: 'Revoke token',
        },
        methodRevoke: {
            title: 'Are you sure you?',
            content: 'Any applications are using this token will no longer be able to access to your account. Are you sure you want to delete this token?',
            error: 'Can not delete this token.',
        },
        createToken: {
            title: 'New Personal Access Token',
            content: 'Personal Access Tokens are API-keys for Viblo Browser Extension, Viblo Atom plugin. These tokens can be used to sign in to your account.',
            generateToken: 'Generate token',
            titleCreateSuccess: 'Token created successfully',
            description: 'Make sure to copy your new personal access token now. You won’t be able to see it again!',
            copyToken: 'Copy Access Token',
            requiredName: 'Token name is required.',
        },
        beforeRouterLeave: {
            title: 'Are you sure?',
            content: 'Make sure to copy your new personal access token now. You won’t be able to see it again!',
        },
        copiedClipboard: 'Copied to clipboard',
    },

    authorizedOAuthApps: {
        title: 'Authorized OAuth Apps',
        firstContent: 'You have granted',
        lastContent: 'applications access to your account.',
        empty: 'There is nothing here.',
        time: 'Last used within the last',
        revokeToken: 'Revoke Token',
        methodRevoke: {
            title: 'Are you sure you?',
            content: 'Any applications are using this token will no longer be able to access to your account. Are you sure you want to delete this token?',
            error: 'Can not delete this token.',
        },
    },

    login: {
        loginTo: 'Login to',
        unverifiedEmail: 'Resend vertification email',
        username: 'Username or email',
        password: 'Password',
        login: 'Login',
        forgotPassword: 'Forgot your password?',
        createAccount: 'Create account',
        orLoginWith: 'Or login with',
        requiredName: 'Username/email is required',
        requiredPassword: 'Password is required',
    },

    register: {
        title: 'Register account for',
        content1: 'Welcome to',
        content2: 'Viblo platform',
        content3: 'Join us to find useful information required to improve your skills. Please fill your info into the form below to continue.',
        mustVerifyEmail: {
            welcome: 'Welcome',
            content1: 'your account have been',
            content2: 'registered successfully',
            content3: 'We have sent you an activation to the email address ',
            content4: 'Please check your inbox to complete.',
            noReceive: 'If you don\'t receive the activation email from us, please',
            resend: 'resend',
            activationEmail: ' the activation email.',
            yourAccount: ', your account have been ',
            registeredSuccessfully: 'registered successfully',
            accountActivated: 'Your account is activated. Let',
            login: 'login',
            explore: 'to explore!',
        },
        form: {
            yourName: 'Your name',
            emailMatched: 'The E-Mail address must be matched with your social account.',
            emailAddress: 'Your email address',
            username: 'Username',
            password: 'Password',
            confirmPassword: 'Confirm your password',
            agree: 'I agree to',
            termsOfService: 'Viblo Terms of Service',
            signUp: 'Sign up',
            orLoginWith: 'Or login with',
            validations: {
                name: {
                    required: 'Name is required',
                    max: 'Must be less than 50 characters',
                },
                email: {
                    required: 'Email is required',
                    type: 'Must be a valid email',
                    max: 'Email must not be longer than 255 characters',
                },
                username: {
                    required: 'Username is required',
                    max: 'Must be less than 20 characters',
                },
                password: {
                    required: 'Password is required',
                    min: 'Password must has at least 8 characters',
                },
                passwordConfirmation: {
                    required: 'Password confirmation is required',
                    confirmed: 'Password confirmation does not match',
                },
                terms: 'Please agree to our Terms of service',
            },
        },
    },

    forgotYourPassword: {
        title: 'Forgot Your Password',
        submitLabel: 'Send me email',
        content: 'You forgot your password? Don\'t worry! Give us the email which is used to register your Viblo account. We\'ll mail you a link to reset your password.',
        sendSuccess: {
            content1: 'We have ',
            content2: 'sent an email',
            content3: 'with a link to reset your password.',
            content4: 'It may takes from 1 to 2 minutes for complete. Please check your inbox ',
        },
        RequestActivationForm: {
            content1: 'We have ',
            content2: 'sent an email',
            content3: 'with a confirmation link to your email address. It may takes from 1 to 2 minutes for complete.',
            content4: 'Please check your inbox',
            emailAddress: 'You email address',
            required: 'The email is required.',
            type: 'Please input correct email address.',
        },
    },

    sendActivationEmail: {
        title: 'Send Account Activation Email',
        label: 'Send me activation email',
        content: 'The account activation email is sent when registering Viblo account. If you don\'t receive it, please give us your email address which registered for Viblo. We will re-send you the account activation email.',
    },

    authorize: {
        title: 'Authorize',
        appAccess: '"{name}" app would like to access your account',
        appWillBe: 'This application will be able to:',
        profile: 'Read all your profile data such as: email, personal info',
        openid: 'Issue an id_token',
        email: 'Access your email address (read-only)',
        content: 'By clicking on Allow, you allow this app to use your information in accordance with their respective terms of service and privacy policies. You can change/remove this or other at any time in your account settings.',
        deny: 'Deny',
        allow: 'Allow',
    },

    learnMore: {
        title: 'Learn more',
        content: 'about using App OAuth.',
    },

    tutorialOAuthApp: {
        titlePage: 'Use App OAuth to access your information',
        step1: {
            title: '1. Create an Oauth App',
            goToPage: 'Go to',
            clickButton: ' and click the button to create a new App OAuth',
            infomationApp: 'OAuth App will have all the information after it is created',
            appId: 'App ID',
            appSecret: 'App seret',
            displayName: 'Display name',
            homepageURL: 'Homepage URL',
            applicationDescription: 'Application description',
            redirect: 'Authorization call back URL',
        },
        step2: {
            title: '2. Get the authorization code',
            gotoPage: 'Your application should have a button that redirects to the link',
            parameters: 'with the following parameters, where:',
            parameter1: 'grant_type: authorization_code',
            parameter2: 'client_id: The App ID you just created above',
            parameter3: 'scope: Openid, profile, email',
            parameter4: 'response_type: code',
            description: 'After that, the user is asked if they are willing to grant the permission your app is asking for. This process is called user consent. If the user selects "Allow", then Viblo Accounts will send your application just registered above under the "Authorization call back URL" an Authorization Code. If the user "Deny", Viblo Accounts will return to the homepage.',
        },
        step3: {
            title: '3. Get access token',
            description: 'The access token is an opaque string that identifies the user, application, or Page. Applications can use this code to make API calls. When someone connects to an app through Viblo\'s Sign in method, that app obtains an access token to grant temporary, secure access to the Viblo Accounts API by converting the authorization code to an access_token from Viblo Accounts. with the following parameters:',
            parameter1: 'client_id: The App ID you just created above',
            parameter2: 'client_secret: App secret you just created above',
            parameter3: 'code: Authorization Code just received in step 2',
            parameter4: 'grant_type: authorization_code',
            parameter5: 'scope: Openid, profile, email',
        },
        step4: {
            title: '4. Send access token',
            description: 'After the application obtains an access token in the previous step, it will send it to the Viblo Accounts api in an HTTP header Authorization request.',
            result: 'The end result is the user\'s information.',
        },
    },
};

export default en;

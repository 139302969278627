// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import defaultImage from '~/assets/profile_default.png';

const getFallbackImage = (value, modifiers) => {
    if (typeof value !== 'string') return defaultImage;

    return modifiers.gravatar
        ? `https://www.gravatar.com/avatar/${value}?s=80&d=mm`
        : value;
};

/** @param {Element} el */
export default function (el, { value, modifiers }) {
    const fallbackImage = getFallbackImage(value, modifiers);

    el.addEventListener('error', (event) => {
        event.target.setAttribute('src', fallbackImage);
        event.target.setAttribute('srcset', `${fallbackImage} 1x`);
    }, { once: true });
}

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import _filter from 'lodash/filter';
import {
    getSettings,
    storeSettingsItem,
    updateSettingsItem,
    deleteSettingsItem,
} from '~/api/admin/system-settings';
import { updateData } from '~/utils/manage-page';

const initialState = () => ({
    settings: [],
});

const actions = {
    async getSettings({ commit }, params) {
        const result = await getSettings(params).then(({ data }) => data);
        commit('fetch', result);
    },

    async storeSetting({ commit }, data) {
        const result = await storeSettingsItem(data);
        commit('set', result.data);
    },

    async updateSetting({ commit }, item) {
        const result = await updateSettingsItem(item.id, item);
        commit('update', result);
    },

    async destroy({ commit }, id) {
        await deleteSettingsItem(id).then(() => {
            commit('delete', id);
        });
    },
};

const mutations = {
    fetch(state, data) {
        state.settings = data;
    },

    update(state, data) {
        state.settings = updateData(state.settings, 'id', data);
    },

    set(state, newItem) {
        state.settings = [newItem, ...state.settings];
    },

    delete(state, id) {
        state.settings = _filter(state.settings, i => i.id !== id);
    },
};

export default {
    state: initialState,
    actions,
    mutations,
    namespaced: true,
};

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <BContainer :class="$style.login">
        <BRow class="justify-content-center">
            <BCol :md="8" :lg="6" :xl="5">
                <ElCard :class="$style.card">
                    <div class="mb-6 text-center">
                        <img :class="$style.logo" src="@/assets/logo.svg" alt="Viblo">
                    </div>

                    <div class="my-4 text-center">
                        <h5>{{ $t('login.loginTo') }} {{ loginService }}</h5>
                    </div>

                    <LoginForm />
                </ElCard>
            </BCol>
        </BRow>
    </BContainer>
</template>

<script>
    import { serviceName } from '~/utils/sso';
    import LoginForm from '~/components/auth/LoginForm.vue';

    export default {
        components: {
            LoginForm,
        },

        metaInfo() {
            return {
                title: this.loginService,
                titleTemplate: '%s - Login',
            };
        },

        computed: {
            loginService() {
                return serviceName(this.$route.query.service) || 'Viblo';
            },
        },
    };
</script>

<style lang="scss" module>
    @import "../styles/bootstrap/mixins";

    .login {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logo {
        width: 120px;
    }

    .card {
        padding: 1.5rem .5rem;
    }

    @include media-breakpoint-down(sm) {
        .card {
            border: none !important;
            box-shadow: none !important;
            padding: 0 !important;
        }
    }
</style>

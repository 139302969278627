// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
export default ({ router, store }) => {
    if (store.state.auth.user) {
        store.commit('session/pushMessage', {
            message: 'You are logged in. The page is only for guest.',
            type: 'error',
        });
        store.dispatch('session/showMessages');

        router.push({ path: '/', query: { t: Date.now() } });
    }
};

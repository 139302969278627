<template>
    <div>Hello, {{ user.username }}. Welcome to dashboard</div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        computed: {
            ...mapState('auth', ['user']),
        },
    };
</script>

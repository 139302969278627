<template>
    <div class="mr-3">
        <span class="mr-05">
            Filter:
        </span>
        <el-select
            v-model="status"
            @change="filter"
        >
            <el-option
                v-for="item in actionsList"
                :key="item.param"
                :value="item.param"
                :label="item.text"
            />
        </el-select>
    </div>
</template>

<script>
    export default {
        data() {
            const actionsList = [
                {
                    param: 'all',
                    text: 'All users',
                },
                {
                    param: 'banned',
                    text: 'Banned users',
                },
                {
                    param: 'active',
                    text: 'Active users',
                },
            ];

            return {
                status: this.$route.query.status || 'active',
                actionsList,
            };
        },

        methods: {
            filter() {
                this.$emit('changeStatus', this.status);
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <div class="dashboard-page">
        <BContainer>
            <BRow class="py-4">
                <BCol span="12">
                    <div class="d-flex justify-content-center">
                        <ChangeAvatarBtn trigger="hover">
                            <Avatar :image="user.picture" :size="128" />
                        </ChangeAvatarBtn>
                    </div>
                    <div class="text-center mt-4">
                        <h1 class="greeting-title">
                            {{ $t('homePage.title') }} {{ user.name }}
                        </h1>
                        <p class="greeting-sub-title">
                            {{ $t('homePage.content') }}
                        </p>
                    </div>
                </BCol>
            </BRow>

            <BRow class="py-4">
                <BCol
                    v-for="item in menuItems"
                    :key="item.link"
                    sm="6"
                    md="4"
                    class="mb-2"
                >
                    <RouterLink :to="item.link" class="menu-item">
                        <ElCard class="card-item text-center" shadow="hover">
                            <img :src="item.icon" :alt="item.label" class="image">
                            <h2 class="card-title mt-4">
                                {{ $t(item.label) }}
                            </h2>
                        </ElCard>
                    </RouterLink>
                </BCol>
            </BRow>

            <BRow class="py-4 mt-5">
                <BCol span="12">
                    <h2 class="greeting-title">
                        {{ $t('homePage.vibloServices') }}
                    </h2>
                </BCol>
            </BRow>

            <BRow>
                <BCol
                    v-for="(service, alias) in vibloServices"
                    :key="alias"
                    sm="12"
                    md="6"
                    xl="4"
                    class="mb-2"
                >
                    <a
                        :href="service.homepage"
                        class="menu-item"
                        target="_blank"
                        rel="noopener"
                    >
                        <ElCard
                            :class="`card-item card-img card-img--${alias} text-center`"
                            shadow="hover"
                        >
                            <img :src="service.logo" :alt="service.name">
                        </ElCard>
                    </a>
                </BCol>
            </BRow>
        </BContainer>
    </div>
</template>

<script>
    import _set from 'lodash/set';
    import { mapState } from 'vuex';
    import { services as vibloServices } from '~/utils/sso';

    import Avatar from '~/components/Avatar.vue';
    import ChangeAvatarBtn from '~/components/buttons/ChangeAvatar.vue';

    import _man from '~/assets/icons/svg/man.svg';
    import _password from '~/assets/icons/svg/password.svg';
    import _socialNetwork from '~/assets/icons/svg/social-network.svg';
    import _key from '~/assets/icons/svg/key.svg';
    import _3d from '~/assets/icons/svg/3d.svg';

    import _viblo from '~/assets/logo/viblo.png';
    import _code from '~/assets/logo/code.png';
    import _ctf from '~/assets/logo/ctf.png';
    import _cv from '~/assets/logo/cv.png';
    import _learn from '~/assets/logo/learn.png';
    import _partner from '~/assets/logo/partner.png';
    import _battle from '~/assets/logo/battle.png';

    const menuItems = [
        { label: 'homePage.myProfile', icon: _man, link: '/profile/personal' },
        { label: 'homePage.passWord', icon: _password, link: '/security/password' },
        { label: 'homePage.connectedAccounts', icon: _socialNetwork, link: '/security/connected-accounts' },
        { label: 'homePage.accessTokens', icon: _key, link: '/security/tokens' },
        { label: 'homePage.oAuthApps', icon: _3d, link: '/security/apps' },
    ];

    _set(vibloServices, 'viblo.logo', _viblo);
    _set(vibloServices, 'code.logo', _code);
    _set(vibloServices, 'ctf.logo', _ctf);
    _set(vibloServices, 'cv.logo', _cv);
    _set(vibloServices, 'learning.logo', _learn);
    _set(vibloServices, 'partner.logo', _partner);
    _set(vibloServices, 'battle.logo', _battle);

    export default {
        components: {
            Avatar,
            ChangeAvatarBtn,
        },

        data: () => ({
            menuItems,
            vibloServices,
        }),

        computed: {
            ...mapState('auth', ['user']),
        },
    };
</script>

<style lang="scss">
    .dashboard-page {
        .greeting {
            &-title {
                font-size: 1.375rem;
            }
            &-sub-title {
                font-size: 1rem;
                margin-top: 1rem;
            }
        }
        .menu-item {
            text-decoration: none;
            .card-item {
                background: rgba(0,0,0,0.15);
                height: 100%;
                min-height: 128px;
                background: #dfe6e9;
                font-size: 1.375rem;
                .image {
                    height: 80px;
                    max-width: 100%;
                }
                .logo {
                    height: 60px;
                }
            }
            .card-item.card-img {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: auto;
                    height: 2.2rem;
                }
                &--viblo {
                    background-color: white;
                }
                &--code {
                    background-color: #3E6E8C;
                }
                &--cv {
                    background: #2A2B50;
                }
                &--ctf {
                    background: #2E3539;
                }
                &--ctf {
                    background-color: #2E3539;
                }
                &--learning {
                    background-color: #002369;
                }
                &--partners {
                    background-color: white;
                }
                &--battle {
                    background-color: #2c2e32;;
                }
            }
            .el-card.is-hover-shadow:hover,
            .el-card.is-hover-shadow:focus {
                -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .4);
                -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .4);
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .4);
            }
        }
    }

    @media screen and (max-width: 768px) {
        .dashboard-page {
            .card-item.card-img {
                img {
                    height: 1.2rem !important;
                }
            }
        }
    }
</style>

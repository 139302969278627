<template>
    <el-select
        v-model="value"
        :placeholder="$t('contactInfo.selectUniversity')"
        filterable
        clearable
        class="w-100"
        @change="$emit('input', value)"
    >
        <el-option
            v-for="(item, index) in data"
            :key="index"
            :value="item.id"
            :label="`${item.name} (${item.code})`"
        />
    </el-select>
</template>

<script>
    import { fetch } from '~/api/university';

    export default {
        props: {
            value: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                loading: false,
                data: [],
            };
        },

        async mounted() {
            const { data } = await fetch();
            this.data = data;
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import axios from '~/libs/axios';

export const getEmail = () => axios.get('/emails');
export const storeEmail = data => axios.post('/emails', data);
export const destroyEmail = id => axios.delete(`/emails/${id}`);
export const resendEmail = id => axios.post(`/emails/${id}/resend`);
export const makePrimary = id => axios.put(`/emails/${id}/make-primary`);

export default {
    getEmail,
    storeEmail,
    destroyEmail,
    resendEmail,
    makePrimary,
};

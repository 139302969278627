// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <ElDialog
        :visible.sync="visible"
        :before-close="onClose"
        :show-close="closeable"
        :close-on-click-modal="closeable"
        :close-on-press-escape="closeable"
        class="profile-picture-dialog"
    >
        <div slot="title">
            {{ $t('personalInfo.changeAvatar.changeDialog.title') }}
            <div v-if="!closeable" class="mt-2 sub-title-dialog">
                {{ $t('personalInfo.changeAvatar.changeDialog.content') }}
            </div>
        </div>
        <div class="picture-picker text-center">
            <Croppa
                v-model="croppa"
                :width="260"
                :height="260"
                :prevent-white-space="true"
                :placeholder-font-size="16"
                :zoom-speed="15"
                :file-size-limit="maxFileSize*1024*1024"
                accept=".jpg, .jpeg, .png, .gif"
                placeholder-color="#333"
                remove-button-color="black"
                @file-type-mismatch="handleFileTypeMismatch"
                @file-size-exceed="handleFileSizeExceed"
            />
        </div>
        <p class="text-center mt-1 title-tip">
            <small>{{ $t('personalInfo.changeAvatar.changeDialog.tip') }}</small>
        </p>
        <div class="picture-toolbox text-center mt-4">
            <ElButtonGroup>
                <ElButton
                    :disabled="hasNotImage"
                    icon="fa fa-reply fa-rotate-270"
                    title="Rotate the image 90 degrees to the left"
                    @click="croppa.rotate(-1)"
                />
                <ElButton
                    :disabled="hasNotImage"
                    icon="fa fa-share fa-rotate-90"
                    title="Rotate the image 90 degrees to the right"
                    @click="croppa.rotate()"
                />
                <ElButton
                    :disabled="hasNotImage"
                    icon="fa fa-redo-alt fa-rotate-90"
                    title="Flip vertical"
                    @click="croppa.flipX()"
                />
                <ElButton
                    :disabled="hasNotImage || processing"
                    :loading="processing"
                    type="primary"
                    @click="save"
                >
                    {{ $t('personalInfo.changeAvatar.changeDialog.save') }}
                </ElButton>
            </ElButtonGroup>
        </div>
    </ElDialog>
</template>

<script>
    import 'vue-croppa/dist/vue-croppa.css';

    import axios from 'axios';
    import { mapState, mapMutations } from 'vuex';
    import { component as Croppa } from 'vue-croppa';
    import formMixin from '~/mixins/form';

    export default {
        components: {
            Croppa,
        },

        mixins: [formMixin],

        props: {
            require: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            croppa: null,
            maxFileSize: 6, // Megabytes
        }),

        computed: {
            ...mapState('dialogs', ['profilePictureVisible']),

            visible: {
                get() {
                    return this.profilePictureVisible;
                },
                set(value) {
                    return this.toggleProfilePictureDialog(value);
                },
            },

            hasNotImage() {
                return this.croppa && !this.croppa.imageSet;
            },

            closeable() {
                if (this.require) {
                    this.toggleProfilePictureDialog(true);
                }

                return !this.require;
            },
        },

        methods: {
            ...mapMutations('dialogs', ['toggleProfilePictureDialog']),

            save() {
                this.croppa.generateBlob(async (blob) => {
                    try {
                        this.processing = true;
                        const formData = new FormData();

                        formData.append('profile_picture', blob, this.croppa.chosenFile.name);

                        const { data } = await axios.post('/profile/picture/change', formData);

                        this.$message.success('Updated successfully!');
                        this.$store.commit('auth/updateUser', data);
                        this.visible = false;
                    } catch (error) {
                        this.handleError(error);
                    } finally {
                        this.processing = false;
                    }
                });
            },

            onClose(done) {
                this.croppa.remove();
                done();
            },

            handleFileTypeMismatch() {
                this.$message.error(this.$t('personalInfo.changeAvatar.changeDialog.FileMismatch', { values: 'jpg, jpeg, png, gif' }));
            },

            handleFileSizeExceed() {
                this.$message.error(this.$t('personalInfo.changeAvatar.changeDialog.sizeExceed').concat(`${this.maxFileSize}MB.`));
            },
        },
    };
</script>

<style lang="scss">
    .profile-picture-dialog {
        .picture-picker .croppa-container {
            border: solid 2px rgb(218, 220, 224);
            .icon-remove {
                top: -1rem !important;
                right: -1rem !important;
            }
        }

        .el-dialog {
            min-width: 300px;
        }

        .title-tip {
            word-break: break-word;
        }
    }

    .sub-title-dialog {
        font-size: 0.9rem;
    }

    .el-message {
        z-index: 99999 !important;
    }
</style>

<template>
    <ElTable :data="accounts" class="connected-account">
        <ElTableColumn prop="provider" :label="$t('connectedAccounts.table.provider')">
            <template slot-scope="{ row: account }">
                <span class="connected-account--provider">
                    <i :class="`fab fa-${account.provider} mr-1`" />
                    {{ account.provider }}
                </span>
            </template>
        </ElTableColumn>
        <ElTableColumn
            prop="email"
            :label="$t('connectedAccounts.table.account')"
            :formatter="account => account.email || $t('connectedAccounts.noAccount')"
        />
        <ElTableColumn :label="$t('connectedAccounts.table.actions')">
            <template slot-scope="{ row: account }">
                <template v-if="account.email">
                    <ElButton
                        :disabled="processing[account.provider]"
                        type="text"
                        size="medium"
                        icon="fa fa-unplug"
                        @click="disconnect(account)"
                    >
                        {{ $t('connectedAccounts.table.disconnect') }}
                    </ElButton>
                </template>

                <ElButton
                    v-else
                    :disabled="processing[account.provider]"
                    type="text"
                    size="medium"
                    icon="fa fa-plug"
                    @click="connect(account)"
                >
                    {{ $t('connectedAccounts.table.connect') }}
                </ElButton>
            </template>
        </ElTableColumn>
    </ElTable>
</template>

<script>
    import { disconnectAccount } from '~/api/social-accounts';

    const getProviderName = provider => ({
        google: 'Google',
        facebook: 'Facebook',
        github: 'GitHub',
    })[provider];

    export default {
        props: {
            accounts: {
                type: Array,
                default: () => [],
            },
        },

        data: () => ({ processing: {} }),

        methods: {
            connect({ provider }) {
                this.processing[provider] = true;
                window.location.href = `/auth/${provider}/?continue=${window.location.href}&action=connect`;
            },

            async disconnect({ provider }) {
                try {
                    const providerName = getProviderName(provider);
                    this.processing[provider] = true;
                    await disconnectAccount(provider);
                    this.$emit('disconnected', provider);
                    this.$message.info(`${providerName}`.concat(this.$t('connectedAccounts.table.success')));
                } catch (error) {
                    this.$message.error(this.$t('connectedAccounts.table.error'));
                    throw error;
                } finally {
                    this.processing[provider] = false;
                }
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <div>
        <div
            :class="{ 'btn-change-avatar--trigger-hover': trigger === 'hover' }"
            class="btn-change-avatar"
            :title="$t('personalInfo.changeAvatar.title')"
            @click="toggleProfilePictureDialog(true)"
        >
            <slot />
        </div>

        <ChangeAvatarDialog :require="!user.picture" />
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import ChangeAvatarDialog from '~/components/dialogs/ChangeAvatar.vue';

    export default {
        components: {
            ChangeAvatarDialog,
        },

        props: {
            trigger: String,
        },

        computed: {
            ...mapState('auth', ['user']),
        },

        methods: {
            ...mapMutations('dialogs', ['toggleProfilePictureDialog']),
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <ElAside class="viblo-aside position-relative" width="200">
        <ElMenu
            :default-openeds="['5', '10', '20', '30']"
            :default-active="$route.path"
            :collapse="collapse"
            :collapse-transition="false"
            class="viblo-aside__menu"
            router
        >
            <ElMenuItem v-if="isAdmin" index="/admin">
                <i class="fa fa-home" />
                <span>Dashboard</span>
            </ElMenuItem>

            <ElSubMenu v-if="isAdmin || isEventEditor" index="5">
                <template slot="title">
                    <i class="fa fa-calendar" />
                    <span>Events</span>
                </template>
                <ElMenuItem index="/admin/event/code">
                    <i class="fa fa-code" />
                    <span>Code</span>
                </ElMenuItem>
                <ElMenuItem index="/admin/event/ctf">
                    <i class="fa fa-flag-checkered" />
                    <span>CTF</span>
                </ElMenuItem>
            </ElSubMenu>

            <ElSubMenu v-if="isAdmin" index="10">
                <template slot="title">
                    <i class="fa fa-id-card" />
                    <span>Manage</span>
                </template>
                <ElMenuItem index="/admin/personal-info">
                    <i class="fa fa-info" />
                    <span>Personal Info</span>
                </ElMenuItem>
                <ElMenuItem index="/admin/users">
                    <i class="fa fa-user" />
                    <span>Users</span>
                </ElMenuItem>
            </ElSubMenu>

            <ElSubMenu v-if="isAdmin" index="20">
                <template slot="title">
                    <i class="el-icon-setting" />
                    <span>Systems</span>
                </template>

                <ElMenuItem index="/admin/email-blacklist">
                    <i class="el-icon-message" />
                    <span>Email blacklist</span>
                </ElMenuItem>
                <ElMenuItem index="/admin/roles">
                    <i class="fa fa-users" />
                    <span>Roles</span>
                </ElMenuItem>
            </ElSubMenu>

            <ElMenuItem class="btn-collapse" @click.native="toggleSidebar">
                <i :class="{ [`fa-toggle--collapsed`]: collapse }" class="fa fa-toggle" />
                <span>Collapse Sidebar</span>
            </ElMenuItem>
        </ElMenu>
    </ElAside>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        data: () => ({
            collapse: Boolean(window.localStorage.getItem('sidebar-collapse')),
        }),

        computed: {
            ...mapGetters('auth', ['isAdmin', 'isEventEditor']),
        },

        methods: {
            toggleSidebar() {
                const value = !this.collapse;

                this.collapse = value;
                window.localStorage.setItem('sidebar-collapse', value ? true : '');
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
<template>
    <ElTable v-if="tokens.length > 0" :data="tokens">
        <ElTableColumn type="index" width="80" />
        <ElTableColumn prop="name" :label="$t('personalTokens.tokenList.tokenName')" />
        <ElTableColumn
            prop="expired_at"
            :label="$t('personalTokens.tokenList.expiresAt')"
            :formatter="formatTime"
        />

        <ElTableColumn :label="$t('personalTokens.tokenList.actions')" width="200">
            <template slot-scope="{ row }">
                <ElButton
                    plain
                    type="danger"
                    size="small"
                    icon="el-icon-delete"
                    @click.prevent="revoke(row)"
                >
                    {{ $t('personalTokens.tokenList.revokeToken') }}
                </ElButton>
            </template>
        </ElTableColumn>
    </ElTable>
    <div v-else class="text-center font-weight-bold text-muted my-05">
        {{ $t('authorizedOAuthApps.empty') }}
    </div>
</template>

<script>
    import { revokeToken } from '~/api/security/tokens';
    import { formatTime } from '~/filters/time';

    export default {
        props: {
            tokens: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            formatTime(item) {
                return formatTime(item.expires_at);
            },

            revoke(token) {
                this.$confirm(this.$t('personalTokens.methodRevoke.content'), this.$t('personalTokens.methodRevoke.title'))
                    .then(async () => {
                        await revokeToken(token.id);
                        this.$emit('delete', token);
                    })
                    .catch((e) => {
                        if (e.response) {
                            this.$message.error(this.$t('personalTokens.methodRevoke.error'));
                        }
                    });
            },
        },
    };
</script>

// gkc_hash_code : 01DNZTC1Y7GYJJ71GWBQHJJP40
import Vue from 'vue';
import Router from 'vue-router';
import { withMiddleware } from './middleware/__utils';

import DefaultLayout from './layouts/Default.vue';
import AdminLayout from './layouts/Admin.vue';
import ConfirmLayout from './layouts/Confirm.vue';

import Login from './views/Login.vue';
import Register from './views/Register.vue';
import NotFound from './views/NotFound.vue';

import Dashboard from './views/Dashboard.vue';
import PersonalInfo from './views/profile/Personal.vue';
import ContactInfo from './views/profile/Contact.vue';
import Email from './views/profile/Email.vue';

import SendActivation from './views/account/SendActivation.vue';
import ForgotPassword from './views/account/ForgotPassword.vue';
import ResetPassword from './views/account/ResetPassword.vue';

import Password from './views/security/Password.vue';
import ConnectedAccounts from './views/security/ConnectedAccounts.vue';

import PersonalAccessTokens from './views/developer/PersonalAccessTokens.vue';
import NewPersonalAccessToken from './views/developer/NewPersonalAccessToken.vue';
import OAuthApps from './views/developer/OAuthApps.vue';
import NewOAuthApp from './views/developer/NewOAuthApp.vue';
import OAuthAppPage from './views/developer/OAuthAppPage.vue';
import AuthorizeOAuthApp from './views/AuthorizeOAuthApp.vue';
import Applications from './views/developer/Applications.vue';
import TutorialOAuthApps from './views/developer/TutorialOAuthApps.vue';

import Admin from './views/admin/Dashboard.vue';
import Users from './views/admin/manage/Users.vue';
import UserInfo from './views/admin/manage/UserInfo.vue';
import ConfirmSunner from './views/admin/confirms/ConfirmSunner.vue';
import ConfirmBan from './views/admin/confirms/ConfirmBan.vue';
import ConfirmUnBan from './views/admin/confirms/ConfirmUnBan.vue';
import EmailBlacklist from './views/admin/systems/EmailBlacklist.vue';
import Event from './views/admin/events/Event.vue';

import RoleLayout from './views/admin/role/Layout.vue';
import AssignedUser from './views/admin/role/AssignedUser.vue';
import ManageRole from './views/admin/role/ManageRole.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',

    routes: [
        withMiddleware('guestOnly', { path: '/login', component: Login }),
        withMiddleware('guestOnly', { path: '/register', component: Register }),
        withMiddleware('authenticated', {
            path: '/',
            component: DefaultLayout,
            children: [
                { path: '', component: Dashboard },

                { path: 'profile', redirect: '/profile/personal' },
                { path: 'profile/personal', component: PersonalInfo },
                { path: 'profile/contact', component: ContactInfo },
                { path: 'profile/emails', component: Email },

                { path: 'security', redirect: '/security/password' },
                { path: 'security/password', component: Password },
                { path: 'security/connected-accounts', component: ConnectedAccounts },
                { path: 'security/tokens', component: PersonalAccessTokens },
                { path: 'security/tokens/new', component: NewPersonalAccessToken },
                { path: 'security/apps', component: OAuthApps },
                { path: 'security/apps/new', component: NewOAuthApp },
                { path: 'security/apps/:app', component: OAuthAppPage },
                { path: 'security/applications', component: Applications },
                { path: 'security/apps-tutorial', component: TutorialOAuthApps },
            ],
        }),
        withMiddleware('isRoleMayFestOrAdmin', {
            path: '/admin',
            component: AdminLayout,
            children: [
                { path: '/admin/event/:service', component: Event },
            ],
        }),

        withMiddleware('isAdmin', {
            path: '/admin',
            component: AdminLayout,
            children: [
                { path: '', component: Admin },
                { path: '/admin/users', component: Users },
                { path: '/admin/personal-info', component: UserInfo },
                { path: '/admin/email-blacklist', component: EmailBlacklist },
                {
                    path: '/admin/roles',
                    component: RoleLayout,
                    children: [
                        {
                            path: '/admin/roles',
                            component: AssignedUser,
                        },
                        {
                            path: '/admin/roles/manage',
                            component: ManageRole,
                        },
                    ],
                },
            ],
        }),

        withMiddleware('isAdmin', {
            path: '/confirm',
            component: ConfirmLayout,
            children: [
                { path: 'confirm-sunner', component: ConfirmSunner },
                { path: 'confirm-ban', component: ConfirmBan },
                { path: 'confirm-unban', component: ConfirmUnBan },
            ],
        }),
        withMiddleware('authenticated', { path: '/authorize', component: AuthorizeOAuthApp }),
        withMiddleware('guestOnly', { path: '/send-activation', component: SendActivation }),
        withMiddleware('guestOnly', { path: '/forgot-password', component: ForgotPassword }),
        withMiddleware('guestOnly', { path: '/reset-password/:token', component: ResetPassword }),
        { path: '*', component: NotFound },
    ],
});

import _get from 'lodash/get';
import TimeCell from '../table/TimeCell.vue';
import ActionsCell from '../table/ActionsCell.vue';

export default {
    methods: {
        renderTime(prop, format) {
            return data => <TimeCell time={_get(data, prop)} format={format}/>;
        },

        renderLinkBtn(to) {
            return data => (
                <viblo-link to={to(data)}>
                    <el-button icon='fa fa-external-link' size='mini'/>
                </viblo-link>
            );
        },

        renderActions(actions) {
            return data => <ActionsCell actions={actions} data={data}/>;
        },
    },
};

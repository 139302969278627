<template>
    <ContactInfo :profile="user" class="py-4" />
</template>

<script>
    import { mapState } from 'vuex';
    import ContactInfo from '~/components/forms/profile/ContactInfo.vue';

    export default {
        components: {
            ContactInfo,
        },

        computed: {
            ...mapState('auth', ['user']),
        },
    };
</script>
